import instance from "../Interceptor/api_instance";
import { useState, useEffect } from "react";
import { getDuration } from "../Interceptor/csrftoken";
import { BarGraph } from "../Media/BarGraph";
import EditReservation from "./Modals/EditReservation";
import Samsara from "./Modals/Samsara";

export const DispatchGraphics = ({ user, english }) => {
  const [reservations, setReservations] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [loadingReservations, setLoadingReservations] = useState(true);
  const [loadingGraph, setLoadingGraph] = useState(true);
  const [trailersError, setTrailersError] = useState(null);
  const [reservationError, setReservationsError] = useState(null);
  const [reservationConfirm, setReservationConfirm] = useState(false);
  const [selected, setSelected] = useState(null);
  const [visitorError, setVisitorsError] = useState(null);
  const [order, setOrder] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [samsaraBtn, setSamsaraBtn] = useState(false);

  const currentDate = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const active = async (e) => {
    const previous = document.getElementsByClassName("selected");
    for (var i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    // if (trailers.length > 0) {
    //   let filter = trailers.filter((a) => a.location.loc_name === e.target.id);
    //   setOrder(filter);
    //   console.log(order)
    // }
  };
  const formattedDate = `${
    months[currentDate.getMonth()]
  } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

  useEffect(() => {
    const fetchData = async () => {
      // Initialize both requests without waiting for them to complete.
      const trailerRequest = instance.get("assets/view-trailers");
      const truckRequest = instance.get("assets/view-trucks");
      const reservationRequest = instance.get("assets/user-reservations/");
      const visitorsRequest = instance.get("assets/get-visitors/");

      // Handle the trailer request.
      trailerRequest
        .then((response) => {
          if (response.status === 200) {
            setTrailers(response.data);
          }
        })
        .catch((error) => {
          // Handle error specifically for trailers request.
          console.error("Error fetching trailers", error);
          setTrailersError(error); // Assuming you have a state to hold this error
        })
        .finally(() => {
          setLoadingGraph(false); // Ensure loading state is updated regardless of success or failure
        });

      // Handle the trailer request.
      truckRequest
        .then((response) => {
          if (response.status === 200) {
            setTrucks(response.data);
          }
        })
        .catch((error) => {
          // Handle error specifically for trailers request.
          console.error("Error fetching trailers", error);
          setTrailersError(error); // Assuming you have a state to hold this error
        })
        .finally(() => {
          setLoadingGraph(false); // Ensure loading state is updated regardless of success or failure
        });

      // Handle the reservation request in a similar manner.
      reservationRequest
        .then((response) => {
          if (response.status === 200) {
            setReservations(response.data);
          }
        })
        .catch((error) => {
          // Handle error specifically for reservation request.
          console.error("Error fetching reservations", error);
          setReservationsError(error); // Assuming you have a state to hold this error
        })
        .finally(() => {
          setLoadingReservations(false); // Ensure loading state is updated regardless of success or failure
        });

      visitorsRequest
        .then((response) => {
          if (response.status === 200) {
            setVisitors(response.data);
          }
        })
        .catch((error) => {
          // Handle error specifically for reservation request.
          console.error("Error fetching visitors", error);
          setVisitorsError(error); // Assuming you have a state to hold this error
        })
        .finally(() => {
          setLoadingReservations(false); // Ensure loading state is updated regardless of success or failure
        });
    };

    fetchData();
  }, []);

  function calculateHoursDifference(timestampString) {
    const timestamp = new Date(timestampString);
    const currentTime = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentTime - timestamp;

    // Convert milliseconds to hours
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    return differenceInHours;
  }

  const unreserve = async (e) => {
    try {
      const key = {
        trailer_id: e,
      };
      await instance.post("assets/unreserve-trailer/", key);
      window.location.reload();
    } catch (e) {
      window.alert(e);
    }
  };

  const handleButtonClick = (e) => {
    setSelected(e);

    setReservationConfirm(true);
  };

  const handleEditButtonClick = (e) => {
    setSelected(e);

    setEditModal(true);
  };

  const HandleUnreserve = () => {
    const handleYes = () => {
      unreserve(selected.trailer.id);
      setLoadingReservations(true);
      setReservationConfirm(false);
    };

    return (
      <>
        <div className="page-mask page-mask-animation">
          <div className="blank-card active-confirm">
            <h4 className="">{`${english(
              "graphics-cont.reservations.unreserve-prompt"
            )} ${selected.trailer.trailer_number}?`}</h4>

            <div className="d-flex gap-2 justify-content-center">
              <button className="profile-pic-btn" onClick={() => handleYes()}>
                {english("general.yes")}
              </button>
              <button
                className="profile-pic-btn"
                onClick={() => setReservationConfirm(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="overview-header">
        <h1 className="global-h1 black">{english("page-headers.overview")}</h1>
        <h4 className="global-h4 gray">
          {english("page-subheaders.welcome")}, {user.first_name}
        </h4>
        <div className="the-btn-cont">
          <button
            onClick={() => setSamsaraBtn(true)}
            className="all-btn other-btn"
          >
            Generate Tracking
          </button>
        </div>
      </div>
      {/* {user.role === "Admin" && (
        <ul className="yard-tabs">
          <li className="selected" onClick={active} id="San Diego Yard">
            {english("yards.sd")}
          </li>
          <li className="tab" onClick={active} id="Tijuana Yard">
            {english("yards.tj")}
          </li>
          <li className="tab" onClick={active} id="Tecate Yard">
            {english("yards.tecate")}
          </li>
        </ul>
      )} */}
      <div className="graphics-cont">
        <div className="graphics-card">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <h4 className="global-h4 gray">
              {english("graphics-cont.today-capacity")}
            </h4>

            <h4 style={{ textAlign: "right" }} className="global-h5 gray">
              {formattedDate}
            </h4>
          </div>
          {loadingGraph ? (
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <>
              {trailersError ? (
                <p className="error">{trailersError}</p>
              ) : (
                <BarGraph trailers={trailers} />
              )}
            </>
          )}
        </div>
        <div className="graphics-card">
          <h4 className="global-h4 gray">
            {english("graphics-cont.reservations.header")}
          </h4>
          {reservationError && <p className="error">{reservationError}</p>}
          <div className="recent-activity">
            {loadingReservations ? (
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : reservations !== "" ? (
              reservations.map((reservation, index) => (
                <div key={index} className="activity-item">
                  <div className="reservation-item-cont">
                    <div>
                      <span className="">Trailer: </span>
                      <span className="global-h5 gray">
                        {reservation.trailer.trailer_number}
                      </span>
                    </div>
                    {reservation.truck_asset && (
                      <div>
                        <span className="">
                          {english("yard-tables.tabs.truck")}:{" "}
                        </span>
                        <span className="global-h5 gray">
                          {reservation.truck_asset?.company}-
                          {reservation.truck_asset?.name}
                        </span>
                      </div>
                    )}
                  </div>

                  <button
                    className="all-btn error-btn"
                    onClick={() => handleButtonClick(reservation)}
                  >
                    {english("graphics-cont.reservations.unreserve")}
                  </button>
                  <button
                    className="all-btn other-btn"
                    onClick={() => handleEditButtonClick(reservation)}
                  >
                    {english("buttons.edit")}
                  </button>
                </div>
              ))
            ) : null}
          </div>
        </div>
        <div className="graphics-card-but-small">
          <h4 className="global-h4 gray">
            {english("graphics-cont.recent-activity.yard-drivers")}
          </h4>
          {visitorError && <p className="error">{visitorError}</p>}
          <div className="recent-activity">
            {loadingReservations ? (
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : visitors !== "" ? (
              visitors
                .filter((item) => item.visitor.category === "Driver")
                .map((item, index) => (
                  <div key={index} className="activity-item">
                    <span className="global-h5 gray">
                      {item.visitor.first_name + " " + item.visitor.last_name}
                    </span>
                  </div>
                ))
            ) : null}
          </div>
        </div>
        <div className="graphics-card-but-small">
          <h4 className="global-h4 gray">
            {english("graphics-cont.recent-activity.trailer-34hr")}
          </h4>
          {reservationError && <p className="error">{trailersError}</p>}
          <div className="recent-activity">
            {loadingReservations ? (
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : trucks !== "" ? (
              trucks
                .filter(
                  (item) =>
                    calculateHoursDifference(item.truck.check_in_dt) >= 34
                )
                .map((item, index) => (
                  <div key={index} className="activity-item">
                    <span className="global-h5 gray">
                      {item.truck.carrier_name}-{item.truck.truck_number}
                    </span>
                    <span style={{ color: "#FB6060" }}>
                      {getDuration(item.truck.check_in_dt)}
                    </span>
                  </div>
                ))
            ) : null}
          </div>
        </div>
      </div>
      {reservationConfirm && <HandleUnreserve />}
      {editModal && (
        <EditReservation
          reservation={selected}
          setEditModal={setEditModal}
          english={english}
        />
      )}
      {samsaraBtn && <Samsara close={() => setSamsaraBtn(false)} />}
    </>
  );
};
