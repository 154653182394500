import React, { useRef } from "react";
import { DeleteIcon, UploadIcon, CameraIcon } from "../../../Media/Icons";
import { convertHeicToJpeg } from "../../Utility/HeicToJpg";

export const CheckInPhotos = ({
  english,
  backForm,
  capturedPhotos,
  setCapturedPhotos,
  deletePhoto,
  submit,
  isLoading,
  inOrOut,
  confirm,
}) => {
  const uploadFileInputRef = useRef();
  const cameraFileInputRef = useRef();

  const MAX_FILE_SIZE = 2.5 * 1024 * 1024; // 2MB

  const handleUploadInput = () => {
    // Trigger the hidden file input for uploading files
    if (uploadFileInputRef.current) {
      uploadFileInputRef.current.click();
    }
  };

  const handleCapturePhoto = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    const convertedFile = await convertHeicToJpeg(file);
    setCapturedPhotos((prevPhotos) => [...prevPhotos, convertedFile]);
  };

  const handleUploadPhoto = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const filesArray = Array.from(e.target.files);
    const validFiles = [];

    for (const file of filesArray) {
      if (file.size > MAX_FILE_SIZE) {
        alert(`File ${file.name} is too large. Maximum size is 2MB.`);
        continue;
      }
      const convertedFile = await convertHeicToJpeg(file);
      validFiles.push(convertedFile);
    }

    if (validFiles.length > 0) {
      setCapturedPhotos((prevPhotos) => [...prevPhotos, ...validFiles]);
    }
  };

  return (
    <div className="images-upload-cont">
      <h2 className="global-h2 light-black">
        {inOrOut
          ? english("forms.type.vehicle.check-in.header")
          : english("forms.type.vehicle.check-out.header")}
      </h2>
      <h4 className="global-h4 gray bottom-space">
        {english("forms.type.vehicle.sub-header.photos")}
      </h4>
      <div className="options-photo-cont">
        <div className="input-photo-cont" onClick={handleUploadInput}>
          <div className="centered-cont">
            <UploadIcon />
          </div>
          <label>
            {english("forms.type.vehicle.input-headers.uploadPhoto")}
          </label>
          <input
            className="file-input"
            type="file"
            accept="image/*, .heic"
            multiple
            ref={uploadFileInputRef}
            onChange={handleUploadPhoto}
            disabled={capturedPhotos.length === 10}
          />
        </div>
        <div
          className="input-photo-cont"
          onClick={() => cameraFileInputRef.current.click()}
        >
          <div className="centered-cont">
            <CameraIcon />
          </div>
          <label>{english("forms.type.vehicle.input-headers.takePhoto")}</label>
          <input
            className="file-input"
            type="file"
            accept="image/*"
            capture="environment"
            ref={cameraFileInputRef}
            onChange={handleCapturePhoto}
            disabled={capturedPhotos.length === 10}
          />
        </div>
      </div>
      <div className="images-cont">
        {capturedPhotos.length > 0
          ? capturedPhotos.map((photo, index) => (
              <div className="image-column" key={index}>
                <img src={URL.createObjectURL(photo)} alt={`${index}`} />
                <div className="delete-icon" onClick={() => deletePhoto(index)}>
                  <DeleteIcon />
                </div>
              </div>
            ))
          : null}
      </div>
      <div className="full-cont">
        {isLoading ? (
          <div className="loading-cont">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <button
            id="submit-btn"
            className="change-pwd-btn"
            onClick={() => confirm(true)}
          >
            {english("buttons.submit")}
          </button>
        )}
        <button className="cancel-btn" onClick={backForm}>
          {english("buttons.back")}
        </button>
      </div>
    </div>
  );
};
