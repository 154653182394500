import instance from "./api_instance";

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "");
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function getDuration(arrivalTime) {
    //get total seconds between the times
    let delta = Math.abs(new Date() - new Date(arrivalTime)) / 1000;
    //calculate whole days 
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    //calculate (and subtract) whole hours
    let hours = Math.floor(delta / 3600) % 24;

    //calculate but don't subtract whole minutes unless seconds are necessary
    let minutes = Math.floor(delta / 60) % 60;

    return (days + 'd ' + hours + 'h ' + minutes + 'm')
}

export function searchQuery(target) {
    const table = document.getElementById("forwardTable");
    const trs = table.tBodies[0].getElementsByTagName("tr");

    let filter = target.toUpperCase();

    for (let row = 0; row < trs.length; ++row) {

        //define the row's cells
        let tds = trs[row].getElementsByTagName("td");

        //hide the row
        trs[row].style.display = "none";

        //loop through row cells
        for (let cell = 0; cell < tds.length; ++cell) {

            //if there's a match
            if (tds[cell].innerHTML.toUpperCase().indexOf(filter) > -1) {

                //show the row
                trs[row].style.display = "";

                //skip to the next row
                continue;
            }
        }
    }
}

// Function to check if sessionid exists in cookies
export async function checkAuthentication(updateUser) {
    try {
        const response = await instance.get('/api/checkauthentication/');
        if (response.status === 200) {
            localStorage.setItem("user", JSON.stringify(response.data));
            updateUser(response.data);
            return true;
        }
        else {
            return false;
        }
    } catch (error) {
        if (error.response.status === 403) {
            console.log("Not authenticated.")
        }
        localStorage.clear();
        return false;
    }
}