import { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import instance from "../../Interceptor/api_instance";
import { Dots } from "../LoadingAnimations/LoadingAnimations";

export default function EditReservation({ reservation, setEditModal, english }) {
  const [allTrucks, setAllTrucks] = useState({ ati: [], jfg: [] });
  const [selectedATITruck, setSelectedATITruck] = useState(null);
  const [selectedJFGTruck, setSelectedJFGTruck] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        const response = await instance.get("assets/all-truck-assets");
        setAllTrucks(response.data);
      } catch (error) {
        console.error("Failed to fetch trucks:", error);
      }
    };

    fetchTrucks();
  }, []);

  useEffect(() => {
    if (
      (allTrucks.ati.length > 0 || allTrucks.jfg.length > 0) &&
      reservation.truck_asset
    ) {
      const selectedTruck = reservation.truck_asset.id;
      findTruckByLabel(selectedTruck);
    }
  }, [allTrucks, reservation.truck_asset]);

  const findTruckByLabel = (label) => {
    const foundTruckATI = allTrucks.ati.find((truck) => truck.id === label);
    const foundTruckJFG = allTrucks.jfg.find((truck) => truck.id === label);

    if (foundTruckATI) {
      setSelectedATITruck(
        foundTruckATI
          ? {
              value: foundTruckATI.id,
              label: `${foundTruckATI.company}-${foundTruckATI.name}`,
            }
          : null
      );
    } else {
      setSelectedJFGTruck(
        foundTruckJFG
          ? {
              value: foundTruckJFG.id,
              label: `${foundTruckJFG.company}-${foundTruckJFG.name}`,
            }
          : null
      );
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px 16px",
      border: "none",
      width: "100%",
      borderRadius: "16px",
      background: "var(--neutral-light-purple, #f4f4ff)",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      color: "#5656b9",
      minHeight: "auto",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
      color: "#5656b9",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5656b9",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F4FF",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      borderRadius: "24px",
      padding: "16px",
      marginTop: "10px",
    }),
  };

  const handleTruckChange = useCallback((selectedOption, action) => {
    if (action.name === "ATITruck") {
      setSelectedATITruck(selectedOption);
    } else if (action.name === "JFGTruck") {
      setSelectedJFGTruck(selectedOption);
    }
  }, []);

  const ATITruckOptions =
    allTrucks.ati.length > 0
      ? allTrucks.ati.map((truck) => ({
          value: truck.id,
          label: `${truck.company}-${truck.name}`,
        }))
      : [{ value: "truckListLoading", label: "Loading" }];

  const JFGTruckOptions =
    allTrucks.jfg.length > 0
      ? allTrucks.jfg.map((truck) => ({
          value: truck.id,
          label: `${truck.company}-${truck.name}`,
        }))
      : [{ value: "truckListLoading", label: "Loading" }];

  const handleResevationUpdate = async () => {
    let key = {
      id: reservation.id,
    };

    if (selectedATITruck || selectedJFGTruck) {
      key.truck_id = selectedATITruck
        ? selectedATITruck.value
        : selectedJFGTruck.value;
    }

    try {
      setLoading(true);
      await instance.patch(
          `assets/edit-reservation/${reservation.id}/${key.truck_id ? key.truck_id : 0}/`
      );
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      setErrorMessage(error);
    }
  };

  return (
    <>
      <div className="page-mask">
        <div className="blank-card active-confirm">
          {errorMessage ? <p className="error">{errorMessage}</p> : <></>}
          {reservation.truck_asset ? (
            <h4 className="mb-4">
              {english("forms.type.edit-reservation.header")} {reservation.trailer.trailer_number} {english("forms.type.edit-reservation.and")}{" "}
              {reservation.truck_asset.company}-{reservation.truck_asset.name}
            </h4>
          ) : (
            <h4 className="mb-4">
              {english("forms.type.edit-reservation.header")} {reservation.trailer.trailer_number}
            </h4>
          )}
          <div className="form-group">
            <label htmlFor="">{english("forms.type.edit-reservation.ati-trucks")}</label>
            <div className="input-cont">
              <Select
                isClearable={true}
                placeholder={english("forms.type.edit-reservation.select")}
                isSearchable={true}
                unstyled={true}
                styles={customStyles}
                options={ATITruckOptions}
                name="ATITruck"
                onChange={handleTruckChange}
                value={selectedATITruck}
                isLoading={ATITruckOptions.length === 0}
                loadingMessage={() => "Loading options, please wait..."}
                isDisabled={selectedJFGTruck}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="">{english("forms.type.edit-reservation.jfg-trucks")}</label>
            <div className="input-cont">
              <Select
                isClearable={true}
                isSearchable={true}
                unstyled={true}
                placeholder={english("forms.type.edit-reservation.select")}
                styles={customStyles}
                options={JFGTruckOptions}
                name="JFGTruck"
                onChange={handleTruckChange}
                value={selectedJFGTruck}
                isLoading={JFGTruckOptions.length === 0}
                loadingMessage={() => "Loading options, please wait..."}
                isDisabled={selectedATITruck}
              />
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center">
            {loading ? (
              <Dots />
            ) : (
              <>
                <button
                  className="profile-pic-btn"
                  onClick={handleResevationUpdate}
                >
                  Update
                </button>
                <button
                  className="profile-pic-btn"
                  onClick={() => setEditModal(false)}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
