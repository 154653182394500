import { useState } from "react";
import dashboard from "../../dashboard_screenshot.png";
import instance from "../../Interceptor/api_instance";
import "../../CSS/login.css";
import "../../CSS/global.css";
import { Logo } from "../../Media/logos";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [locked, setLocked] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setLocked(false);
    setSubmitted(true);
    const data = {
      email: email,
    };
    try {
      await instance.post("api/forgot-password/", data);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setLocked(true);
      } else {
        window.alert("A server error occurred. Please contact your IT team.");
      }
    }
  };
  return (
    <div className="cont">
      <div className="left-cont">
        <div className="center-img">
          <img src={dashboard} alt="Dashboard" />
          <p>
            A simplified gateway to effortless visibility and asset management.
          </p>
        </div>
      </div>
      <div className="right-cont">
        <div className="login-cont">
          <div className="logo-cont bottom-space">
            <Logo fillColor="#557AFF" />
            <h4>Forward</h4>
          </div>
          <h1 className="bottom-space">Forgot Passsword?</h1>
          <p className="bottom-space">
            Please enter your email address to reset your password.
          </p>
          <form id="login-form" onSubmit={submit}>
            <div className="auth-group">
              <input
                type="text"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="link-cont bottom-space">
              <a href="/login">Back to login</a>
            </div>
            <button className="bottom-space change-pwd-btn" type="submit">
              Submit
            </button>
            {submitted ? (
              <div className="login-error">
                <p>If the email you entered exists in our system,</p>
                <p>you will receive instructions to reset your password.</p>
              </div>
            ) : null}
            {locked ? (
              <div className="login-error">
                <p>Too many requests have been made.</p>
                <p>Please try again later or contact your IT team.</p>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};
