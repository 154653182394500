export const CheckInTrailerF15 = ({
  backForm,
  nextForm,
  english,
  handleTrailerChange,
  trailerForm,
  sealStatus,
}) => {
  const isAnyFieldPoor = () => {
    return Object.values(trailerForm).some((value) => value === false);
  };

    return (
        <div className="questions-cont">
            <h2 className="global-h2 light-black">{english ? "Vehicle Check in" : "Entrada de Vehículo"}</h2>
            <h4 className="global-h4 gray bottom-space">
                {english ? "Please note the condition of the trailer's parts." : "Marque el estado de las piezas de la caja."}
            </h4>
            <form onSubmit={nextForm} id="form-trailerF15">
                <div className="form-group">
                    <label className="input-label required">{english ? "Chassis " : "Chasis exterior "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="chassis" className="radio-input" checked={trailerForm.chassis} onClick={() => handleTrailerChange('chassis', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="chassis" className="radio-input" checked={trailerForm.chassis === false} onClick={() => handleTrailerChange('chassis', false)} /> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Inside & outside doors " : "Puertas traseras "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="doors" className="radio-input" checked={trailerForm.inOutDoors} onClick={() => handleTrailerChange('inOutDoors', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="doors" className="radio-input" checked={trailerForm.inOutDoors === false} onClick={() => handleTrailerChange('inOutDoors', false)} /> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Trailer floor " : "Piso de la remolque "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="floor" className="radio-input" checked={trailerForm.trailerFloor} onClick={() => handleTrailerChange('trailerFloor', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="floor" className="radio-input" checked={trailerForm.trailerFloor === false} onClick={() => handleTrailerChange('trailerFloor', false)}/> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Side walls " : "Paredes "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="walls" className="radio-input" checked={trailerForm.sideWalls} onClick={() => handleTrailerChange('sideWalls', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="walls" className="radio-input" checked={trailerForm.sideWalls === false} onClick={() => handleTrailerChange('sideWalls', false)} /> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Frontal wall " : "Pared frontal "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="front-wall" className="radio-input" checked={trailerForm.frontWall} onClick={() => handleTrailerChange('frontWall', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="front-wall" className="radio-input" checked={trailerForm.frontWall === false} onClick={() => handleTrailerChange('frontWall', false)} /> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Ceiling " : "Techo "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="ceiling" className="radio-input" checked={trailerForm.ceiling} onClick={() => handleTrailerChange('ceiling', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="ceiling" className="radio-input" checked={trailerForm.ceiling === false} onClick={() => handleTrailerChange('ceiling', false)} /> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Refrigerator unit " : "Unidad de refrigeracion "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="refrigerator" className="radio-input" checked={trailerForm.refrigerator} onClick={() => handleTrailerChange('refrigerator', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="refrigerator" className="radio-input" checked={trailerForm.refrigerator === false} onClick={() => handleTrailerChange('refrigerator', false)} /> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="refrigerator" className="radio-input" checked={trailerForm.refrigerator === null} onClick={() => handleTrailerChange('refrigerator', null)}/> <label>N/A</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Seal " : "Sello "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="seal" className="radio-input" checked={trailerForm.sealCondition} onClick={() => handleTrailerChange('sealCondition', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="seal" className="radio-input" checked={trailerForm.sealCondition === false} onClick={() => handleTrailerChange('sealCondition', false)}/> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Pest & garbage free " : "Libre de plagas y basura "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="pest" className="radio-input" checked={trailerForm.trailerGarbage} onClick={() => handleTrailerChange('trailerGarbage', true)} required /> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="pest" className="radio-input" checked={trailerForm.trailerGarbage === false} onClick={() => handleTrailerChange('trailerGarbage', false)}/> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Cleanliness " : "Limpieza "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="cleanliness" className="radio-input" checked={trailerForm.trailerCleanliness} onClick={() => handleTrailerChange('trailerCleanliness', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="cleanliness" className="radio-input" checked={trailerForm.trailerCleanliness === false} onClick={() => handleTrailerChange('trailerCleanliness', false)}/> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Light filtering " : "Filtracion de luz "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="filter" className="radio-input" checked={trailerForm.filter} onClick={() => handleTrailerChange('filter', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="filter" className="radio-input" checked={trailerForm.filter === false} onClick={() => handleTrailerChange('filter', false)}/> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label required">{english ? "Humidity " : "Humedad "}</label>
                    <div className="input-cont">
                        <fieldset className="fieldsets">
                            <div className="radio-options">
                                <input type="radio" name="humidity" className="radio-input" checked={trailerForm.humidity} onClick={() => handleTrailerChange('humidity', true)} required/> <label>{english ? "Good" : "Bueno"}</label>
                            </div>
                            <div className="radio-options">
                                <input type="radio" name="humidity" className="radio-input" checked={trailerForm.humidity === false} onClick={() => handleTrailerChange('humidity', false)} /> <label>{english ? "Poor" : "Mal"}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                { sealStatus === 'true' || sealStatus === true ? <div className="form-group">
                    <label className="input-label required">{english ? "Seal tests " : "Pruebas de sello"}</label>
                    <div className="input-cont">
                        <div className="checkboxes-cont">
                            <div className="check-cont">
                                <input type="checkbox" onChange={e => handleTrailerChange('view_seal', e.target.checked)} checked={trailerForm.view_seal} required/>
                                <label>{english ? "View seal" : "Ver sello"}</label>
                            </div>
                            <div className="check-cont">
                                <input type="checkbox" onChange={e => handleTrailerChange('verify_seal', e.target.checked)} checked={trailerForm.verify_seal} required/>
                                <label>{english ? "Verify seal" : "Verificar sello"}</label>
                            </div>
                            <div className="check-cont">
                                <input type="checkbox" required onChange={e => handleTrailerChange('tug_on_seal', e.target.checked)} checked={trailerForm.tug_on_seal}/>
                                <label>{english ? "Tug on seal" : "Tirar del sello"}</label>
                            </div>
                            <div className="check-cont">
                                <input type="checkbox" required onChange={e => handleTrailerChange('twistTurn_seal', e.target.checked)} checked={trailerForm.twistTurn_seal}/>
                                <label>{english ? "Twist and turn seal" : "Gira el sello"}</label>
                            </div>
                        </div>
                    </div>
                </div> : null}
                <div className="form-group">
                    <label className="input-label">{english ? "Notes" : "Notas"}</label>
                    <div className="input-cont">
                        <input className="text-input" placeholder={english ? "Enter notes" : "Ingresa notas"} type="text" onChange={e => handleTrailerChange('trailerNotes', e.target.value)} value={trailerForm.trailerNotes} required={isAnyFieldPoor()}/>
                    </div>
                </div>
                <div className="full-cont">
                    <button className="change-pwd-btn" type="submit">Continue</button>
                    <button className="cancel-btn" onClick={backForm}>Back</button>
                </div>
            </form>
        </div>
    )
}