import {
  AddNewDriver1,
  AddNewDriver2,
  AddNewDriverFinal,
} from "./AddNewDriver";
import instance from "../../../Interceptor/api_instance";

import { useState } from "react";
import { useTranslation } from "react-i18next";

// Parent of AddNewDriver

export default function NewDriver() {
  const { t } = useTranslation();
  const [step, setStep] = useState(1); // Initialize the current step to 1
  const [driverData, setDriverData] = useState({
    step1: {}, // Initialize an empty object for step 1 data
    step2: {}, // Initialize an empty object for step 2 data
    step3: {}, // Initialize an empty object for step 3 data
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Function to navigate to the next step
  const nextStep = () => {
    setStep((prevStep) => prevStep + 1); // Increment the current step by 1
  };

  // Function to navigate to the previous step
  const prevStep = () => {
    setStep((prevStep) => prevStep - 1); // Decrement the current step by 1
  };

  // Callback function to update driver data for the current step
  const onData = (data) => {
    setDriverData((prevData) => ({
      ...prevData,
      [`step${step}`]: data, // Update the driver data for the current step
    }));
  };

  // Function to retrieve merged data from step1 and step2
  const getStepData = () => {
    // Merge data from step1 and step2 before passing it to step3
    const mergedData = { ...driverData.step1, ...driverData.step2 };
    return mergedData; // Return the merged data
  };

  // Data submittion
  const handleSubmit = async () => {
    try {
      const response = await instance.post(
        "safety/create-driver/",
        driverData.step3,
        {
          headers: {
            "Content-Type": "multipart/data-form",
          },
        }
      );
      if (response.status === 200) {
        setSubmitSuccess(true);
      }
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const SuccessModal = () => {
    const handleSuccessClose = () => {
      setSubmitSuccess(false);
      window.location.href = "/driving-team"; // Redirect to /driving-team
    };

    return (
      <>
        <div className="page-mask page-mask-animation">
          <div className="success-mssg">
            <h2 className="global-h2 black">Success!</h2>
            <h4 className="global-h4 gray">The driver has been created</h4>
            <button onClick={handleSuccessClose} className="profile-pic-btn">
              Close
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="global-cont">
      <div className="page-overview">
        <div className="cont-wrapper">
          <div className="overview-header">
            <h1 className="global-h1 black">{t("page-headers.new-driver")}</h1>
            {errorMessage && <p className="error">{errorMessage}</p>}
            {step === 1 && (
              <h4 className="global-h4 gray">
                {t("page-subheaders.driver-profile")}
              </h4>
            )}
            {step === 2 && (
              <h4 className="global-h4 gray">Emergency Contact Information</h4>
            )}
            {step === 3 && <h4 className="global-h4 gray">Review</h4>}
          </div>
          {step === 1 && (
            <AddNewDriver1
              next={nextStep}
              onData={onData}
              data={getStepData()}
            />
          )}
          {step === 2 && (
            <AddNewDriver2
              back={prevStep}
              next={nextStep}
              onData={onData}
              data={getStepData()}
            />
          )}
          {step === 3 && (
            <>
              <AddNewDriverFinal
                back={prevStep}
                onData={onData}
                data={getStepData()}
                submit={handleSubmit}
              />
              {submitSuccess && <SuccessModal />}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
