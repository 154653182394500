import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../CSS/inputs.css";

/**
 * Dropdown Component
 *
 * @component
 *
 * @prop {Object} props - The component props.
 * @prop {string} props.text - The text displayed when the select is open.
 * @prop {Array} props.options - An array of options passed from the parent component.
 * @prop {function} props.onSelect - A function to pass selected values to the parent component.
 * @prop {string} [props.link] - If the dropdown values are page links, you do not need to pass this prop.
 * @prop {boolean} props.isOpen - A boolean to control the open state of the dropdown.
 * @prop {function} props.onToggleOpen - A function to toggle the open state of the dropdown.
 *
 * @example
 * // Parent function to toggle open state
 * const toggleOpen = (index) => {
 *   const updatedOpenStates = isOpen.map((state, i) => (i === index ? !state : false));
 *   setIsOpen(updatedOpenStates);
 * };
 */


export default function BlankSelect({ text, options, onSelect, link, isOpen, onToggleOpen }) {
  const [selectedItem, setSelectedItem] = useState("");

  const handleSelect = (value) => {
    onSelect(value);
    setSelectedItem(value);
  };

  return (
    <div>
      <div onClick={onToggleOpen} className="report-dropdown noselect">
        {/* If options need to be Links */}
        {link ? (
          <span className="noselect">{text}</span>
        ) : selectedItem ? (
          <span className="noselect">{text}</span>
        ) : (
          <span className="noselect">{text}</span>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          className={`fancy-carrot ${isOpen ? "rotate" : ""}`}
        >
          <path
            d="M0.959997 10.56L7.36 4.15997C7.52 3.99997 7.76 3.91997 8 3.91997C8.24 3.91997 8.48 3.99997 8.64 4.15997L15.04 10.56C15.44 10.96 15.44 11.52 15.04 11.92C14.64 12.32 14.08 12.32 13.68 11.92L7.92 6.15997L2.16 11.92C1.76 12.32 1.2 12.32 0.799996 11.92C0.559997 11.52 0.559998 10.88 0.959997 10.56Z"
            fill="#393838"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="dropdown-list calendar-drop">
          {options && options.length > 0 ? (
            options.map((item, index) =>
              link ? (
                <Link
                  className="text-decoration-none text-dark"
                  to={link[index]}
                  key={index}
                >
                  <div
                    className="dropdown-checkbox-cont"
                    onClick={() => handleSelect(item)}
                  >
                    <span className="drop-menu-item">{item}</span>
                  </div>
                </Link>
              ) : (
                <div
                  className="dropdown-checkbox-cont"
                  key={index}
                  onClick={() => handleSelect(item)}
                >
                  <span className="drop-menu-item">{item}</span>
                </div>
              )
            )
          ) : (
            <div className="dropdown-checkbox-cont">
              <span>No data found</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
