import { useState, useEffect } from "react";
import instance from "../../Interceptor/api_instance";
import Select from "react-select";

export default function Samsara({ close }) {
  const [isAti, setIsAti] = useState(null);
  const [isTruck, setIsTruck] = useState(null);
  const [data, setData] = useState({
    customer: "",
    asset: "",
    duration: 3,
    email: "",
    is_truck: null,
    is_ati: null,
  });

  const [atiTruckList, setATITruckList] = useState([]);
  const [atiTrailerList, setATITrailerList] = useState([]);
  const [jfgTruckList, setJFGTruckList] = useState([]);

  useEffect(() => {
    const getTrucks = async () => {
      try {
        const truckAssets = await instance.get('assets/all-truck-assets');
        if (truckAssets.status === 200) {
          setATITruckList(truckAssets.data.ati);
        }
        if (truckAssets.status === 200) {
          setJFGTruckList(truckAssets.data.jfg);
        }
      } catch (e) {
        window.alert(e);
      }
    };

    const getTrailers = async () => {
      try {
        const atiTrailers = await instance.get("assets/out-trailers");
        setATITrailerList(atiTrailers.data);
      } catch (e) {
        window.alert(e);
      }
    };
    getTrailers();
    getTrucks();
  }, []);

  // Update the `data` state when `isAti` or `isTruck` changes
  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      is_ati: isAti,
      is_truck: isTruck,
    }));
  }, [isAti, isTruck]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted data", data);
    const response = await instance.post('assets/live-share', data);
    // try {
    //   const response = instance.post("", data);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  // Handling changes from react-select
  const handleAssetkNumberChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setData((prevState) => ({ ...prevState, asset: value }));
  };

  // Handling input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px 16px",
      border: "none",
      width: "100%",
      borderRadius: "16px",
      background: "var(--neutral-light-purple, #f4f4ff)",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      color: "#5656b9",
      minHeight: "auto",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
      color: "#5656b9",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5656b9",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F4FF",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      borderRadius: "24px",
      padding: "16px",
      marginTop: "10px",
    }),
  };

  const atiTruckListOptions = atiTruckList?.map((truck) => ({
    value: truck.name,
    label: truck.name,
  }));

  const atiTrailerListOptions = atiTrailerList?.map((trailer) => ({
    value: `${trailer.unit}-${trailer.unit_number}`,
    label: `${trailer.unit}-${trailer.unit_number}`,
  }));

  const jfgTruckListOptions = jfgTruckList?.map((truck) => ({
    value: truck.name,
    label: truck.name,
  }));

  return (
    <>
      <div className="page-mask">
        <div style={{ width: "600px" }} className="questions-cont">
          <h4 className="global-h2">Samsara Modal</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="input-label required">Carrier</label>
              <div className="input-cont">
                <fieldset className="fieldsets">
                  <div className="radio-options">
                    <input
                      type="radio"
                      name="carrier"
                      className="radio-input"
                      checked={isAti === false}
                      onChange={() => setIsAti(false)}
                      required
                    />
                    <label>JFG</label>
                  </div>
                  <div className="radio-options">
                    <input
                      type="radio"
                      name="carrier"
                      className="radio-input"
                      onChange={() => setIsAti(true)}
                      checked={isAti === true}
                    />
                    <label>ATI</label>
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="form-group">
              <label className="input-label required">Type</label>
              <div className="input-cont">
                <fieldset className="fieldsets">
                  <div className="radio-options">
                    <input
                      type="radio"
                      name="type"
                      className="radio-input"
                      checked={isTruck === true}
                      onChange={() => setIsTruck(true)}
                      required
                    />
                    <label>Truck</label>
                  </div>
                  <div className="radio-options">
                    <input
                      type="radio"
                      name="type"
                      className="radio-input"
                      checked={isTruck === false}
                      onChange={() => setIsTruck(false)}
                    />
                    <label>Trailer</label>
                  </div>
                </fieldset>
              </div>
            </div>

            {isAti && (
              <>
                {isTruck && (
                  <div className="form-group">
                    <label className="input-label required">Truck number</label>

                    <div id="checkin-truck-number" className="input-cont">
                      <Select
                        value={atiTruckListOptions.find(
                          (option) => option.value === data.asset
                        )}
                        onChange={handleAssetkNumberChange}
                        options={atiTruckListOptions}
                        isClearable={true}
                        isSearchable={true}
                        styles={customStyles}
                        unstyled={true}
                        required
                        isLoading={atiTruckListOptions.length === 0}
                        loadingMessage={() => "Loading options, please wait..."}
                      />
                    </div>
                  </div>
                )}
                {!isTruck && (
                  <div className="form-group">
                    <label className="input-label required">
                      Trailer number
                    </label>

                    <div id="checkin-truck-number" className="input-cont">
                      <Select
                        value={atiTrailerListOptions.find(
                          (option) => option.value === data.asset
                        )}
                        onChange={handleAssetkNumberChange}
                        options={atiTrailerListOptions}
                        isClearable={true}
                        isSearchable={true}
                        styles={customStyles}
                        unstyled={true}
                        required
                        isLoading={atiTrailerListOptions.length === 0}
                        loadingMessage={() => "Loading options, please wait..."}
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {!isAti && isTruck && (
              <div className="form-group">
                <label className="input-label required">Truck number</label>

                <div id="checkin-truck-number" className="input-cont">
                  <Select
                    value={jfgTruckListOptions.find(
                      (option) => option.value === data.asset
                    )}
                    onChange={handleAssetkNumberChange}
                    options={jfgTruckListOptions}
                    isClearable={true}
                    isSearchable={true}
                    styles={customStyles}
                    unstyled={true}
                    required
                    isLoading={jfgTruckListOptions.length === 0}
                    loadingMessage={() => "Loading options, please wait..."}
                  />
                </div>
              </div>
            )}

            <div className="form-group">
              <label className="input-label required">Duration</label>
              <div className="input-cont">
                <input
                  type="number"
                  name="duration"
                  value={data.duration}
                  onChange={handleChange}
                  className="text-input"
                  min={3}
                  max={14}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="input-label required">Customer name</label>
              <div className="input-cont">
                <input
                  type="text"
                  name="customer"
                  value={data.customer}
                  onChange={handleChange}
                  className="text-input"
                  placeholder="John Doe"
                />
              </div>
            </div>

            <div className="form-group">
              <label className="input-label required">Email</label>
              <div className="input-cont">
                <input
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  className="text-input"
                  placeholder="email@email.com"
                />
              </div>
            </div>

            <div className="full-cont">
              <button
                className="change-pwd-btn"
                id="continue-btn"
                type="submit"
              >
                Submit
              </button>
              <button onClick={close} className="cancel-btn">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
