import heic2any from "heic2any";

export const convertHeicToJpeg = async (file) => {
  if (file.name.endsWith(".heic") || file.name.endsWith(".HEIC")) {
    const blob = await heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 0.5,
    });
    return new File([blob], file.name.replace(/\.[^/.]+$/, ".jpg"), {
      type: "image/jpeg",
    });
  }
  return file;
};
