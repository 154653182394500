import React, { useState, useEffect } from "react";
import "../../CSS/inputs.css";
import BlankSelect from "./BlankSelect";

/**
 * @prop {function} onChange - a function to handle state change passed from the parent component
 * @prop {date} stored - a state that stores a selected date 

 * The Calendar component in JavaScript allows users to select dates and navigate through months and
 * years.
 * @returns The `Calendar` component is being returned. It consists of a calendar interface with
 * options to select a month and year, navigate between months, view and select dates, and buttons to
 * go to today's date or clear the selected date.
 */
export default function Calendar({ onChange, stored }) {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [activeDate, setActiveDate] = useState(null);

  const [isOpen, setIsOpen] = useState(Array(2).fill(false));
  const toggleOpen = (index) => {
    const updatedOpenStates = isOpen.map((state, i) =>
      i === index ? !state : false
    );
    setIsOpen(updatedOpenStates);
  };

  useEffect(() => {
    if (stored) {
      const storedSplit = stored.split("/");

      // Create a new Date object
      const date = new Date(storedSplit[2], storedSplit[0] - 1, storedSplit[1]);

      setSelectedMonth(storedSplit[0] - 1)
      setActiveDate(date);
    }
  }, [stored]);
 
  const currentDate = new Date();

  const currentMonth =
    selectedMonth !== "" ? selectedMonth : currentDate.getMonth();
  const currentYear =
    selectedYear !== "" ? selectedYear : currentDate.getFullYear();

  // months with values for Date() to work with
  const allMonths = [
    { name: "January", value: 0 },
    { name: "February", value: 1 },
    { name: "March", value: 2 },
    { name: "April", value: 3 },
    { name: "May", value: 4 },
    { name: "June", value: 5 },
    { name: "July", value: 6 },
    { name: "August", value: 7 },
    { name: "September", value: 8 },
    { name: "October", value: 9 },
    { name: "November", value: 10 },
    { name: "December", value: 11 },
  ];

  // Array of years
  const years = Array.from(
    { length: currentDate.getFullYear() - 2023 },
    (_, index) => 2024 + index
  );

  // select handlers
  const handleMonthSelect = (monthName) => {
    const selectedMonthObject = allMonths.find(
      (month) => month.name === monthName
    );
    if (selectedMonthObject) {
      const selectedMonthValue = selectedMonthObject.value;
      setSelectedMonth(selectedMonthValue);
    }
    toggleOpen();
  };

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    toggleOpen();
  };

  // Calendar logic
  const renderCalendar = () => {
    const calendarDays = [];
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay(); // Day of the week for the first day of the month (0 = Sunday)
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Total days in the current month
    const daysInPrevMonth = new Date(currentYear, currentMonth, 0).getDate(); // Total days in the previous month

    let dayCount = 1;
    let prevMonthDayCount = daysInPrevMonth - firstDayOfMonth + 1;

    const handleDayClick = (date) => {
      setActiveDate(date);
      if (onChange) {
        onChange(date);
      }
    };

    // Renders 7 days per row aligning with the days of the week
    for (let week = 0; dayCount <= daysInMonth; week++) {
      const weekDays = [];
      for (let day = 0; day < 7; day++) {
        if (week === 0 && day < firstDayOfMonth) {
          // Render days from the previous month
          const date = new Date(
            currentYear,
            currentMonth - 1,
            prevMonthDayCount
          );
          weekDays.push(
            <div
              key={`prev-month-${prevMonthDayCount}`}
              className="empty"
              onClick={() => handleDayClick(date)}
            >
              {prevMonthDayCount}
            </div>
          );
          prevMonthDayCount++;
        } else if (dayCount > daysInMonth) {
          // Render days after the current month
          const date = new Date(
            currentYear,
            currentMonth + 1,
            dayCount - daysInMonth
          );

          weekDays.push(
            <div
              key={`next-month-${dayCount - daysInMonth}`}
              className="empty"
              onClick={() => handleDayClick(date)}
            >
              {dayCount - daysInMonth}
            </div>
          );
          dayCount++;
        } else {
          // Render days of the current month
          const date = new Date(currentYear, currentMonth, dayCount);
          weekDays.push(
            <div
              key={date.toDateString()}
              className={`day ${
                activeDate &&
                date.toDateString() === activeDate.toDateString() &&
                "day-clicked"
              }`}
              onClick={() => handleDayClick(date)}
            >
              {dayCount}
            </div>
          );
          dayCount++;
        }
      }
      calendarDays.push(
        <div key={`week-${week}`} className="week">
          {weekDays}
        </div>
      );
    }
    return calendarDays;
  };

  // Handle header arrows to change months
  const handleMonthArrowright = () => {
    if (selectedMonth <= 10) {
      setSelectedMonth(currentMonth + 1);
    }
    if (selectedMonth === 11) {
      setSelectedMonth(0);
    }
  };
  const handleMonthArrowleft = () => {
    if (selectedMonth >= 0) {
      setSelectedMonth(currentMonth - 1);
    }
    if (selectedMonth === 0) {
      setSelectedMonth(11);
    }
  };

  // Today and clear btns
  const handleToday = () => {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    setActiveDate(currentDate);
    setSelectedYear(currentYear);
    setSelectedMonth(currentMonth);

    if (onChange) {
      onChange(currentDate);
    }
  };

  const handleClear = () => {
    setActiveDate("");
    if (onChange) {
      onChange("");
    }
  };

  return (
    <div className="calendar-cont noselect">
      <div className="calendar-wrapper">
        <div className="month-year">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            onClick={handleMonthArrowleft}
          >
            <path
              d="M11.2382 15.0162L4.83816 8.6162C4.67816 8.4562 4.59816 8.2162 4.59816 7.9762C4.59816 7.7362 4.67816 7.4962 4.83816 7.3362L11.2382 0.9362C11.6382 0.5362 12.1982 0.5362 12.5982 0.9362C12.9982 1.3362 12.9982 1.8962 12.5982 2.2962L6.83816 8.0562L12.5982 13.8162C12.9982 14.2162 12.9982 14.7762 12.5982 15.1762C12.1982 15.4162 11.5582 15.4162 11.2382 15.0162Z"
              fill="#5656B9"
            />
          </svg>
          <BlankSelect
            onSelect={(value) => handleMonthSelect(value)}
            options={allMonths.map((month) => month.name)}
            text={allMonths[currentMonth].name}
            isOpen={isOpen[0]}
            onToggleOpen={() => toggleOpen(0)}
          />
          <BlankSelect
            onSelect={(value) => handleYearSelect(value)}
            options={years}
            text={currentYear}
            isOpen={isOpen[1]}
            onToggleOpen={() => toggleOpen(1)}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            onClick={handleMonthArrowright}
          >
            <path
              d="M6.39816 0.983801L12.7982 7.3838C12.9582 7.5438 13.0382 7.7838 13.0382 8.0238C13.0382 8.2638 12.9582 8.5038 12.7982 8.6638L6.39816 15.0638C5.99816 15.4638 5.43816 15.4638 5.03816 15.0638C4.63816 14.6638 4.63816 14.1038 5.03816 13.7038L10.7982 7.9438L5.03816 2.1838C4.63816 1.7838 4.63816 1.2238 5.03816 0.8238C5.43816 0.5838 6.07816 0.583801 6.39816 0.983801Z"
              fill="#5656B9"
            />
          </svg>
        </div>
        <div className="week">
          <span>S</span>
          <span>M</span>
          <span>T</span>
          <span>W</span>
          <span>T</span>
          <span>F</span>
          <span>S</span>
        </div>
        <div className="day-cont">
          {renderCalendar()}
          <div className="dot"></div>
        </div>
        <div className="calendar-btn-cont">
          <button onClick={handleToday} className="calendar-btn">
            Today
          </button>
          <button onClick={handleClear} className="calendar-btn">
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}
