import { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { UsFlag, MexFlag } from "../../Media/Icons";

const countries = {
  USA: {
    code: "1",
    icon: <UsFlag />,
  },
  MEX: {
    code: "52",
    icon: <MexFlag />,
  },
};

/**
 * The PhoneInput component in JavaScript allows users to input phone numbers with country code
 * selection and formatting.
 * @returns The `PhoneInput` component is being returned. It consists of a phone input field with a
 * dropdown to select country codes. The component allows users to input phone numbers with the
 * corresponding country code displayed next to the input field. The selected country code determines
 * the format of the phone number input.
 */

export const PhoneInput = ({ name, value, change, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeCode, setActiveCode] = useState(countries["USA"]);

  useEffect(() => {
    if (typeof value === "string") {
      if (value.startsWith("1")) {
        setActiveCode(countries["USA"]);
      } else if (value.startsWith(countries["52"])) {
        setActiveCode("MEX");
      }
    }
  }, [value]);

  const toggleIsOpen = () => setIsOpen((current) => !current);

  const handleCountryCode = (key) => {
    setActiveCode(key);
    toggleIsOpen();
  };

  useEffect(() => {
    if (typeof value === "string") {
      if (value.startsWith("1")) {
        setActiveCode(countries["USA"]);
      } else if (value.startsWith("52")) {
        setActiveCode(countries["MEX"]);
      }
    }
  }, [value]);

  const CountryDropDown = () => {
    return (
      <div className="phone-drop-down">
        {Object.keys(countries).map((key) => (
          <span onClick={() => handleCountryCode(countries[key])} key={key}>
            {countries[key].icon} {countries[key].code}
          </span>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="input-cont phone">
        <div onClick={toggleIsOpen} className="phone-icon-cont">
          {activeCode.code === "1"
            ? countries["USA"].icon
            : countries["MEX"].icon}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
          >
            <path
              d="M15.1982 2.51001L8.79818 8.91001C8.63818 9.07001 8.39818 9.15001 8.15818 9.15001C7.91818 9.15001 7.67818 9.07001 7.51818 8.91001L1.11818 2.51001C0.718176 2.11001 0.718176 1.55001 1.11818 1.15001C1.51818 0.750006 2.07818 0.750006 2.47818 1.15001L8.23818 6.91001L13.9982 1.15001C14.3982 0.750006 14.9582 0.750006 15.3582 1.15001C15.5982 1.55001 15.5982 2.19001 15.1982 2.51001Z"
              fill="#5656B9"
            />
          </svg>
        </div>
        <PatternFormat
          format={`${activeCode.code}##########`}
          defaultValue={
            value?.value ??
            (typeof value === "string"
              ? value
                  .replace(/^\+\d+\s?/, "")
                  .match(/\d/g)
                  ?.join("")
              : "")
          }
          valueIsNumericString={true}
          name={name}
          placeholder={`${activeCode.code} 555-555-5555`}
          onValueChange={(values) => {
            change(name, values);
          }}
          required
          id={id}
        />
        {isOpen && <CountryDropDown />}
      </div>
    </>
  );
};
