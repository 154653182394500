import React, { memo } from "react";
import Select from "react-select";
import { Spinner } from "../LoadingAnimations/LoadingAnimations";

const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: "4px 16px",
    border: "none",
    width: "100%",
    borderRadius: "16px",
    background: "var(--neutral-light-purple, #f4f4ff)",
    boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
    color: "#5656b9",
    minHeight: "auto",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
    color: "#5656b9",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#5656b9",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#F4F4FF",
    boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
    borderRadius: "24px",
    padding: "16px",
    marginTop: "10px",
  }),
};

const ReservationConfirm = memo(
  ({
    row,
    english,
    confirmLoading,
    errorMessage,
    handleResevationConfirm,
    handleTruckChange,
    ATITruckOptions,
    JFGTruckOptions,
    selectedATITruck,
    selectedJFGTruck,
    setReservationModal,
  }) => {
    return (
      <div className="page-mask">
        <div className="blank-card active-confirm">
          {confirmLoading ? (
            <Spinner />
          ) : errorMessage ? (
            <>
              <p className="error">{errorMessage}</p>
              <button
                className="profile-pic-btn"
                onClick={() => handleResevationConfirm(false, row)}
              >
                {english("buttons.close")}
              </button>
            </>
          ) : (
            <>
              <h4 className="">{`${
                row.row[1].charAt(0).toUpperCase() + row.row[1].slice(1)
              } trailer ${row.row[2]}`}</h4>

              {(row.row[1] === "reserve" || row.row[1] === "reservar") && (
                <>
                  <p className="gray mb-4">
                    {english("forms.type.edit-reservation.select-truck")}
                  </p>
                  <div className="form-group">
                    <label htmlFor="">{english("forms.type.edit-reservation.ati-trucks")}</label>
                    <div className="input-cont">
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        unstyled={true}
                        placeholder={english("forms.type.edit-reservation.select")}
                        styles={customStyles}
                        options={ATITruckOptions}
                        name="ATITruck"
                        onChange={handleTruckChange}
                        value={selectedATITruck}
                        isLoading={ATITruckOptions.length === 0}
                        loadingMessage={() => "Loading options, please wait..."}
                        isDisabled={selectedJFGTruck ? true : false}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{english("forms.type.edit-reservation.jfg-trucks")}</label>
                    <div className="input-cont">
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        unstyled={true}
                        placeholder={english("forms.type.edit-reservation.select")}
                        styles={customStyles}
                        options={JFGTruckOptions}
                        name="JFGTruck"
                        onChange={handleTruckChange}
                        value={selectedJFGTruck}
                        isLoading={JFGTruckOptions.length === 0}
                        loadingMessage={() => "Loading options, please wait..."}
                        isDisabled={selectedATITruck ? true : false}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex gap-2 justify-content-center">
                <button
                  className="profile-pic-btn"
                  onClick={() => handleResevationConfirm(true, row)}
                >
                  {row.row[1].charAt(0).toUpperCase() + row.row[1].slice(1)}
                </button>
                <button
                  className="profile-pic-btn"
                  onClick={() => setReservationModal(false)}
                >
                  {english("buttons.close")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default ReservationConfirm;
