import React, { useState, useEffect } from 'react';

/**
 * The Switch component in JavaScript manages animation activation through toggling based on the active
 * state.
 * @returns The code snippet is a React functional component named Switch. It returns JSX that renders
 * a switch component with a background path and a moveable path inside an SVG element. The appearance
 * of the switch is determined by the isActive state, which toggles between two different fill colors
 * based on the active prop. The switch can be clicked to toggle its animation state.
 */
export default function Switch({active, onClick}) {
  // State to manage animation activation
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]); 

  // Function to toggle animation
  const toggleAnimation = () => {
    setIsActive(!isActive);
   
  };


  return (
    <>
      <div className="switch-cont" >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="49"
          height="24"
          viewBox="0 0 49 24"
          fill="none"
        >
          <path
            className="switch-bg"
            d="M13.5159 2.40002C8.23588 2.40002 3.91588 6.72002 3.91588 12C3.91588 17.28 8.23588 21.6 13.5159 21.6H35.3559C40.6359 21.6 44.9559 17.28 44.9559 12C44.9559 6.72002 40.6359 2.40002 35.3559 2.40002H13.5159Z"
            fill={!isActive ? '#B9B9D7' : "#9CF28F"}
          />
          <path
            className={`switch-move ${isActive ? 'switch-active' : ''} ${!isActive ? 'switch-deactive' : ''}`}
            d="M42.9159 12C42.9159 14.04 42.0759 15.96 40.7559 17.28C39.3159 18.72 37.3959 19.44 35.4759 19.44C33.4359 19.44 31.5159 18.6 30.1959 17.28C28.7559 15.84 28.0359 13.92 28.0359 12C28.0359 9.96 28.8759 8.04 30.1959 6.72C31.6359 5.28 33.5559 4.56 35.4759 4.56C37.5159 4.56 39.4359 5.4 40.7559 6.72C42.0759 8.04 42.9159 9.96 42.9159 12Z"
            fill="white"
          />
        </svg>
      </div>
    </>
  );
}
