import "../../CSS/guardOverview.css";
import "../../CSS/global.css";
import "../../CSS/report.css";
import "../../CSS/fancyDropDown.css";
import { useState } from "react";
import RangeSlider from "../Inputs/RangeSlider";
import Calendar from "../Inputs/Calendar";
import { Helmet } from "react-helmet";
import instance from "../../Interceptor/api_instance";
import { PDFDocument } from "pdf-lib";
import JSZip from "jszip";
import ProgressBar from "react-bootstrap/ProgressBar";

/**
 * The `FormExport` function in JavaScript is a component that handles exporting reports based on
 * selected date and time ranges, with progress tracking and PDF generation functionality.
 * @returns The `FormExport` function is being returned, which is a React component responsible for
 * rendering a form to export reports based on selected date and time ranges. The form includes input
 * fields for start and end dates, a range slider for selecting hours, and a button to trigger the
 * export process. The component also handles state management, data formatting, fetching data from an
 * API endpoint, creating PDF reports, and
 */

export default function FormExport({ type, pdfFile, endPoint }) {
  const [isOpenStart, setIsOpenStart] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [progress, setProgress] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleRange = (min, max) => {
    setStartTime(min);
    setEndTime(max);
  };

  const handleStartDate = (min) => {
    setStartDate(min);
  };
  const handleEndDate = (min) => {
    setEndDate(min);
  };

  const handleExport = () => {
    // Check if startDate and endDate are not empty strings
    if (startDate && endDate) {
      const exportData = {
        startTime: startTime,
        endTime: endTime,
        startDate: startDate,
        endDate: endDate,
      };
      fetchData(exportData);
    } else {
      alert("Please select both start and end dates");
    }
  };

  const fetchData = async (exportData) => {
    try {
      const response = await instance.post(endPoint, exportData);
      setShowModal(true); // Show the loading modal
      console.profile();
      await createPdf(response.data, setProgress);
      console.profileEnd();
      setShowModal(false); // Hide the modal after completion
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenStart = () => {
    setIsOpenStart(!isOpenStart);
  };
  const handleOpenEnd = () => {
    setIsOpenEnd(!isOpenEnd);
  };

  // Check if startDate is a valid Date object before formatting
  if (startDate instanceof Date && !isNaN(startDate)) {
    // If startDate is a valid Date object, proceed with formatting
    const formattedStartDate = startDate.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });

    setStartDate(formattedStartDate);
  }

  // Check if endDate is a valid Date object before formatting
  if (endDate instanceof Date && !isNaN(endDate)) {
    // If endDate is a valid Date object, proceed with formatting
    const formattedEndDate = endDate.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });

    setEndDate(formattedEndDate);
  }

  const LoadingModal = ({ progress }) => {
    return (
      <>
        <div className="progress-cont">
          <p className="gray">Fetching Reports</p>
          {/* <progress value={progress} max="100"></progress> */}
          <div style={{ width: "200px" }}>
            <ProgressBar
              now={progress}
              label={`${Math.round(progress)}%`}
              className="custom-progress-bar"
            />
          </div>
        </div>
      </>
    );
  };

  // Function to format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  // Function to format time
  function formatTime(timeString) {
    const time = new Date(timeString);
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  // Function to set text fields based on boolean value
  function setTextFromBoolean(value) {
    if (value === null) {
      return "N/A";
    } else {
      return value ? "OK" : "Poor";
    }
  }
  const createPdf = async (reports, updateProgress) => {
    try {
      const zip = new JSZip(); // Step 1: Create a new JSZip instance

      // Calculate total number of reports
      const totalReports = reports.length;

      // Create an array to store promises for each PDF creation
      const pdfPromises = [];

      for (let i = 0; i < totalReports; i++) {
        const report = reports[i];

        // Report progress
        const progress = ((i + 1) / totalReports) * 100;
        updateProgress(progress);

        // Load the existing PDF template
        const pdfTemplateBytes = await fetch(`./${pdfFile}`).then((res) =>
          res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(pdfTemplateBytes);

        // Get the form
        const form = pdfDoc.getForm();

        // different types will have different keys Samsara or our own backend

        if (type === "Driver") {
          // Populate text fields
          if (report.check_in_dt !== null) {
            const formattedDate = formatDate(
              report.check_in_dt ? report.check_in_dt : "N/A"
            );
            const formattedTime = formatTime(
              report.check_in_dt ? report.check_in_dt : "N/A"
            );

            form.getTextField("entry_date").setText(formattedDate);
            form.getTextField("time_of_entry").setText(formattedTime);
            form
              .getTextField("is_loaded")
              .setText(report.is_loaded ? "Loaded" : "Empty");
            form.getTextField("truck_number").setText(report.truck_number);
            form.getTextField("truck_plates").setText(report.truck_plate);
            form.getTextField("trailer_number").setText(report.trailer_number);
            form
              .getTextField("trailer_plates")
              .setText(report.trailer_license_plate);
            form
              .getTextField("seal_number")
              .setText(report.seal_number ? report.seal_number : "N/A");
            form
              .getTextField("driver_name")
              .setText(`${report.first_name} ${report.last_name}`);
            form
              .getTextField("origin")
              .setText(report.origin ? report.origin : "N/A");
          }

          if (report.check_out_dt !== null) {
            const formattedDate = formatDate(
              report.check_out_dt ? report.check_out_dt : "N/A"
            );
            const formattedTime = formatTime(
              report.check_out_dt ? report.check_out_dt : "N/A"
            );

            form.getTextField("exit_date").setText(formattedDate);
            form.getTextField("exit_time").setText(formattedTime);
            form
              .getTextField("exit_is_loaded")
              .setText(report.is_loaded ? "Loaded" : "Empty");
            form.getTextField("exit_truck_num").setText(report.truck_number);
            form.getTextField("exit_truck_plates").setText(report.truck_plate);
            form
              .getTextField("exit_trailer_number")
              .setText(report.trailer_number);
            form
              .getTextField("exit_trailer_plates")
              .setText(report.trailer_license_plate);
            form
              .getTextField("exit_seal_number")
              .setText(report.seal_number ? report.seal_number : "N/A");
            form
              .getTextField("exit_driver_name")
              .setText(`${report.first_name} ${report.last_name}`);
            form
              .getTextField("destination")
              .setText(report.destination ? report.destination : "N/A");
          }

          // Set text for inspection
          form
            .getTextField("bumper")
            .setText(setTextFromBoolean(report.bumper));
          form
            .getTextField("engine")
            .setText(setTextFromBoolean(report.engine));
          form.getTextField("tires").setText(setTextFromBoolean(report.tires));
          form
            .getTextField("cab_floor")
            .setText(setTextFromBoolean(report.cab_floor));
          form
            .getTextField("fuel_tank")
            .setText(setTextFromBoolean(report.fuel_tank));
          form
            .getTextField("cab_compartments")
            .setText(setTextFromBoolean(report.cab_and_compartment));
          form
            .getTextField("air_tank")
            .setText(setTextFromBoolean(report.air_tank));
          form
            .getTextField("drive_shafts")
            .setText(setTextFromBoolean(report.drive_shafts));
          form
            .getTextField("fifth_wheel")
            .setText(setTextFromBoolean(report.fifth_wheel));
          form
            .getTextField("chassis")
            .setText(setTextFromBoolean(report.chassis));
          form
            .getTextField("in_out_doors")
            .setText(setTextFromBoolean(report.inside_outside_doors));
          form
            .getTextField("trailer_floor")
            .setText(setTextFromBoolean(report.trailer_floor));
          form
            .getTextField("side_walls")
            .setText(setTextFromBoolean(report.side_walls));
          form
            .getTextField("front_wall")
            .setText(setTextFromBoolean(report.frontal_wall));
          form
            .getTextField("roof_ceiling")
            .setText(setTextFromBoolean(report.trailer_ceiling));
          form
            .getTextField("refrigeration_unit")
            .setText(setTextFromBoolean(report.fridge_unit));
          form
            .getTextField("exhaust")
            .setText(setTextFromBoolean(report.exhaust));
          form
            .getTextField("seal")
            .setText(setTextFromBoolean(report.seal_condition));
          form
            .getTextField("pest_garbage_free")
            .setText(setTextFromBoolean(report.pest_and_garbage_free));
          form
            .getTextField("cleanliness")
            .setText(setTextFromBoolean(report.cleanliness));
          form
            .getTextField("light_filtering")
            .setText(setTextFromBoolean(report.light_filtering));
          form
            .getTextField("humidity")
            .setText(setTextFromBoolean(report.humidity));

          // Set check boxes for VVTT
          form.getCheckBox("view_seal").check(report.view_seal);
          form.getCheckBox("verify_seal").check(report.verify_seal);
          form.getCheckBox("tug_seal").check(report.tug_seal);
          form.getCheckBox("twist_turn").check(report.twist_turn_seal);

          form
            .getTextField("notes")
            .setText(report.notes ? report.notes : "N/A");

          form
            .getTextField("width")
            .setText(report.trailer_dimensions[1].toString());
          form
            .getTextField("height")
            .setText(report.trailer_dimensions[2].toString());
          form
            .getTextField("length")
            .setText(report.trailer_dimensions[0].toString());
          form
            .getTextField("Text9")
            .setText(`${report.guard_first_name} ${report.guard_last_name}`);
        }
        if (type === "Check") {
          // Populate text fields
          if (report.check_in_dt !== null) {
            const formattedDate = formatDate(
              report.check_in_dt ? report.check_in_dt : "N/A"
            );
            const formattedTime = formatTime(
              report.check_in_dt ? report.check_in_dt : "N/A"
            );

            form.getTextField("entry_date").setText(formattedDate);
            form.getTextField("time_of_entry").setText(formattedTime);
            form
              .getTextField("is_loaded")
              .setText(report.is_loaded ? "Loaded" : "Empty");
            form.getTextField("truck_number").setText(report.truck_number);
            form.getTextField("truck_plates").setText(report.truck_plate);
            form.getTextField("trailer_number").setText(report.trailer_number);
            form
              .getTextField("trailer_plates")
              .setText(report.trailer_license_plate);
            form
              .getTextField("seal_number")
              .setText(report.seal_number ? report.seal_number : "N/A");
            form
              .getTextField("driver_name")
              .setText(`${report.first_name} ${report.last_name}`);
            form
              .getTextField("origin")
              .setText(report.origin ? report.origin : "N/A");
          }

          if (report.check_out_dt !== null) {
            const formattedDate = formatDate(
              report.check_out_dt ? report.check_out_dt : "N/A"
            );
            const formattedTime = formatTime(
              report.check_out_dt ? report.check_out_dt : "N/A"
            );

            form.getTextField("exit_date").setText(formattedDate);
            form.getTextField("exit_time").setText(formattedTime);
            form
              .getTextField("exit_is_loaded")
              .setText(report.is_loaded ? "Loaded" : "Empty");
            form.getTextField("exit_truck_num").setText(report.truck_number);
            form.getTextField("exit_truck_plates").setText(report.truck_plate);
            form
              .getTextField("exit_trailer_number")
              .setText(report.trailer_number);
            form
              .getTextField("exit_trailer_plates")
              .setText(report.trailer_license_plate);
            form
              .getTextField("exit_seal_number")
              .setText(report.seal_number ? report.seal_number : "N/A");
            form
              .getTextField("exit_driver_name")
              .setText(`${report.first_name} ${report.last_name}`);
            form
              .getTextField("destination")
              .setText(report.destination ? report.destination : "N/A");
          }

          // Set text for inspection
          form
            .getTextField("bumper")
            .setText(setTextFromBoolean(report.bumper));
          form
            .getTextField("engine")
            .setText(setTextFromBoolean(report.engine));
          form.getTextField("tires").setText(setTextFromBoolean(report.tires));
          form
            .getTextField("cab_floor")
            .setText(setTextFromBoolean(report.cab_floor));
          form
            .getTextField("fuel_tank")
            .setText(setTextFromBoolean(report.fuel_tank));
          form
            .getTextField("cab_compartments")
            .setText(setTextFromBoolean(report.cab_and_compartment));
          form
            .getTextField("air_tank")
            .setText(setTextFromBoolean(report.air_tank));
          form
            .getTextField("drive_shafts")
            .setText(setTextFromBoolean(report.drive_shafts));
          form
            .getTextField("fifth_wheel")
            .setText(setTextFromBoolean(report.fifth_wheel));
          form
            .getTextField("chassis")
            .setText(setTextFromBoolean(report.chassis));
          form
            .getTextField("in_out_doors")
            .setText(setTextFromBoolean(report.inside_outside_doors));
          form
            .getTextField("trailer_floor")
            .setText(setTextFromBoolean(report.trailer_floor));
          form
            .getTextField("side_walls")
            .setText(setTextFromBoolean(report.side_walls));
          form
            .getTextField("front_wall")
            .setText(setTextFromBoolean(report.frontal_wall));
          form
            .getTextField("roof_ceiling")
            .setText(setTextFromBoolean(report.trailer_ceiling));
          form
            .getTextField("refrigeration_unit")
            .setText(setTextFromBoolean(report.fridge_unit));
          form
            .getTextField("exhaust")
            .setText(setTextFromBoolean(report.exhaust));
          form
            .getTextField("seal")
            .setText(setTextFromBoolean(report.seal_condition));
          form
            .getTextField("pest_garbage_free")
            .setText(setTextFromBoolean(report.pest_and_garbage_free));
          form
            .getTextField("cleanliness")
            .setText(setTextFromBoolean(report.cleanliness));
          form
            .getTextField("light_filtering")
            .setText(setTextFromBoolean(report.light_filtering));
          form
            .getTextField("humidity")
            .setText(setTextFromBoolean(report.humidity));

          // Set check boxes for VVTT
          form.getCheckBox("view_seal").check(report.view_seal);
          form.getCheckBox("verify_seal").check(report.verify_seal);
          form.getCheckBox("tug_seal").check(report.tug_seal);
          form.getCheckBox("twist_turn").check(report.twist_turn_seal);

          form
            .getTextField("notes")
            .setText(report.notes ? report.notes : "N/A");

          form
            .getTextField("width")
            .setText(report.trailer_dimensions[1].toString());
          form
            .getTextField("height")
            .setText(report.trailer_dimensions[2].toString());
          form
            .getTextField("length")
            .setText(report.trailer_dimensions[0].toString());
          form
            .getTextField("Text9")
            .setText(`${report.guard_first_name} ${report.guard_last_name}`);
        }

        // Save the new PDF document
        const pdfBytes = await pdfDoc.save();

        // Create a Blob from the PDF bytes
        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Add the PDF blob to the folder in the zip file
        const folderName = `${type} Reports`;
        const timestamp = new Date().getTime(); // Get current timestamp
        const fileName = `${folderName}_${timestamp}.pdf`;
        if (!zip.folder(folderName)) {
          zip.folder(folderName);
        }
        zip.folder(folderName).file(fileName, blob);

        // Push the promise to the array
        pdfPromises.push(Promise.resolve());
      }

      // Wait for all PDF promises to resolve
      await Promise.all(pdfPromises);

      // Generate the zip file as a Blob
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Trigger the download of the zip file
      const url = URL.createObjectURL(zipBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "reports.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error creating PDF:", error);
    }
  };

  return (
    <div className="global-cont">
      <div className="page-overview">
        <div className="cont-wrapper">
          <>
            <Helmet>
              <title>Forward | Reports</title>
            </Helmet>
            <div>
              <div className="overview-header">
                <h1 className="global-h1 black">{`${type} Reports`}</h1>
              </div>
              <div style={{ width: "100%" }} className="graphics-card">
                <h4 style={{ marginBottom: "25px" }} className="global-h4 gray">
                  Please select the date and/or time range for exporting C-TPAT
                  reports.
                </h4>

                <div className="report-tabs">
                  <p>Date</p>
                  <div className="report-col">
                    <p>Start</p>

                    <div
                      onClick={handleOpenStart}
                      className="report-dropdown noselect"
                    >
                      {startDate ? (
                        <span>{startDate}</span>
                      ) : (
                        <span>-/-/-</span>
                      )}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        className={`fancy-carrot ${
                          isOpenStart ? "rotate" : ""
                        }`}
                      >
                        <path
                          d="M0.959997 10.56L7.36 4.15997C7.52 3.99997 7.76 3.91997 8 3.91997C8.24 3.91997 8.48 3.99997 8.64 4.15997L15.04 10.56C15.44 10.96 15.44 11.52 15.04 11.92C14.64 12.32 14.08 12.32 13.68 11.92L7.92 6.15997L2.16 11.92C1.76 12.32 1.2 12.32 0.799996 11.92C0.559997 11.52 0.559998 10.88 0.959997 10.56Z"
                          fill="#393838"
                        />
                      </svg>
                    </div>
                    {isOpenStart && (
                      <Calendar
                        onChange={(activeDate) => handleStartDate(activeDate)}
                        stored={startDate}
                      />
                    )}
                  </div>
                  <div className="report-col">
                    <p>End</p>
                    <div
                      onClick={handleOpenEnd}
                      className="report-dropdown noselect"
                    >
                      {endDate ? <span>{endDate}</span> : <span>-/-/-</span>}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        className={`fancy-carrot ${isOpenEnd ? "rotate" : ""}`}
                      >
                        <path
                          d="M0.959997 10.56L7.36 4.15997C7.52 3.99997 7.76 3.91997 8 3.91997C8.24 3.91997 8.48 3.99997 8.64 4.15997L15.04 10.56C15.44 10.96 15.44 11.52 15.04 11.92C14.64 12.32 14.08 12.32 13.68 11.92L7.92 6.15997L2.16 11.92C1.76 12.32 1.2 12.32 0.799996 11.92C0.559997 11.52 0.559998 10.88 0.959997 10.56Z"
                          fill="#393838"
                        />
                      </svg>
                    </div>
                    {isOpenEnd && (
                      <Calendar
                        onChange={(activeDate) => handleEndDate(activeDate)}
                        stored={endDate}
                      />
                    )}
                  </div>
                </div>
                {/* hours row */}
                <div className="report-tabs hours-cont">
                  <p>Hours</p>
                  <div className="report-slider">
                    <RangeSlider
                      min={0}
                      max={24}
                      onChange={({ min, max }) => handleRange(min, max)}
                    />
                  </div>
                </div>
                <div className="report-slider">
                  <button onClick={handleExport} className="profile-pic-btn">
                    Export
                  </button>
                  {showModal && (
                    <>
                      <div className="page-mask page-mask-animation">
                        <LoadingModal progress={progress} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}
