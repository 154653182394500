import { useNavigate } from "react-router-dom";
import { checkAuthentication } from "../../Interceptor/csrftoken";
import { isDispatch, isGuard, isSafety } from "../Tables/Sorting/functions";
import { useAuth } from "../../Auth/AuthProvider";

export const HomeRedirect = () => {
    const navigate = useNavigate();
    const {user, updateUser} = useAuth();
    const isAuthenticated = async () => {
        const check = await checkAuthentication(updateUser);
        if (!check) {
            window.location.href = "/login";
        }
    }
    isAuthenticated();
    if (isDispatch(user)) {
            navigate('/dispatch-overview');
    }
    else if (isGuard(user)) {
            navigate('/guard-overview');
    }
    else if (isSafety(user)) {
        navigate('/guard-overview');
}
    else {
        navigate('/login');
    }
}