import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

export const BarGraph = ({ trailers }) => {
  let sdTrailers = 0;
  let tjTrailers = 0;
  let tecTrailers = 0;

  for (let i = 0; i < trailers.length; ++i) {
    if (trailers[i].location.loc_name === "San Diego Yard") {
      ++sdTrailers;
    } else if (trailers[i].location.loc_name === "Tijuana Yard") {
      ++tjTrailers;
    } else if (trailers[i].location.loc_name === "Tecate Yard") {
      ++tecTrailers;
    }
  }
  ChartJS.defaults.color = "#5656B9";
  ChartJS.defaults.font.family = "Poppins";
  ChartJS.defaults.borderColor = "#F4F4FF";

  const values = [sdTrailers, tjTrailers, tecTrailers];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        onClick: () => {},
        position: "top",
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 12,
            family: "Poppins",
          },
        },
      },
    },
    scales: {
      y: {
        max: 100,
        beginAtZero: true,
        ticks: {
          stepSize: 20,
          font: {
            size: 12,
            family: "Poppins",
          },
        },
      },
    },
  };
  const labels = ["San Diego", "Tijuana", "Tecate"];

  const data = {
    labels: labels,
    font: {
      size: 120,
      family: "Poppins",
    },
    datasets: [
      {
        label: "Trailers",
        data: values.map((x) => x),
        backgroundColor: "#CFBEFF",
        color: "#5656B9",
      },
    ],
  };

  return <Bar id="myChart" data={data} options={options} />;
};
