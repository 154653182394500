import { useState, useRef } from "react";
import instance from "../../../Interceptor/api_instance";
import { Dots } from "../../LoadingAnimations/LoadingAnimations";
import { DeleteIcon } from "../../../Media/Icons";
import { convertHeicToJpeg } from "../../Utility/HeicToJpg"; 

export const CheckInVisitor = ({ onClose, english }) => {
  const [firstName, setFirst] = useState("");
  const [lastName, setLast] = useState("");
  const [purpose, setPurpose] = useState("");
  const [otherPurpose, setOtherPurpose] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);
  const [formData, setFormData] = useState({
    photos: [],
  });

  const submit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const selectedPhotos = formData.photos.map((photo) => photo[1]);
      const formDataToSend = new FormData();
      const data = {
        first_name: firstName,
        last_name: lastName,
        reason: purpose !== "Other" || purpose !== "Provider" ? purpose : otherPurpose,
        reasonDesc: purpose !== "Other" || purpose !== "Provider" ? otherPurpose : null,
        category: purpose === 'Other' ? otherPurpose : purpose
      };

      setData(data);

      selectedPhotos.forEach((photo, index) => {
        formDataToSend.append("photo", photo);
      });

      formDataToSend.append("data", JSON.stringify(data));
      const response = await instance.post(
        "assets/check-in-visitor/",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        setSuccess(true);
      }
    } catch (e) {
      window.alert(e);
      setIsLoading(false);
    }
  };

  // Photo upload
  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    const photos = await Promise.all(
      files.map(async (file) => {
        const convertedFile = await convertHeicToJpeg(file);
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e) => resolve([e.target.result, convertedFile]);
          reader.readAsDataURL(convertedFile);
        });
      })
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      photos: [...prevFormData.photos, ...photos],
    }));
  };

  // Function to handle upload button click
  const handleUploadClick = (e) => {
    e.preventDefault(); // Prevent form submission
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Ensure fileInputRef is not null before accessing click method
    }
  };

  const handleCapturePhoto = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    const convertedFile = await convertHeicToJpeg(file);
    setFormData((prevFormData) => ({
      ...prevFormData,
      photos: [...prevFormData.photos, convertedFile],
    }))
  };

  const deletePhoto = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      photos: prevFormData.photos.filter((_, i) => i !== index),
    }));
  };

  const SuccessModal = ({ visitorName }) => {
    return (
      <>
        <div className="page-mask-2 page-mask-animation zindex-modal">
          <div className="success-message">
            <h2 className="global-h2">{english("forms.type.success")}</h2>
            <h3 className="global-h4 gray">
              {visitorName.first_name} {visitorName.last_name}{" "}
              {english("forms.type.checked-in")}
            </h3>
            <button className="change-pwd-btn" onClick={() => onClose()}>
              {english("buttons.close")}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="prompt-cont visit-ques-cont">
      <h2 className="global-h2 light-black">
        {english("forms.type.visitor.header-in")}
      </h2>
      <h4 className="global-h4 gray bottom-space">
        {english("forms.type.vehicle.sub-header.firstForm")}
      </h4>
      <form id="form-submit" onSubmit={submit}>
        <div className="form-group">
          <label className="input-label required">
            {english("buttons.visitor")}
          </label>
          <div className="input-cont">
            <input
              type="text"
              placeholder={english(
                "forms.type.newDriver.input-headers.firstName"
              )}
              className="text-input"
              onChange={(e) => setFirst(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder={english(
                "forms.type.newDriver.input-headers.lastName"
              )}
              className="text-input"
              onChange={(e) => setLast(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english("forms.type.visitor.purpose.header")}
          </label>
          <div className="input-cont">
            <select onChange={(e) => setPurpose(e.target.value)} required>
              <option value="" selected>
                {english("forms.type.visitor.purpose.select")}
              </option>
              <option value="Visitor">{english("buttons.visitor")}</option>
              <option value="Provider">
                {english("forms.type.visitor.purpose.provider")}
              </option>
              <option value="Driver">
                {english("forms.type.visitor.purpose.driver")}
              </option>
              <option value="Mechanic">
                {english("forms.type.visitor.purpose.mechanic")}
              </option>
              <option value="Staff">
                {english("forms.type.visitor.purpose.staff")}
              </option>
              <option value="Guard">
                {english("forms.type.visitor.purpose.guard")}
              </option>
              <option value="Other">
                {english("forms.type.vehicle.placeholders.other")}
              </option>
            </select>

            {purpose === "Other" ? (
              <input
                type="text"
                className="text-input"
                placeholder={english("forms.type.visitor.purpose.other")}
                onChange={(e) => setOtherPurpose(e.target.value)}
                disabled={purpose !== "Other"}
                required
              />
            ) : purpose === "Provider" ? (
              <input
                type="text"
                className="text-input"
                placeholder={english("forms.type.visitor.purpose.provider")}
                onChange={(e) => setOtherPurpose(e.target.value)}
                disabled={purpose !== "Provider"}
                required
              />
            ) : null}
          </div>
        </div>
        <div className="driver-photo-id">
          <h2 className="edit-header">
            {english("forms.type.vehicle.sub-header.photos")}
          </h2>
          <div data-testid="profile-photo" className="visitor-photo">
            {/* Display the uploaded photos */}
            {formData.photos.map((photo, index) => (
              <div key={index} className="photo-preview">
                <div className="delete-icon" onClick={() => deletePhoto(index)}>
                  <DeleteIcon />
                </div>
                <img src={photo[0]} alt={`Uploaded ${index + 1}`} />
              </div>
            ))}
          </div>

          <div>
            {/* Hidden file input */}
            <input
              data-testid="file-input"
              className="file-input"
              type="file"
              name="photo"
              onChange={handleFileChange}
              accept="image/*, .heic"
              ref={fileInputRef}
              multiple
            />
            <button onClick={handleUploadClick} className="profile-pic-btn">
              {english("buttons.uploadPhoto")}
            </button>
          </div>
          <div onClick={() => cameraInputRef.current.click()}>
            <input
              data-testid="file-input"
              className="file-input"
              type="file"
              capture="environment"
              name="photo"
              onChange={handleFileChange}
              accept="image/*"
              ref={cameraInputRef}
            />
            <button className="profile-pic-btn">
            {english("forms.type.vehicle.input-headers.takePhoto")}
            </button>
          </div>
        </div>
        <div className="full-cont">
          {isLoading ? (
            <Dots />
          ) : (
            <button type="submit" className="change-pwd-btn">
              {english("buttons.submit")}
            </button>
          )}

          <button onClick={onClose} className="cancel-btn">
            {english("buttons.cancel")}
          </button>

          {success && <SuccessModal visitorName={data} />}
        </div>
      </form>
    </div>
  );
};
