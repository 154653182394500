import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../../CSS/imageModal.css";
import { useTranslation } from "react-i18next";
import UniversalTable from "../Tables/UniversalTable";
import instance from "../../Interceptor/api_instance";
import CheckInBtn from "../Buttons/check-in-btn";
import { AutoCheckOutVehicle } from "../Forms/CheckOut/AutoCheckOutVehicle";
import { CheckInSuccess } from "../Forms/CheckIn/CheckInSuccess";
import {
  guardInvTrailerHeaders,
  guardInvTruckHeaders,
  dispatchInvTrailerHeaders,
  dispatchInvTruckHeaders,
} from "../Tables/TableHeaders";
import { useAuth } from "../../Auth/AuthProvider";

export const InventoryView = () => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [trailerData, setTrailerData] = useState(null);
  const [truckData, setTruckData] = useState(null);
  const [trailerGuardData, setTrailerGuardData] = useState(null);
  const [truckGuardData, setTruckGuardData] = useState(null);

  const [table, setTable] = useState("trailers-table");
  const [isTrailerLoading, setIsTrailerLoading] = useState(true);
  const [isTruckLoading, setIsTruckLoading] = useState(true);
  const [checkOut, setCheckOut] = useState(false);
  const [checkOutTruckForm, setCheckOutTruckForm] = useState(false);
  const [selectedTrailer, selectTrailer] = useState({});
  const [message, setMessage] = useState(false);

  const [selectedTruck, selectTruck] = useState({});
  const { user } = useAuth();
  console.log(user);
  useEffect(() => {
    try {

      if (
        user.role === "Dispatch" ||
        user.role === "Admin" ||
        user.role === "Safety"
      ) {
        fetchTrailerData();
        fetchTruckData();
      } else {
        fetchGuardTrailerData();
        fetchGuardTruckData();
      }
    } catch (e) {
      console.log(e);
    }
  }, [user]);

  const fetchTrailerData = async () => {
    console.log("Dispatch inventory");
    setIsTrailerLoading(true);
    try {
      let driverData = null;
      driverData = await instance.get("assets/view-trailers");
      setTrailerData(driverData.data);
      setIsTrailerLoading(false);
    } catch (error) {
      setError(error);
    }
  };
  const fetchTruckData = async () => {
    setIsTruckLoading(true);
    try {
      let driverData = null;
      driverData = await instance.get("assets/view-trucks");
      setTruckData(driverData.data);
      setIsTruckLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const fetchGuardTrailerData = async () => {
    console.log("Guard inventory");
    setIsTrailerLoading(true);
    try {
      let driverData = null;
      driverData = await instance.get("assets/view-trailers");
      setTrailerGuardData(driverData.data);
      setIsTrailerLoading(false);
    } catch (error) {
      setError(error);
    }
  };
  const fetchGuardTruckData = async () => {
    setIsTruckLoading(true);

    try {
      let driverData = null;
      driverData = await instance.get("assets/view-trucks");
      setTruckGuardData(driverData.data);
      setIsTruckLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const active = async (e) => {
    const previous = document.getElementsByClassName("selected");
    for (var i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    setTable(e.target.id);
  };

  const handleForm = (e) => {
    console.log(e);
    setCheckOut(true);
    selectTrailer(e);
  };

  const handleClose = () => {
    setCheckOut(false);
    setCheckOutTruckForm(false);
  };

  const checkOutTruck = (truck) => {
    selectTruck(truck);
    setCheckOutTruckForm(true);
  };

  return (
    <>
      {checkOut ? (
        <div>
          <div className="page-mask">
            <AutoCheckOutVehicle
              onClose={handleClose}
              vehicle={selectedTrailer.trailer}
              english={t}
              carrying={true}
              vehicleType={"Trailer"}
            />
          </div>
        </div>
      ) : null}
      {checkOutTruckForm ? (
        <div>
          <div className="page-mask">
            <AutoCheckOutVehicle
              onClose={handleClose}
              vehicle={selectedTruck.truck}
              vehicleType={"Truck"}
              english={t}
              carrying={null}
            />
          </div>
        </div>
      ) : null}
      {message ? (
        <div>
          <div className="page-mask">
            <CheckInSuccess
              title="Success"
              message="The vehicle has been checked out."
              setMessage={setMessage}
            />
          </div>
        </div>
      ) : null}
      {message ? (
        <div>
          <div className="page-mask">
            <CheckInSuccess
              title="Success"
              message="The vehicle has been checked out."
              setMessage={setMessage}
            />
          </div>
        </div>
      ) : null}
      <div className="global-cont">
        <Helmet>
          <title>Forward | Inventory</title>
        </Helmet>
        <div className="page-overview">
          <div className="cont-wrapper">
            <div className="inventory-header">
              <h2 className="global-h1 light-black">
                {t("page-headers.inventory")}
              </h2>
              {user.role === "Guard" && <CheckInBtn english={t} />}
            </div>
            <ul className="yard-tabs">
              <li className="selected" onClick={active} id="trailers-table">
                {t("yard-tables.tabs.trailers")}
              </li>
              <li className="tab" onClick={active} id="trucks-table">
                {t("yard-tables.tabs.trucks")}
              </li>
            </ul>
            {user.role === "Guard" ? (
              <>
                {/* Guard inventory */}
                {table === "trailers-table" && (
                  <UniversalTable
                    english={t}
                    data={trailerGuardData}
                    headers={guardInvTrailerHeaders(user)}
                    filters={[
                      "Location",
                      "Customer",
                      "Contents",
                      "Reservation",
                    ]}
                    type="trailer"
                    handleForm={handleForm}
                    error={error}
                    pageLoading={isTrailerLoading}
                    user={user}
                  />
                )}
                {table === "trucks-table" && (
                  <UniversalTable
                    english={t}
                    data={truckGuardData}
                    headers={guardInvTruckHeaders(user)}
                    filters={["Location", "Driver"]}
                    type="truck"
                    handleForm={checkOutTruck}
                    error={error}
                    pageLoading={isTruckLoading}
                    user={user}
                  />
                )}
              </>
            ) : (
              <>
                {/* Dispatch inventory */}
                {table === "trailers-table" && (
                  <UniversalTable
                    english={t}
                    data={trailerData}
                    headers={dispatchInvTrailerHeaders(user)}
                    user={user}
                    filters={[
                      "Location",
                      "Customer",
                      "Contents",
                      "Reservation",
                    ]}
                    type="trailer"
                    error={error}
                    pageLoading={isTrailerLoading}
                  />
                )}
                {table === "trucks-table" && (
                  <UniversalTable
                    english={t}
                    data={truckData}
                    headers={dispatchInvTruckHeaders(user)}
                    filters={["Location", "Driver"]}
                    type="truck"
                    error={error}
                    pageLoading={isTruckLoading}
                    user={user}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
