import { useAuth } from "../Auth/AuthProvider";
import instance from "../Interceptor/api_instance";
import { useEffect } from "react";

export const Logout = () => {

  const {logout} = useAuth();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        const data = await instance.post('api/logout/');
        if (data.status === 200) {
          logout();
          localStorage.clear();
          window.location.href = "/login";
        }

      } catch (e) {
        localStorage.clear();
        window.location.href='/login';
      }
    }
    logoutUser();
  }, [])
  
  return (
    <></>
  )
}