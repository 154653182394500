import instance from "../Interceptor/api_instance";
import { useEffect, useState } from "react";
import "../CSS/login.css";
import "../CSS/global.css";
import { Logo } from "../Media/logos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../Auth/AuthProvider";

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [locked, setLocked] = useState(false);
  const [error, setError] = useState(false);
  const { login } = useAuth(); // Destructure the login function from useAuth
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    // const { user } = useAuth; // Destructure to get user from useAuth
    if (user) {
      navigate("/"); // Redirect authenticated users
    }
  }, [navigate]); // Add necessary dependencies
  

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data, status } = await instance.post("/api/login/", { username, password });
      if (status === 200) {
        login(data); // Use the login function from AuthProvider
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      setLoginError(true);
      setError(true); // Simplified error handling for demonstration
      if (error.includes("429")) {
        setLoginError(false);
        setLocked(true);
      }
      console.error("Login error:", error);
    }
  };

  return (
    <div className="cont">
      <div className="left-cont">
        <div className="center-img">
          <img className="login-img" src={"LoginScreenShot.png"} alt="Dashboard" />
          <p>
            A simplified gateway to effortless visibility and asset management.
          </p>
        </div>
      </div>
      <div className="right-cont">
        <div className="login-cont">
          <div className="logo-cont bottom-space">
            <Logo fillColor="#557AFF" />
            <h4>Forward</h4>
          </div>
          <h1 className="bottom-space">Log in to your account</h1>
          <p className="bottom-space">Welcome! Please enter your details.</p>
          <form id="login-form" onSubmit={submit}>
            <div className="auth-group">
              <FontAwesomeIcon icon="fa-solid fa-envelope" />
              <input
                type="text"
                placeholder="Enter username"
                onChange={(e) => setUsername(e.target.value)}
                id="username"
                required
              />
            </div>
            <div className="auth-group bottom-space">
              <FontAwesomeIcon icon="fa-solid fa-lock" />
              <input
                type="password"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                required
              />
            </div>
            <div className="link-cont bottom-space">
              <a href="/forgot-password">Forgot your password?</a>
            </div>

            {loading ? (
              <div className="loading-cont">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <button className="bottom-space change-pwd-btn" id="login-button" type="submit">
                Submit
              </button>
            )}
            {loginError ? (
              <div className="login-error">
                <p>Please enter a correct username and password.</p>
              </div>
            ) : null}
            {locked ? (
              <div className="login-error">
                <p>Too many requests have been made.</p>
                <p>Please try again later or contact your IT team.</p>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};
