export const Logo = ({fillColor}) => {
    return (
        <svg width="39" height="32" viewBox="0 0 39 32" fill="none" className="logo" xmlns="http://www.w3.org/2000/svg">
            <g id="forward logo 3">
                <g id="Group">
                    <path id="Vector" d="M18.8018 17.8036C18.7436 17.8618 18.6855 17.9491 18.5982 18.0073L18.4818 18.1236L6.87455 29.7309C6.32182 30.2836 5.59455 30.5745 4.86727 30.5745C4.14 30.5745 3.41273 30.2836 2.86 29.7309C1.75455 28.6255 1.75455 26.8218 2.86 25.7164L12.0236 16.5527C12.3145 16.2618 12.3145 15.7673 12.0236 15.4764L2.88909 6.28364C1.75455 5.17818 1.75455 3.40363 2.88909 2.29818C3.99455 1.19273 5.79818 1.19273 6.90364 2.29818L18.5691 13.9636C18.5982 13.9927 18.5982 13.9927 18.6273 14.0218C18.6855 14.08 18.7727 14.1673 18.8309 14.2255C19.6455 15.2436 19.6455 16.7564 18.8018 17.8036Z" fill={fillColor} />
                    <path id="Vector_2" d="M36.3145 17.8036C36.2564 17.8618 36.1982 17.9491 36.1109 18.0073L35.9945 18.1236L24.3873 29.7309C23.8636 30.2545 23.1364 30.5455 22.4091 30.5455C21.6818 30.5455 20.9545 30.2545 20.4018 29.7018C19.2964 28.5964 19.2964 26.7927 20.4018 25.6873L29.5655 16.5236C29.8564 16.2327 29.8564 15.7382 29.5655 15.4473L20.4018 6.28363C19.2964 5.17818 19.2964 3.37454 20.4018 2.26909C21.5073 1.16363 23.3109 1.16363 24.4164 2.26909L36.0818 13.9345C36.1109 13.9636 36.1109 13.9636 36.14 13.9927C36.1982 14.0509 36.2855 14.1382 36.3436 14.1964C37.1582 15.2436 37.1582 16.7564 36.3145 17.8036Z" fill={fillColor} />
                </g>
            </g>
        </svg>
    )
}

export const Ati = () => {

    return(
        <svg
                      xmlns="http://www.w3.org/2000/svg"
            
                      viewBox="0 0 188 54"
                      fill="none"
                    >
                      <path
                        d="M0.773315 28.9067C0.773315 39.2533 8.98665 48.2133 21.36 53.3333C11.5466 48.32 5.14665 40.4267 5.14665 31.5733C5.14665 16.4267 23.8133 4.16 46.8533 4.16C69.1466 4.16 87.3866 15.68 88.4533 30.2933C88.4533 29.8667 88.4533 29.3333 88.4533 28.9067C88.56 12.9067 68.9333 0 44.72 0C20.5066 0 0.773315 12.9067 0.773315 28.9067Z"
                        fill="#AC2126"
                      />
                      <mask
                        id="mask0_137_253"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="89"
                        height="54"
                      >
                        <path
                          d="M0.773315 28.9067C0.773315 39.2533 8.98665 48.2133 21.36 53.3333C11.5466 48.32 5.14665 40.4267 5.14665 31.5733C5.14665 16.4267 23.8133 4.16 46.8533 4.16C69.1466 4.16 87.3866 15.68 88.4533 30.2933C88.4533 29.8667 88.4533 29.3333 88.4533 28.9067C88.56 12.9067 68.9333 0 44.72 0C20.5066 0 0.773315 12.9067 0.773315 28.9067Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_137_253)">
                        <path
                          d="M88.4533 0H0.773315V53.3333H88.4533V0Z"
                          fill="#AC2126"
                        />
                      </g>
                      <path
                        d="M77.1466 23.36V34.7733C77.1466 36.48 75.76 37.44 74.16 37.44H69.7866V23.36H77.1466Z"
                        fill="#050505"
                      />
                      <path
                        d="M76.9333 18.3467V22.1867H69.4667V15.04H73.52C75.44 15.04 76.9333 16.5333 76.9333 18.3467Z"
                        fill="#AC2126"
                      />
                      <path
                        d="M59.76 21.76H52.4V37.3333H59.76V21.76Z"
                        fill="#050505"
                      />
                      <path
                        d="M67.9733 22.2933H47.0667C45.2533 22.2933 43.76 20.8 43.76 18.9867V15.04H67.9733V22.2933Z"
                        fill="#050505"
                      />
                      <path
                        d="M21.4667 37.8667C21.68 37.8667 33.0933 37.8667 33.0933 37.8667V26.3467L21.4667 37.8667Z"
                        fill="#AC2126"
                      />
                      <path
                        d="M33.52 15.04C32.4533 15.1467 31.1733 15.68 30.4267 16.4267C25.4133 21.3333 20.4 26.4533 15.3867 31.4667C13.36 33.4933 11.3333 35.52 8.88 37.9733H19.3333C24.1333 33.28 29.5733 27.84 34.5867 22.72V37.9733H41.9467V15.04C38.8533 15.04 36.2933 14.9333 33.52 15.04Z"
                        fill="#050505"
                      />
                      <path
                        d="M84.1866 36.16C84.1866 37.3333 83.3333 38.1867 82.16 38.1867C80.9866 38.1867 80.1333 37.3333 80.1333 36.16C80.1333 34.9867 81.0933 34.1333 82.2666 34.1333C83.3333 34.1333 84.1866 34.9867 84.1866 36.16ZM80.6666 36.16C80.6666 37.0133 81.3066 37.76 82.2666 37.76C83.12 37.76 83.76 37.0133 83.76 36.16C83.76 35.3067 83.12 34.56 82.16 34.56C81.3066 34.56 80.6666 35.2 80.6666 36.16ZM81.84 37.12H81.4133V35.2C81.6266 35.2 81.84 35.0933 82.16 35.0933C82.5866 35.0933 82.6933 35.2 82.8 35.2C82.9066 35.3067 83.0133 35.4133 83.0133 35.6267C83.0133 35.84 82.8 36.0533 82.5866 36.0533C82.8 36.16 82.9066 36.2667 82.9066 36.5867C83.0133 36.9067 83.0133 37.0133 83.0133 37.12H82.48C82.3733 37.0133 82.3733 36.9067 82.3733 36.5867C82.3733 36.3733 82.2666 36.2667 81.9466 36.2667H81.7333V37.12H81.84ZM81.84 36.0533H82.0533C82.2666 36.0533 82.48 35.9467 82.48 35.7333C82.48 35.52 82.3733 35.4133 82.0533 35.4133C81.9466 35.4133 81.84 35.4133 81.84 35.4133V36.0533Z"
                        fill="black"
                      />
                      <path
                        d="M101.36 21.76H97.9466L97.3066 23.68H95.4933L98.8 14.9333H100.507L103.813 23.68H102L101.36 21.76ZM100.827 20.2667L99.6533 16.8533L98.4799 20.2667H100.827Z"
                        fill="#050505"
                      />
                      <path
                        d="M109.573 19.0933H111.173V22.6133C110.747 22.9333 110.32 23.2533 109.787 23.4667C109.253 23.68 108.72 23.7867 108.187 23.7867C107.44 23.7867 106.693 23.5733 106.053 23.2533C105.413 22.8267 104.987 22.2933 104.56 21.6533C104.24 21.0133 104.027 20.16 104.027 19.3067C104.027 18.4533 104.24 17.7067 104.56 16.96C104.88 16.32 105.413 15.68 106.053 15.36C106.693 14.9333 107.333 14.8267 108.187 14.8267C108.827 14.8267 109.467 14.9333 110 15.2533C110.533 15.4667 110.96 15.8933 111.387 16.4267L110.32 17.6C109.787 16.96 109.147 16.64 108.4 16.64C107.973 16.64 107.547 16.7467 107.12 16.96C106.8 17.1733 106.48 17.4933 106.267 17.92C106.053 18.3467 105.947 18.88 105.947 19.4133C105.947 19.9467 106.053 20.48 106.267 20.9067C106.48 21.3333 106.8 21.6533 107.12 21.8667C107.44 22.08 107.867 22.1867 108.4 22.1867C108.933 22.1867 109.36 22.08 109.787 21.76V19.0933H109.573Z"
                        fill="#050505"
                      />
                      <path
                        d="M117.467 23.68L116.08 21.2267H115.973H114.48V23.68H112.773V14.9333H115.973C116.613 14.9333 117.253 15.04 117.68 15.36C118.213 15.5733 118.533 16 118.853 16.4267C119.067 16.8533 119.28 17.4933 119.28 18.1333C119.28 18.7733 119.173 19.3067 118.853 19.84C118.64 20.2667 118.213 20.6933 117.68 20.9067L119.387 23.68H117.467ZM117.04 16.96C116.72 16.7467 116.4 16.5333 115.867 16.5333H114.48V19.6267H115.867C116.4 19.6267 116.827 19.52 117.04 19.2C117.36 18.88 117.467 18.56 117.467 18.0267C117.467 17.6 117.36 17.1733 117.04 16.96Z"
                        fill="#050505"
                      />
                      <path
                        d="M125.467 21.76H122.053L121.413 23.68H119.6L122.907 14.9333H124.613L127.92 23.68H126.107L125.467 21.76ZM124.933 20.2667L123.76 16.8533L122.587 20.2667H124.933Z"
                        fill="#050505"
                      />
                      <path
                        d="M135.6 23.68V18.4533L133.36 22.8267H132.613L130.48 18.56V23.68H128.88V14.9333H130.267L133.04 20.3733L135.707 14.9333H137.093V23.68H135.6Z"
                        fill="#050505"
                      />
                      <path
                        d="M140.507 23.2533C139.867 22.8267 139.44 22.2933 139.013 21.6533C138.693 21.0133 138.48 20.16 138.48 19.3067C138.48 18.4533 138.693 17.7067 139.013 16.96C139.333 16.32 139.867 15.68 140.507 15.36C141.147 14.9333 141.787 14.72 142.64 14.72C143.387 14.72 144.133 14.9333 144.773 15.36C145.413 15.7867 145.84 16.32 146.267 16.96C146.587 17.6 146.8 18.4533 146.8 19.3067C146.8 20.16 146.587 20.9067 146.267 21.6533C145.947 22.2933 145.413 22.9333 144.773 23.2533C144.133 23.68 143.493 23.8933 142.64 23.8933C141.787 23.7867 141.04 23.5733 140.507 23.2533ZM143.707 21.76C144.027 21.5467 144.347 21.2267 144.56 20.8C144.773 20.3733 144.88 19.84 144.88 19.3067C144.88 18.7733 144.773 18.24 144.56 17.8133C144.347 17.3867 144.027 17.0667 143.707 16.8533C143.387 16.64 142.96 16.5333 142.533 16.5333C142.107 16.5333 141.68 16.64 141.36 16.8533C141.04 17.0667 140.72 17.3867 140.507 17.8133C140.293 18.24 140.187 18.7733 140.187 19.3067C140.187 19.84 140.293 20.3733 140.507 20.8C140.72 21.2267 141.04 21.5467 141.36 21.76C141.68 21.9733 142.107 22.08 142.533 22.08C142.96 22.08 143.387 21.9733 143.707 21.76Z"
                        fill="#050505"
                      />
                      <path
                        d="M154.8 14.9333V23.68H153.413L149.68 18.3467V23.68H147.973V14.9333H149.36L153.093 20.2667V14.9333H154.8Z"
                        fill="#050505"
                      />
                      <path
                        d="M158.107 16.5333H155.76V14.9333H162.267V16.64H159.92V23.7867H158.107V16.5333Z"
                        fill="#050505"
                      />
                      <path
                        d="M98.0533 29.3333H95.7067V27.6267H102.213V29.3333H99.8667V36.48H98.16V29.3333H98.0533Z"
                        fill="#050505"
                      />
                      <path
                        d="M107.867 36.48L106.48 34.0267H106.373H104.773V36.48H103.067V27.7333H106.267C106.907 27.7333 107.547 27.84 107.973 28.16C108.507 28.3733 108.827 28.8 109.147 29.2267C109.36 29.6533 109.573 30.2933 109.573 30.9333C109.573 31.5733 109.467 32.1067 109.147 32.64C108.933 33.0667 108.507 33.4933 107.973 33.7067L109.68 36.48H107.867ZM107.333 29.76C107.013 29.5467 106.693 29.3333 106.16 29.3333H104.773V32.4267H106.16C106.693 32.4267 107.12 32.32 107.333 32C107.653 31.68 107.76 31.36 107.76 30.8267C107.76 30.4 107.653 29.9733 107.333 29.76Z"
                        fill="#050505"
                      />
                      <path
                        d="M115.867 34.56H112.453L111.813 36.48H110L113.307 27.7333H115.013L118.32 36.48H116.507L115.867 34.56ZM115.227 33.0667L114.053 29.6533L112.88 33.0667H115.227Z"
                        fill="#050505"
                      />
                      <path
                        d="M125.893 27.6267V36.3733H124.507L120.773 31.04V36.3733H119.067V27.6267H120.453L124.187 32.96V27.6267H125.893Z"
                        fill="#050505"
                      />
                      <path
                        d="M128.453 36.2667C127.92 36.0533 127.493 35.84 127.173 35.52L127.707 34.0267C128.027 34.3467 128.453 34.56 128.88 34.6667C129.307 34.88 129.733 34.88 130.16 34.88C130.693 34.88 131.013 34.7733 131.227 34.6667C131.44 34.4533 131.547 34.24 131.547 34.0267C131.547 33.8133 131.44 33.6 131.333 33.4933C131.227 33.3867 131.013 33.28 130.8 33.1733C130.587 33.0667 130.267 32.96 129.947 32.8533C129.413 32.7467 128.88 32.5333 128.56 32.4267C128.24 32.32 127.92 32 127.6 31.68C127.387 31.36 127.173 30.8267 127.173 30.2933C127.173 29.76 127.28 29.3333 127.493 28.9067C127.707 28.48 128.133 28.16 128.56 27.9467C128.987 27.7333 129.627 27.6267 130.267 27.6267C130.693 27.6267 131.227 27.7333 131.653 27.84C132.08 27.9467 132.507 28.16 132.827 28.3733L132.293 29.8667C131.653 29.44 130.907 29.2267 130.267 29.2267C129.733 29.2267 129.413 29.3333 129.2 29.5467C128.987 29.76 128.88 29.9733 128.88 30.2933C128.88 30.6133 128.987 30.8267 129.307 30.9333C129.52 31.04 129.947 31.2533 130.48 31.36C131.013 31.4667 131.547 31.68 131.867 31.7867C132.187 31.8933 132.507 32.2133 132.827 32.5333C133.04 32.8533 133.253 33.3867 133.253 33.92C133.253 34.4533 133.147 34.88 132.933 35.3067C132.72 35.7333 132.293 36.0533 131.867 36.2667C131.44 36.48 130.8 36.5867 130.16 36.5867C129.52 36.5867 128.987 36.48 128.453 36.2667Z"
                        fill="#050505"
                      />
                      <path
                        d="M139.44 28.0533C139.973 28.2667 140.293 28.6933 140.613 29.12C140.827 29.5467 141.04 30.1867 141.04 30.8267C141.04 31.4667 140.933 32 140.613 32.5333C140.4 33.0667 139.973 33.3867 139.44 33.6C138.907 33.8133 138.373 34.0267 137.733 34.0267H136.24V36.48H134.533V27.7333H137.733C138.373 27.6267 138.907 27.84 139.44 28.0533ZM138.8 32C139.12 31.7867 139.227 31.36 139.227 30.8267C139.227 30.2933 139.12 29.9733 138.8 29.6533C138.48 29.44 138.16 29.2267 137.627 29.2267H136.24V32.32H137.627C138.16 32.32 138.587 32.2133 138.8 32Z"
                        fill="#050505"
                      />
                      <path
                        d="M143.813 36.0533C143.173 35.6267 142.747 35.0933 142.32 34.4533C142 33.8133 141.787 32.96 141.787 32.1067C141.787 31.2533 142 30.5067 142.32 29.76C142.64 29.12 143.173 28.48 143.813 28.16C144.453 27.7333 145.093 27.52 145.947 27.52C146.693 27.52 147.44 27.7333 148.08 28.16C148.72 28.5867 149.147 29.12 149.573 29.76C149.893 30.4 150.107 31.2533 150.107 32.1067C150.107 32.96 149.893 33.7067 149.573 34.4533C149.253 35.0933 148.72 35.7333 148.08 36.0533C147.44 36.48 146.8 36.6933 145.947 36.6933C145.093 36.5867 144.453 36.3733 143.813 36.0533ZM147.12 34.56C147.44 34.3467 147.76 34.0267 147.973 33.6C148.187 33.1733 148.293 32.64 148.293 32.1067C148.293 31.5733 148.187 31.04 147.973 30.6133C147.76 30.1867 147.44 29.8667 147.12 29.6533C146.8 29.44 146.373 29.3333 145.947 29.3333C145.52 29.3333 145.093 29.44 144.773 29.6533C144.453 29.8667 144.133 30.1867 143.92 30.6133C143.707 31.04 143.6 31.5733 143.6 32.1067C143.6 32.64 143.707 33.1733 143.92 33.6C144.133 34.0267 144.453 34.3467 144.773 34.56C145.093 34.7733 145.52 34.88 145.947 34.88C146.373 34.88 146.693 34.7733 147.12 34.56Z"
                        fill="#050505"
                      />
                      <path
                        d="M156.08 36.48L154.693 34.0267H154.587H153.093V36.48H151.387V27.7333H154.587C155.227 27.7333 155.867 27.84 156.293 28.16C156.827 28.3733 157.147 28.8 157.467 29.2267C157.68 29.6533 157.893 30.2933 157.893 30.9333C157.893 31.5733 157.787 32.1067 157.467 32.64C157.253 33.0667 156.827 33.4933 156.293 33.7067L158 36.48H156.08ZM155.653 29.76C155.333 29.5467 155.013 29.3333 154.48 29.3333H153.093V32.4267H154.48C155.013 32.4267 155.44 32.32 155.653 32C155.973 31.68 156.08 31.36 156.08 30.8267C155.973 30.4 155.867 29.9733 155.653 29.76Z"
                        fill="#050505"
                      />
                      <path
                        d="M160.56 29.3333H158.213V27.6267H164.72V29.3333H162.373V36.48H160.667V29.3333H160.56Z"
                        fill="#050505"
                      />
                      <path
                        d="M168.667 27.6267H170.373V36.3733H168.667V27.6267Z"
                        fill="#050505"
                      />
                      <path
                        d="M179.013 27.6267V36.3733H177.627L173.893 31.04V36.3733H172.187V27.6267H173.573L177.307 32.96V27.6267H179.013Z"
                        fill="#050505"
                      />
                      <path
                        d="M182.213 36.0533C181.573 35.6267 181.147 35.0933 180.72 34.4533C180.4 33.8133 180.187 32.96 180.187 32.1067C180.187 31.2533 180.4 30.5067 180.72 29.76C181.04 29.12 181.573 28.48 182.213 28.16C182.853 27.7333 183.493 27.6267 184.24 27.6267C184.88 27.6267 185.52 27.7333 185.947 28.0533C186.48 28.3733 186.907 28.6933 187.227 29.2267L186.16 30.4C185.627 29.76 184.987 29.3333 184.24 29.3333C183.813 29.3333 183.387 29.44 182.96 29.6533C182.64 29.8667 182.32 30.1867 182.107 30.6133C181.893 31.04 181.787 31.5733 181.787 32.1067C181.787 32.64 181.893 33.1733 182.107 33.6C182.32 34.0267 182.64 34.3467 182.96 34.56C183.28 34.7733 183.707 34.88 184.24 34.88C184.987 34.88 185.627 34.56 186.16 33.8133L187.227 34.9867C186.907 35.52 186.48 35.84 185.947 36.16C185.413 36.48 184.88 36.5867 184.24 36.5867C183.6 36.5867 182.853 36.3733 182.213 36.0533Z"
                        fill="#050505"
                      />
                    </svg>
    )
}