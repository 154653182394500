import "./App.css";
import "./CSS/global.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GuardOverview } from "./Components/Routes/GuardOverview";
import { Login } from "./Components/Login";
import { Logout } from "./Components/Logout";
import {
  faChevronDown,
  faEnvelope,
  faLock,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { DispatchOverview } from "./Components/Routes/DispatchOverview";
import { HomeRedirect } from "./Components/Routes/HomeRedirect";
import { InventoryView } from "./Components/Routes/InventoryView";
import { Reservations } from "./Components/Routes/Reservations";
import { Settings } from "./Components/Routes/Settings";
import { CheckOut } from "./Components/Routes/CheckOut";
import { ForgotPassword } from "./Components/Routes/ForgotPassword";
import { ErrorPage } from "./Components/Routes/ErrorPage";
import { LanguageProvider } from "./Media/LanguageContext";
import { Reports } from "./Components/Routes/Reports";
import DrivingTeam from "./Components/Routes/DrivingTeam";
import NewDriver from "./Components/Forms/Driver/NewDriver";
import { ScannedId, IdTemplate } from "./Components/Routes/ScannedId";
import Unauthorized from "./Components/Routes/Unauthorized";
import UniversalTable from "./Components/Tables/UniversalTable";
import BackLog from "./Components/Routes/BackLog";
import DriverReports from "./Components/Routes/DriversReports";

import { AuthProvider } from "./Auth/AuthProvider";
import { ProtectedRoute } from "./Auth/RouteProtector";
import { Navigation } from "./Components/Navigation/Navigation";
import { CheckIn } from "./Components/Routes/CheckIn";
import { SideBar2 } from "./Components/Navigation/Sidebar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Suspense } from "react";
import HelpContainer from "./Components/HelpContainer";

library.add(faChevronDown, faEnvelope, faLock, faMagnifyingGlass);

// Help page with gifs

// KNOWN ISSUES
// Check if truck is checked in
// filtering by carrier on driving team tabel => TypeError: Cannot read properties of undefined (reading 'carrier_name')

// add QR codes to trucks and trailers to auto fill the data in the check in and check out form

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <LanguageProvider>
          <div className="App">
            {/* <HelpContainer /> */}
            <AuthProvider>
              <Navigation></Navigation>
              <SideBar2></SideBar2>
              <Routes>
                {/* unprotected routes */}
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<ErrorPage />} />
                <Route path="/" element={<HomeRedirect />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/scanned-id/:carrier/:id"
                  element={<ScannedId />}
                />
                <Route path="/id-template" element={<IdTemplate />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/universal-table" element={<UniversalTable />} />
                <Route path="/settings" element={<Settings />} />

                {/* Guard Routes */}
                <Route
                  element={<ProtectedRoute allowedRoles={["Admin", "Guard", "Guard Supervisor", "Safety"]} />}
                >
                  <Route path="/check-in-trailer" element={<CheckIn />} />
                  <Route path="/check-out" element={<CheckOut />} />
                </Route>

                {/* Shared inventory Guard and Dispatch */}
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={["Admin", "Guard", "Guard Supervisor", "Dispatch", "Safety"]}
                    />
                  }
                >
                  <Route path="/inventory-view" element={<InventoryView />} />
                </Route>

                {/* Shared inventory Dispatch and Safety */}

                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={["Admin", "Dispatch", "Guard Supervisor", "Safety"]}
                    />
                  }
                >
                  <Route path="/backlog" element={<BackLog />} />
                </Route>

                {/* Shared inventory Guard and Safety */}

                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={["Admin", "Guard", "Guard Supervisor", "Safety"]}
                    />
                  }
                >
                  <Route path="/guard-overview" element={<GuardOverview />} />
                </Route>

                {/* Dispatch Routes */}
                <Route
                  element={
                    <ProtectedRoute allowedRoles={["Admin", "Dispatch"]} />
                  }
                >
                  <Route
                    path="/dispatch-overview"
                    element={<DispatchOverview />}
                  />
                  <Route path="/reservations" element={<Reservations />} />
                </Route>

                {/* Safety Routes */}
                <Route
                  element={
                    <ProtectedRoute allowedRoles={["Admin", "Safety"]} />
                  }
                >
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/new-driver" element={<NewDriver />} />
                  <Route path="/driving-team" element={<DrivingTeam />} />
                </Route>

                {/* Admin Routes */}
                <Route element={<ProtectedRoute allowedRoles={["Admin"]} />}>
                  <Route path="/driver-reports" element={<DriverReports />} />
                </Route>
              </Routes>
            </AuthProvider>
          </div>
        </LanguageProvider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
