import FormExport from "../Utility/FormExport";

export const Reports = () => {
  return (
    <>
      <FormExport
        type="Check"
        pdfFile="F15-INSPECCION DE IITEDITADO.pdf"
        endPoint="safety/ctpat-reports"
      />
    </>
  );
};
