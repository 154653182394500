import React from "react";

/**
 *
 * @prop {int} page - current page
 * @prop {int} props.totalPages - total pages
 * @prop {function} props.handlePreviousPage - go to previous page
 * @prop {function} props.handleNextPage - go to next page
 * @prop {function} props.handlePageChange - updates ui when user navigates pages
 */

/**
 * The PageNavigator function in JavaScript handles pagination by displaying page numbers and allowing
 * navigation between pages.
 * @returns The `PageNavigator` component is being returned. It consists of navigation elements for
 * pagination including previous and next buttons, page numbers, and ellipsis for indicating more
 * pages. The component also includes logic to determine which page numbers to display based on the
 * current page and total number of pages.
 */
export default function PageNavigator({
  page,
  totalPages,
  handlePreviousPage,
  handleNextPage,
  handlePageChange,
}) {
  // Function to determine which page numbers to show
  const getPageNumbers = (currentPage, totalPages) => {
    const pages = [];
    const maxPagesToShow = 3; // Maximum number of pages to display at a time
    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
      // If the total number of pages is less than or equal to maxPagesToShow, show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // Calculate startPage and endPage
      if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
        // If the current page is among the first few pages
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + Math.floor(maxPagesToShow / 2) >= totalPages) {
        // If the current page is among the last few pages
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        // Otherwise, center the current page
        startPage = currentPage - Math.floor(maxPagesToShow / 2);
        endPage = currentPage + Math.floor(maxPagesToShow / 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <>
      {totalPages >= 4 ? (
        <div className="page-select">
          {page > 1 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={handlePreviousPage}
            >
              <path
                d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                fill="#5656B9"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                fill="#B9B9D7"
              />
            </svg>
          )}

          <div className="page-select-number-cont">
            {page > totalPages - 2 && (
              <>
                <span
                  className="page-select-number"
                  onClick={() => handlePageChange(1)}
                >
                  1
                </span>
                <span className="page-select-number-dots">. . .</span>
              </>
            )}

            {getPageNumbers(page, totalPages).map((num) => (
              <span
                key={num}
                className={`page-select-number ${num === page ? "active" : ""}`}
                onClick={() => handlePageChange(num)}
              >
                {num}
              </span>
            ))}

            {page < totalPages - 1 && (
              <>
                <span className="page-select-number-dots">. . .</span>
                <span
                  className="page-select-number"
                  onClick={() => handlePageChange(totalPages)}
                >
                  {totalPages}
                </span>
              </>
            )}
          </div>

          {page === totalPages ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                fill="#B9B9D7"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={handleNextPage}
            >
              <path
                d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                fill="#5656B9"
              />
            </svg>
          )}
        </div>
      ) : (
        <div className="page-select">
          {page > 1 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={handlePreviousPage}
            >
              <path
                d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                fill="#5656B9"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                fill="#B9B9D7"
              />
            </svg>
          )}

          <div className="page-select-number-cont">
            {getPageNumbers(page, totalPages).map((num) => (
              <span
                key={num}
                className={`page-select-number ${num === page ? "active" : ""}`}
                onClick={() => handlePageChange(num)}
              >
                {num}
              </span>
            ))}
          </div>

          {page === totalPages ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                fill="#B9B9D7"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={handleNextPage}
            >
              <path
                d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                fill="#5656B9"
              />
            </svg>
          )}
        </div>
      )}
    </>
  );
}
