import { useState, useEffect } from "react";
import instance from "../../../Interceptor/api_instance";
import Select from "react-select";

const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const mexicanStates = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Coahuila",
  "Colima",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "México",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];

export const AutoCheckOutTrailer = ({
  handleTruckChange,
  trucks,
  truck,
  trailer,
  handleVisitorChange,
  visitor,
  visitorList,
  handleVehicleData,
  setTrailer,
  setTruck,
  onClose,
  english,
  nextForm,
  dimensionOption,
  setDimensionOption,
  customHeight,
  setCustomHeight,
  customWidth,
  setCustomWidth,
  customLength,
  setCustomLength,
  carrying,
  setCarrying,
  vehicleType,
  trailerList,
  handleTrailerChange,
  lockTruck,
}) => {
  const [allCustomers, setCustomerList] = useState([]);

  const handleCustomer = (name) => {
    setCustomerList([]);
    handleVehicleData(setTrailer, "customer", name);
  };

  const queryCustomers = async (e) => {
    handleVehicleData(setTrailer, "", e.target.value);
    try {
      if (e.target.value !== "") {
        const response = await instance.get(
          "assets/customers/" + e.target.value + "/"
        );
        setCustomerList(response.data);
      } else {
        handleVehicleData(setTrailer, "", e.target.value);
        setCustomerList([]);
      }
    } catch (e) {
      window.alert(e);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px 16px",
      border: "none",
      width: "100%", // Make sure control is full width
      borderRadius: "16px",
      background: "var(--neutral-light-purple, #f4f4ff)",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      color: "#5656b9",
      minHeight: "auto", // Override to handle padding
    }),
    // You might want to adjust the width of the menu to match the control
    container: (provided) => ({
      ...provided,
      width: "100%", // Ensure the dropdown menu is also full width
      color: "#5656b9",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5656b9",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F4FF",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      borderRadius: "24px",
      padding: "16px",
      marginTop: "10px",
    }),
  };

  const truckCheckoutOptions = trucks?.map((truck) => ({
    value: truck.truck.id,
    label: `${truck.truck.carrier_name}-${truck.truck.truck_number}`,
  }));

  const driverCheckoutOptions = visitorList?.map((visitor) => ({
    value: visitor.visitor.id,
    label: `${visitor.visitor.first_name} ${visitor.visitor.last_name}`,
  }));

  return (
    <div className="questions-cont lg-cont">
      <h2 className="global-h2 light-black">
        {english("forms.type.vehicle.check-out.header")}
      </h2>
      <h4 className="global-h4 gray bottom-space">
        {english("forms.type.vehicle.sub-header.firstForm")}
      </h4>
      <div id="form-submit">
        <form className="form-check-in" onSubmit={nextForm} id="form-id">
          {vehicleType === "Truck" ? (
            <div className="form-group">
              <label className="input-label required">
                {english("forms.type.vehicle.input-headers.carrying-bool")}
              </label>
              <div className="input-cont">
                <fieldset className="fieldsets">
                  <div className="radio-options">
                    <input
                      type="radio"
                      name="hauling"
                      className="radio-input"
                      checked={carrying}
                      onClick={() => setCarrying(true)}
                      required
                    />{" "}
                    <label>{english("general.yes")}</label>
                  </div>
                  <div className="radio-options">
                    <input
                      type="radio"
                      name="hauling"
                      className="radio-input"
                      checked={carrying === false}
                      onClick={() => setCarrying(false)}
                    />{" "}
                    <label>No</label>
                  </div>
                </fieldset>
              </div>
            </div>
          ) : null}
          {carrying ? (
            <div>
              <div className="form-group">
                <label className="input-label required">
                  {english("forms.type.vehicle.input-headers.trailer-number")}
                </label>
                {vehicleType === "Trailer" ? (
                  <div className="input-cont">
                    <input
                      type="text"
                      className="text-input"
                      value={trailer.trailer_number}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="input-cont">
                    <select onChange={(e) => handleTrailerChange(e)} required>
                      <option selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.select-trailer"
                        )}
                      </option>
                      {trailerList.length > 0
                        ? trailerList.map((trailer) => (
                            <option value={trailer.trailer.id}>
                              {trailer.trailer.trailer_number}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="input-label required">
                  {english("forms.type.vehicle.input-headers.trailer-dim")}
                </label>
                <div className="input-cont">
                  <fieldset className="fieldsets">
                    <div className="radio-options">
                      <input
                        type="radio"
                        name="dimension"
                        className="radio-input"
                        checked={dimensionOption === 1}
                        onClick={() => setDimensionOption(1)}
                        required
                      />
                      <label>53'</label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        name="dimension"
                        className="radio-input"
                        checked={dimensionOption === 2}
                        onClick={() => setDimensionOption(2)}
                      />
                      <label>48'</label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        name="dimension"
                        className="radio-input"
                        checked={dimensionOption === 3}
                        onClick={() => setDimensionOption(3)}
                      />
                      <label>
                        {english("forms.type.vehicle.placeholders.other")}
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
              {dimensionOption === 3 ? (
                <div className="form-group">
                  <label className="input-label required">
                    {english(
                      "forms.type.vehicle.input-headers.other-dimensions"
                    )}
                  </label>
                  <div className="input-cont">
                    <input
                      type="number"
                      step="0.01"
                      min={0}
                      className="text-input"
                      value={customLength}
                      onChange={(e) => setCustomLength(e.target.value)}
                      placeholder={english(
                        "forms.type.vehicle.placeholders.length"
                      )}
                    />
                    <input
                      type="number"
                      step="0.01"
                      min={0}
                      className="text-input"
                      value={customWidth}
                      onChange={(e) => setCustomWidth(e.target.value)}
                      placeholder={english(
                        "forms.type.vehicle.placeholders.width"
                      )}
                    />
                    <input
                      type="number"
                      step="0.01"
                      min={0}
                      className="text-input"
                      value={customHeight}
                      onChange={(e) => setCustomHeight(e.target.value)}
                      placeholder={english(
                        "forms.type.vehicle.placeholders.height"
                      )}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="form-group">
            <label className="input-label required">
              {english("forms.type.vehicle.input-headers.truck-number")}
            </label>
            {vehicleType === "Trailer" ? (
              <div className="w-100">
                <div className="input-cont">
                  <Select
                    onChange={handleTruckChange}
                    options={truckCheckoutOptions}
                    placeholder={
                      truck.id
                        ? `${truck.carrier_name}-${truck.truck_number}`
                        : english(
                            "forms.type.vehicle.placeholders.select-truck"
                          )
                    }
                    isClearable={false} // Allows users to clear the selected value
                    isSearchable={true} // Allows users to search through the dropdown
                    styles={customStyles}
                    unstyled={true}
                    isDisabled={lockTruck}
                  />
                </div>
                {lockTruck && (
                  <p className="error mt-2">
                    {!truck.truck_number
                      ? english("forms.type.vehicle.check-out.truck-not") +
                        trailer.trailer_number + " " + english("forms.type.vehicle.check-out.by") +
                        trailer.reservation.user.first_name + " " + trailer.reservation.user.last_name + 
                        " " +
                        english("forms.type.vehicle.check-out.truck-not2") + " (" +
                        trailer.reservation.truck_asset.company + "-" + trailer.reservation.truck_asset.name + ")"
                      : truck.carrier_name +
                        "-" +
                        truck.truck_number +
                        " " +
                        english("forms.type.vehicle.check-out.reserved") +
                        trailer.trailer_number +
                        " " +
                        english("forms.type.vehicle.check-out.by") +
                        trailer.reservation.user.first_name +
                        " " +
                        trailer.reservation.user.last_name}
                  </p>
                )}
              </div>
            ) : (
              <div className="input-cont">
                <input
                  type="text"
                  className="text-input"
                  value={truck.carrier_name + "-" + truck.truck_number}
                  disabled
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="input-label required">
              {english("forms.type.vehicle.input-headers.truck_condition")}
            </label>
            <div className="input-cont condition-cont">
              <div>
                <select
                  id="conditionSelect"
                  onChange={(e) =>
                    handleVehicleData(
                      setTruck,
                      "truck_condition",
                      e.target.value
                    )
                  }
                  value={truck === "null" ? "" : truck.truck_condition}
                  required
                >
                  <option value="" selected disabled>
                    {english(
                      "forms.type.vehicle.placeholders.select-condition.header"
                    )}
                  </option>
                  <option value="Good">
                    {english(
                      "forms.type.vehicle.placeholders.select-condition.good"
                    )}
                  </option>
                  <option value="Okay">Okay</option>
                  <option value="Poor">
                    {english(
                      "forms.type.vehicle.placeholders.select-condition.poor"
                    )}
                  </option>
                  <option value="Critical">
                    {english(
                      "forms.type.vehicle.placeholders.select-condition.critical"
                    )}
                  </option>
                </select>
                <textarea
                  type="text"
                  className="text-input"
                  value={
                    truck.truck_condition_notes
                      ? truck.truck_condition_notes
                      : ""
                  }
                  onChange={(e) =>
                    handleVehicleData(
                      setTruck,
                      "truck_condition_notes",
                      e.target.value
                    )
                  }
                  disabled
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="input-label required">
              {english("forms.type.vehicle.input-headers.truck-plate")}
            </label>
            <div className="input-cont">
              <input
                disabled
                className="text-input"
                value={truck.truck_plate}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="input-label required">
              {english("forms.type.vehicle.input-headers.odometer")}
            </label>
            <div className="input-cont">
              <input
                type="number"
                min={0}
                className="text-input"
                placeholder={english(
                  "forms.type.vehicle.placeholders.enter-miles"
                )}
                value={truck.odometer}
                onChange={(e) =>
                  handleVehicleData(setTruck, "odometer", e.target.value)
                }
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="input-label required">
              {english("yard-tables.search-filters.dropdowns.driver")}
            </label>
            <div className="input-cont">
              <Select
                onChange={handleVisitorChange}
                options={driverCheckoutOptions}
                placeholder={
                  visitor.id
                    ? `${visitor.first_name} ${visitor.last_name}`
                    : english("forms.type.vehicle.placeholders.select-driver")
                }
                isClearable={true} // Allows users to clear the selected value
                isSearchable={true} // Allows users to search through the dropdown
                styles={customStyles}
                unstyled={true}
              />
            </div>
          </div>
          {carrying ? (
            <div className="form-group">
              <label className="input-label required">
                {english("yard-tables.search-filters.dropdowns.customer")}
              </label>
              <div className="input-cont">
                <div className="search-input">
                  <input
                    type="text"
                    className="text-input"
                    placeholder={english(
                      "forms.type.vehicle.placeholders.search-customer"
                    )}
                    value={trailer.customer}
                    onChange={(e) =>
                      handleVehicleData(setTrailer, "customer", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <label className="input-label required">
              {english("yard-tables.search-filters.dropdowns.destination")}
            </label>
            <div className="input-cont">
              <select
                onChange={(e) =>
                  handleVehicleData(setTruck, "country", e.target.value)
                }
                value={truck.country}
                required
              >
                <option value="" selected disabled>
                  {english(
                    "forms.type.vehicle.placeholders.destination.country"
                  )}
                </option>
                <option value="USA">
                  {english("forms.type.vehicle.placeholders.usa")}
                </option>
                <option value="Mexico">Mexico</option>
              </select>
              <select
                value={truck.countryState}
                onChange={(e) =>
                  handleVehicleData(setTruck, "countryState", e.target.value)
                }
                required
              >
                <option value="">
                  {english("forms.type.vehicle.placeholders.destination.state")}
                </option>
                {truck.country === "USA"
                  ? usStates.map((state) => (
                      <option value={state}>{state}</option>
                    ))
                  : mexicanStates.map((state) => (
                      <option value={state}>{state}</option>
                    ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="input-label"></label>
            <div className="input-cont">
              <input
                type="text"
                className="text-input"
                placeholder={english(
                  "forms.type.vehicle.placeholders.destination.City"
                )}
                value={truck.city}
                onChange={(e) =>
                  handleVehicleData(setTruck, "city", e.target.value)
                }
              />
            </div>
          </div>
          {carrying ? (
            <div>
              <div className="form-group">
                <label className="input-label required">
                  {english ? "Contents " : "Contenido "}
                </label>
                <div className="input-cont">
                  <select
                    value={trailer.is_loaded}
                    onChange={(e) =>
                      handleVehicleData(setTrailer, "is_loaded", e.target.value)
                    }
                    required
                  >
                    <option selected disabled value="">
                      {english(
                        "forms.type.vehicle.placeholders.select-content"
                      )}
                    </option>
                    <option value={true}>
                      {english("forms.type.vehicle.placeholders.loaded")}
                    </option>
                    <option value={false}>
                      {english("forms.type.vehicle.placeholders.empty")}
                    </option>
                  </select>
                </div>
              </div>
              {trailer.is_loaded ? (
                <div className="form-group">
                  <label className="input-label required">
                    {english("forms.type.vehicle.placeholders.seal")}
                  </label>
                  <div className="input-cont">
                    <select
                      onChange={(e) =>
                        handleVehicleData(setTrailer, "is_seal", e.target.value)
                      }
                      value={trailer.is_seal}
                      required
                    >
                      <option value="" disabled selected>
                        {english(
                          "forms.type.vehicle.placeholders.select-status"
                        )}
                      </option>
                      <option value={true}>
                        {english("forms.type.vehicle.placeholders.seal")}
                      </option>
                      <option value={false}>
                        {english("forms.type.vehicle.placeholders.noseal")}
                      </option>
                    </select>
                  </div>
                </div>
              ) : null}
              {trailer.is_seal === "true" || trailer.is_seal === true ? (
                <div className="form-group">
                  <label className="input-label required">
                    {english("forms.type.vehicle.input-headers.sealNum")}
                  </label>
                  <div className="input-cont">
                    <input
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.num"
                      )}
                      value={trailer.seal_number}
                      onChange={(e) =>
                        handleVehicleData(
                          setTrailer,
                          "seal_number",
                          e.target.value
                        )
                      }
                      required
                    />
                  </div>
                </div>
              ) : null}
              <div className="form-group">
                <label className="input-label required">
                  {english(
                    "forms.type.vehicle.input-headers.trailer_condition"
                  )}
                </label>
                <div className="input-cont condition-cont">
                  <div>
                    <select
                      id="conditionSelect"
                      onChange={(e) =>
                        handleVehicleData(
                          setTrailer,
                          "trailer_condition",
                          e.target.value
                        )
                      }
                      value={trailer.trailer_condition}
                      required
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.header"
                        )}
                      </option>
                      <option value="Good">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.good"
                        )}
                      </option>
                      <option value="Okay">Okay</option>
                      <option value="Poor">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.poor"
                        )}
                      </option>
                      <option value="Critical">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.critical"
                        )}
                      </option>
                    </select>
                    <textarea
                      type="text"
                      className="text-input"
                      value={
                        trailer.trailer_condition_notes
                          ? trailer.trailer_condition_notes
                          : ""
                      }
                      onChange={(e) =>
                        handleVehicleData(
                          setTrailer,
                          "trailer_condition_notes",
                          e.target.value
                        )
                      }
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <label className="input-label">
              {english("forms.type.vehicle.input-headers.notes")}
            </label>
            <div className="input-cont">
              <input
                className="text-input"
                placeholder={english("forms.type.vehicle.placeholders.notes")}
                type="text"
                value={truck.generalNotes}
                onChange={(e) =>
                  handleVehicleData(setTruck, "generalNotes", e.target.value)
                }
              />
            </div>
          </div>
          <div className="full-cont">
            <button className="change-pwd-btn" type="submit">
              {english("buttons.continue")}
            </button>
            <button className="cancel-btn" onClick={onClose}>
              {english("buttons.cancel")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
