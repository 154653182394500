import { useEffect, useState } from "react";
import "../../CSS/global.css";
import "../../CSS/guardOverview.css";
import "../../CSS/fancyDropDown.css";
import { GuardGraphics } from "../GuardGraphics";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import UniversalTable from "../Tables/UniversalTable";
import instance from "../../Interceptor/api_instance";
import CheckInBtn from "../Buttons/check-in-btn";
import { AutoCheckOutVehicle } from "../Forms/CheckOut/AutoCheckOutVehicle";
import { CheckInSuccess } from "../Forms/CheckIn/CheckInSuccess";
import HelpContainer from "../HelpContainer";
import { guardTrailerHeaders, guardTruckHeaders } from "../Tables/TableHeaders";
import { useAuth } from "../../Auth/AuthProvider";

/*
    This view calls the API each time
    Store user info and avoid repeated calls 
    Clear user data when the token is expired and cleared
*/

export const GuardOverview = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [trailerData, setTrailerData] = useState(null);
  const [truckData, setTruckData] = useState(null);
  const [table, setTable] = useState("trailers-table");
  const [checkOut, setCheckOut] = useState(false);
  const [checkOutTruckForm, setCheckOutTruckForm] = useState(false);
  const [selectedTrailer, selectTrailer] = useState({});
  const [message, setMessage] = useState(false);
  const [isTrailerLoading, setIsTrailerLoading] = useState(false);
  const [isTruckLoading, setIsTruckLoading] = useState(false);
  const [selectedTruck, selectTruck] = useState({});

  useEffect(() => {
    console.log(user);
    fetchTrailerData();
    fetchTruckData();
  }, []);

  const fetchTrailerData = async () => {
    setIsTrailerLoading(true);
    try {
      let driverData = null;
      driverData = await instance.get("assets/view-trailers");
      setTrailerData(driverData.data);
      setIsTrailerLoading(false);
    } catch (error) {
      setError(error);
    }
  };
  const fetchTruckData = async () => {
    setIsTruckLoading(true);
    try {
      let driverData = null;
      driverData = await instance.get("assets/view-trucks");
      setTruckData(driverData.data);
      setIsTruckLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const active = async (e) => {
    const previous = document.getElementsByClassName("selected");
    for (var i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    setTable(e.target.id);
  };

  const handleForm = (e) => {
    setCheckOut(true);
    selectTrailer(e);
  };
  const handleClose = () => {
    setCheckOut(false);
    setCheckOutTruckForm(false);
  };
  const checkOutTruck = (truck) => {
    selectTruck(truck);
    setCheckOutTruckForm(true);
  };

  return (
    <>
      {checkOut ? (
        <div>
          <div className="page-mask">
            <AutoCheckOutVehicle
              onClose={handleClose}
              setMessage={setMessage}
              vehicle={selectedTrailer.trailer}
              english={t}
              carrying={true}
              vehicleType={"Trailer"}
            />
          </div>
        </div>
      ) : null}
      {checkOutTruckForm ? (
        <div>
          <div className="page-mask">
            <AutoCheckOutVehicle
              onClose={handleClose}
              vehicle={selectedTruck.truck}
              vehicleType={"Truck"}
              english={t}
              carrying={null}
            />
          </div>
        </div>
      ) : null}
      {message ? (
        <div>
          <div className="page-mask">
            <CheckInSuccess
              title="Success"
              message="The vehicle has been checked out."
              setMessage={setMessage}
            />
          </div>
        </div>
      ) : null}
      <div className="global-cont">
        <Helmet>
          <title>Forward | Overview</title>
        </Helmet>
        <div className="page-overview">
          <div className="cont-wrapper">
            <GuardGraphics user={user} english={t} />
            <div className="inventory-header">
              <h2 className="light-black global-h2">
                {t("page-headers.inventory")}
              </h2>
              {user.role !== "Safety" && <CheckInBtn english={t} />}
            </div>
            <ul className="yard-tabs">
              <li className="selected" onClick={active} id="trailers-table">
                {t("yard-tables.tabs.trailers")}
              </li>
              <li className="tab" onClick={active} id="trucks-table">
                {t("yard-tables.tabs.trucks")}
              </li>
            </ul>
            {table === "trailers-table" && (
              <UniversalTable
                english={t}
                data={trailerData}
                headers={guardTrailerHeaders(user)}
                filters={["Location", "Customer", "Contents", "Reservation"]}
                type="trailer"
                handleForm={handleForm}
                error={error}
                pageLoading={isTrailerLoading}
                user={user}
              />
            )}
            {table === "trucks-table" && (
              <UniversalTable
                english={t}
                data={truckData}
                headers={guardTruckHeaders(user)}
                filters={["Location", "Driver"]}
                type="truck"
                handleForm={checkOutTruck}
                error={error}
                pageLoading={isTruckLoading}
                user={user}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
