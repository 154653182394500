import "../../CSS/errorPage.css";
import { Helmet } from "react-helmet";

export const ErrorPage = () => {
  return (
    <div className="global-cont">
      <Helmet>
        <title>ERROR</title>
      </Helmet>
      <div
        style={{ flexDirection: "column", backgroundColor: "#393838" }}
        className="page-overview"
      >
        <div
          style={{ marginTop: "0", marginLeft: "0" }}
          className="cont-wrapper"
        >
          <div className="truck-cont">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="822"
              height="136"
              viewBox="0 0 822 136"
              fill="none"
            >
              <path
                d="M87.6301 109.897L6.28172 93.7274L6.28172 41.4064L87.6301 21.887L87.6301 109.897Z"
                fill="#CFBEFF"
              />
              <path
                d="M227.2 121.216H241.134V14.7839H227.2V121.216Z"
                fill="#B5AFD8"
              />
              <path
                d="M96.0714 21.8871H141.4V113.94H96.0714C96.0714 113.94 77.3387 67.9711 96.0714 21.8871Z"
                fill="#C6B9F9"
              />
              <path
                d="M157.935 11.7809L223.615 11.4344C225.581 11.4344 227.2 12.9936 227.2 14.9571V120.927C227.2 122.891 225.639 124.45 223.673 124.45L161.578 124.45C158.629 124.45 155.681 123.873 152.963 122.775C151.807 122.313 150.477 121.794 148.974 121.158C138.509 116.712 123.361 107.876 112.549 96.2106C92.3711 74.4391 89.1912 39.7316 157.935 11.7809Z"
                fill="#CFBEFF"
              />
              <path
                d="M133.537 117.231V112.669L149.263 121.216H136.948C135.04 121.216 133.537 119.426 133.537 117.231Z"
                fill="#A19CD3"
              />
              <path
                d="M6.28172 93.7274L93.5853 107.241L94.1634 113.073C94.1634 113.073 95.4932 124.45 67.9145 124.45C55.1948 124.45 41.0297 125.316 29.4663 122.775C18.6545 120.408 10.1554 115.037 7.26458 103.141C6.6286 100.426 6.28172 97.3079 6.28172 93.7274Z"
                fill="#CFBEFF"
              />
              <path
                d="M227.2 102.852C227.2 102.852 131.571 92.0527 110.352 93.7274L112.549 96.2106C112.549 96.2106 185.109 96.9614 227.2 105.335V102.852Z"
                fill="#B5AFD8"
              />
              <path
                d="M227.2 105.335V109.897C227.2 109.897 139.434 96.7881 122.089 96.4416C122.031 96.4416 197.829 97.8854 227.2 105.335Z"
                fill="#EEE9FF"
              />
              <path
                d="M112.549 96.2684C132.727 116.192 204.305 123.468 216.099 124.508H161.578C158.629 124.508 155.681 123.93 152.963 122.833C151.807 122.371 150.477 121.851 148.974 121.216C138.509 116.769 123.303 107.934 112.549 96.2684Z"
                fill="#B5AFD8"
              />
              <path
                d="M106.825 110.359C106.825 110.359 88.7286 94.0739 88.7286 67.9711C88.7286 41.8684 106.825 25.583 106.825 25.583C106.825 25.583 82.6 67.6246 106.825 110.359Z"
                fill="#EEE9FF"
              />
              <path
                d="M81.7906 91.2442L6.2817 86.913V83.6212L81.7906 89.5694V91.2442Z"
                fill="#B5AFD8"
              />
              <path
                d="M76.5292 98.8093C76.5292 98.8093 40.625 89.2807 13.1619 93.4964C13.1619 93.4386 40.5093 101.697 76.5292 98.8093Z"
                fill="#EEE9FF"
              />
              <path
                d="M6.5708 98.9826C6.5708 98.9826 6.4552 97.9431 6.4552 97.5966C6.4552 97.2501 6.39732 96.2106 6.39732 96.2106L82.5422 104.296C83.1204 105.739 83.9876 107.068 85.144 108.165L6.5708 98.9826Z"
                fill="#B5AFD8"
              />
              <path
                d="M59.2997 116.943C52.7664 123.468 22.4704 116.943 22.4704 112.669C22.4704 107.761 30.9694 112.092 40.1623 113.997C45.7128 115.095 61.728 114.459 59.2997 116.943Z"
                fill="#EEE9FF"
              />
              <path
                d="M93.8165 110.186C93.8165 110.186 91.8507 112.727 90.4631 122.198H88.0926C88.0926 122.198 88.7864 116.192 90.9835 110.186H93.8165Z"
                fill="#393838"
              />
              <path
                d="M89.7693 132.593H87.0519C86.3003 132.593 85.6065 132.015 85.4331 131.149C84.9705 129.012 84.5658 124.854 88.0348 122.14H90.2318C90.81 122.14 91.2726 122.66 91.2726 123.353V130.86C91.3304 131.784 90.6366 132.593 89.7693 132.593Z"
                fill="#CFBEFF"
              />
              <path
                d="M133.537 18.7686V23.3885L149.263 14.8416L136.948 14.8416C135.04 14.7839 133.537 16.5741 133.537 18.7686Z"
                fill="#A19CD3"
              />
              <path
                d="M94.1634 22.8688C94.1634 22.8688 95.4932 11.4921 67.9145 11.4921C40.4515 11.4921 6.28172 7.50745 6.28172 42.2149L93.5853 28.7015L94.1634 22.8688Z"
                fill="#CFBEFF"
              />
              <path
                d="M227.2 33.1482C227.2 33.1482 131.571 43.9473 110.352 42.2726L112.549 39.7894C112.549 39.7894 185.109 39.0386 227.2 30.665V33.1482Z"
                fill="#B5AFD8"
              />
              <path
                d="M227.2 30.665V26.1028C227.2 26.1028 139.434 39.2119 122.089 39.5584C122.031 39.5584 197.829 38.1146 227.2 30.665Z"
                fill="#EEE9FF"
              />
              <path
                d="M112.549 39.7316C132.727 19.8081 204.305 12.5316 216.099 11.4921L161.578 11.4921C158.629 11.4921 155.681 12.0696 152.963 13.1669C151.807 13.6289 150.477 14.1486 148.974 14.7839C138.509 19.2883 123.303 28.124 112.549 39.7316Z"
                fill="#B5AFD8"
              />
              <path
                d="M106.825 25.6408C106.825 25.6408 88.7286 41.9261 88.7286 68.0289C88.7286 94.1316 106.825 110.417 106.825 110.417C106.825 110.417 82.6 68.3754 106.825 25.6408Z"
                fill="#EEE9FF"
              />
              <path
                d="M81.7906 44.7558L6.2817 49.0871V52.3788L81.7906 46.4306V44.7558Z"
                fill="#B5AFD8"
              />
              <path
                d="M76.5292 37.1907C76.5292 37.1907 40.625 46.7193 13.1619 42.5036C13.1619 42.5614 40.5093 34.3032 76.5292 37.1907Z"
                fill="#EEE9FF"
              />
              <path
                d="M6.5708 37.0174C6.5708 37.0174 6.4552 38.0569 6.4552 38.4034C6.4552 38.7499 6.39732 39.7894 6.39732 39.7894L82.5422 31.7044C83.1204 30.2607 83.9876 28.9325 85.144 27.8352L6.5708 37.0174Z"
                fill="#B5AFD8"
              />
              <path
                d="M59.2997 19.1151C52.7664 12.5894 22.4704 19.1151 22.4704 23.3885C22.4704 28.2972 30.9694 23.966 40.1623 22.0603C45.7128 20.9053 61.728 21.5406 59.2997 19.1151Z"
                fill="#EEE9FF"
              />
              <path
                d="M93.8165 25.814C93.8165 25.814 91.8507 23.273 90.4631 13.8021H88.0926C88.0926 13.8021 88.7864 19.8081 90.9835 25.814H93.8165Z"
                fill="#393838"
              />
              <path
                d="M89.7693 3.40724H87.0519C86.3003 3.40724 85.6065 3.98473 85.4331 4.85097C84.9705 6.9877 84.5658 11.1457 88.0348 13.8599H90.2318C90.81 13.8599 91.2726 13.3401 91.2726 12.6472V5.19747C91.3304 4.21573 90.6366 3.40724 89.7693 3.40724Z"
                fill="#CFBEFF"
              />
              <path
                d="M81.7906 100.542L81.7906 34.7652C81.7906 30.7227 84.1611 27.0845 87.8613 25.4675L96.0714 21.8871C77.3965 67.9711 96.0714 113.94 96.0714 113.94L87.6301 109.897C86.705 109.435 85.8956 108.858 85.2018 108.223C84.0454 107.183 83.1204 105.855 82.6 104.353C82.0218 103.141 81.7906 101.87 81.7906 100.542Z"
                fill="#393838"
              />
              <path
                d="M7.32241 103.198C18.2498 117.058 29.524 122.833 29.524 122.833C18.6545 120.408 10.1554 115.037 7.32241 103.198Z"
                fill="#E0E0E2"
              />
              <path
                d="M7.32241 32.8594C18.2498 18.9996 29.524 13.2246 29.524 13.2246C18.6545 15.5923 10.1554 20.963 7.32241 32.8594Z"
                fill="#E0E0E2"
              />
              <path
                d="M811.151 124.566L245.644 124.566C243.158 124.566 241.076 122.544 241.076 120.003V15.9966C241.076 13.5134 243.1 11.4344 245.644 11.4344L811.151 11.4344C813.637 11.4344 815.718 13.4556 815.718 15.9966V120.003C815.718 122.487 813.695 124.566 811.151 124.566Z"
                fill="#E6E7FF"
                stroke="#A19CD3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="rectangle-divider"></div>
          <div className="cont-404">
            <h1>404</h1>
            <div className="error-cont">
              <h5>Oops! Page not found.</h5>
              <a href="/">Overview page</a>
            </div>
          </div>
          <div className="rectangle-divider"></div>
          <div className="truck-cont">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="822"
              height="136"
              viewBox="0 0 822 136"
              fill="none"
            >
              <path
                d="M87.6301 109.897L6.28172 93.7274L6.28172 41.4064L87.6301 21.887L87.6301 109.897Z"
                fill="#CFBEFF"
              />
              <path
                d="M227.2 121.216H241.134V14.7839H227.2V121.216Z"
                fill="#B5AFD8"
              />
              <path
                d="M96.0714 21.8871H141.4V113.94H96.0714C96.0714 113.94 77.3387 67.9711 96.0714 21.8871Z"
                fill="#C6B9F9"
              />
              <path
                d="M157.935 11.7809L223.615 11.4344C225.581 11.4344 227.2 12.9936 227.2 14.9571V120.927C227.2 122.891 225.639 124.45 223.673 124.45L161.578 124.45C158.629 124.45 155.681 123.873 152.963 122.775C151.807 122.313 150.477 121.794 148.974 121.158C138.509 116.712 123.361 107.876 112.549 96.2106C92.3711 74.4391 89.1912 39.7316 157.935 11.7809Z"
                fill="#CFBEFF"
              />
              <path
                d="M133.537 117.231V112.669L149.263 121.216H136.948C135.04 121.216 133.537 119.426 133.537 117.231Z"
                fill="#A19CD3"
              />
              <path
                d="M6.28172 93.7274L93.5853 107.241L94.1634 113.073C94.1634 113.073 95.4932 124.45 67.9145 124.45C55.1948 124.45 41.0297 125.316 29.4663 122.775C18.6545 120.408 10.1554 115.037 7.26458 103.141C6.6286 100.426 6.28172 97.3079 6.28172 93.7274Z"
                fill="#CFBEFF"
              />
              <path
                d="M227.2 102.852C227.2 102.852 131.571 92.0527 110.352 93.7274L112.549 96.2106C112.549 96.2106 185.109 96.9614 227.2 105.335V102.852Z"
                fill="#B5AFD8"
              />
              <path
                d="M227.2 105.335V109.897C227.2 109.897 139.434 96.7881 122.089 96.4416C122.031 96.4416 197.829 97.8854 227.2 105.335Z"
                fill="#EEE9FF"
              />
              <path
                d="M112.549 96.2684C132.727 116.192 204.305 123.468 216.099 124.508H161.578C158.629 124.508 155.681 123.93 152.963 122.833C151.807 122.371 150.477 121.851 148.974 121.216C138.509 116.769 123.303 107.934 112.549 96.2684Z"
                fill="#B5AFD8"
              />
              <path
                d="M106.825 110.359C106.825 110.359 88.7286 94.0739 88.7286 67.9711C88.7286 41.8684 106.825 25.583 106.825 25.583C106.825 25.583 82.6 67.6246 106.825 110.359Z"
                fill="#EEE9FF"
              />
              <path
                d="M81.7906 91.2442L6.2817 86.913V83.6212L81.7906 89.5694V91.2442Z"
                fill="#B5AFD8"
              />
              <path
                d="M76.5292 98.8093C76.5292 98.8093 40.625 89.2807 13.1619 93.4964C13.1619 93.4386 40.5093 101.697 76.5292 98.8093Z"
                fill="#EEE9FF"
              />
              <path
                d="M6.5708 98.9826C6.5708 98.9826 6.4552 97.9431 6.4552 97.5966C6.4552 97.2501 6.39732 96.2106 6.39732 96.2106L82.5422 104.296C83.1204 105.739 83.9876 107.068 85.144 108.165L6.5708 98.9826Z"
                fill="#B5AFD8"
              />
              <path
                d="M59.2997 116.943C52.7664 123.468 22.4704 116.943 22.4704 112.669C22.4704 107.761 30.9694 112.092 40.1623 113.997C45.7128 115.095 61.728 114.459 59.2997 116.943Z"
                fill="#EEE9FF"
              />
              <path
                d="M93.8165 110.186C93.8165 110.186 91.8507 112.727 90.4631 122.198H88.0926C88.0926 122.198 88.7864 116.192 90.9835 110.186H93.8165Z"
                fill="#393838"
              />
              <path
                d="M89.7693 132.593H87.0519C86.3003 132.593 85.6065 132.015 85.4331 131.149C84.9705 129.012 84.5658 124.854 88.0348 122.14H90.2318C90.81 122.14 91.2726 122.66 91.2726 123.353V130.86C91.3304 131.784 90.6366 132.593 89.7693 132.593Z"
                fill="#CFBEFF"
              />
              <path
                d="M133.537 18.7686V23.3885L149.263 14.8416L136.948 14.8416C135.04 14.7839 133.537 16.5741 133.537 18.7686Z"
                fill="#A19CD3"
              />
              <path
                d="M94.1634 22.8688C94.1634 22.8688 95.4932 11.4921 67.9145 11.4921C40.4515 11.4921 6.28172 7.50745 6.28172 42.2149L93.5853 28.7015L94.1634 22.8688Z"
                fill="#CFBEFF"
              />
              <path
                d="M227.2 33.1482C227.2 33.1482 131.571 43.9473 110.352 42.2726L112.549 39.7894C112.549 39.7894 185.109 39.0386 227.2 30.665V33.1482Z"
                fill="#B5AFD8"
              />
              <path
                d="M227.2 30.665V26.1028C227.2 26.1028 139.434 39.2119 122.089 39.5584C122.031 39.5584 197.829 38.1146 227.2 30.665Z"
                fill="#EEE9FF"
              />
              <path
                d="M112.549 39.7316C132.727 19.8081 204.305 12.5316 216.099 11.4921L161.578 11.4921C158.629 11.4921 155.681 12.0696 152.963 13.1669C151.807 13.6289 150.477 14.1486 148.974 14.7839C138.509 19.2883 123.303 28.124 112.549 39.7316Z"
                fill="#B5AFD8"
              />
              <path
                d="M106.825 25.6408C106.825 25.6408 88.7286 41.9261 88.7286 68.0289C88.7286 94.1316 106.825 110.417 106.825 110.417C106.825 110.417 82.6 68.3754 106.825 25.6408Z"
                fill="#EEE9FF"
              />
              <path
                d="M81.7906 44.7558L6.2817 49.0871V52.3788L81.7906 46.4306V44.7558Z"
                fill="#B5AFD8"
              />
              <path
                d="M76.5292 37.1907C76.5292 37.1907 40.625 46.7193 13.1619 42.5036C13.1619 42.5614 40.5093 34.3032 76.5292 37.1907Z"
                fill="#EEE9FF"
              />
              <path
                d="M6.5708 37.0174C6.5708 37.0174 6.4552 38.0569 6.4552 38.4034C6.4552 38.7499 6.39732 39.7894 6.39732 39.7894L82.5422 31.7044C83.1204 30.2607 83.9876 28.9325 85.144 27.8352L6.5708 37.0174Z"
                fill="#B5AFD8"
              />
              <path
                d="M59.2997 19.1151C52.7664 12.5894 22.4704 19.1151 22.4704 23.3885C22.4704 28.2972 30.9694 23.966 40.1623 22.0603C45.7128 20.9053 61.728 21.5406 59.2997 19.1151Z"
                fill="#EEE9FF"
              />
              <path
                d="M93.8165 25.814C93.8165 25.814 91.8507 23.273 90.4631 13.8021H88.0926C88.0926 13.8021 88.7864 19.8081 90.9835 25.814H93.8165Z"
                fill="#393838"
              />
              <path
                d="M89.7693 3.40724H87.0519C86.3003 3.40724 85.6065 3.98473 85.4331 4.85097C84.9705 6.9877 84.5658 11.1457 88.0348 13.8599H90.2318C90.81 13.8599 91.2726 13.3401 91.2726 12.6472V5.19747C91.3304 4.21573 90.6366 3.40724 89.7693 3.40724Z"
                fill="#CFBEFF"
              />
              <path
                d="M81.7906 100.542L81.7906 34.7652C81.7906 30.7227 84.1611 27.0845 87.8613 25.4675L96.0714 21.8871C77.3965 67.9711 96.0714 113.94 96.0714 113.94L87.6301 109.897C86.705 109.435 85.8956 108.858 85.2018 108.223C84.0454 107.183 83.1204 105.855 82.6 104.353C82.0218 103.141 81.7906 101.87 81.7906 100.542Z"
                fill="#393838"
              />
              <path
                d="M7.32241 103.198C18.2498 117.058 29.524 122.833 29.524 122.833C18.6545 120.408 10.1554 115.037 7.32241 103.198Z"
                fill="#E0E0E2"
              />
              <path
                d="M7.32241 32.8594C18.2498 18.9996 29.524 13.2246 29.524 13.2246C18.6545 15.5923 10.1554 20.963 7.32241 32.8594Z"
                fill="#E0E0E2"
              />
              <path
                d="M811.151 124.566L245.644 124.566C243.158 124.566 241.076 122.544 241.076 120.003V15.9966C241.076 13.5134 243.1 11.4344 245.644 11.4344L811.151 11.4344C813.637 11.4344 815.718 13.4556 815.718 15.9966V120.003C815.718 122.487 813.695 124.566 811.151 124.566Z"
                fill="#E6E7FF"
                stroke="#A19CD3"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
