import { CheckOutVisitor } from "./CheckOutVisitor";
import { useState, useEffect } from "react";
import instance from "../../../Interceptor/api_instance";
import { CheckOutIcon } from "../../../Media/Icons";
import { Dots } from "../../LoadingAnimations/LoadingAnimations";

export const CheckOutVisitorList = ({ onClose, onSuccess, english }) => {
  const [visitors, setVisitors] = useState([]);
  const [form, setForm] = useState(false);
  const [selectedVisitor, selectVisitor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await instance.get("assets/get-visitors/");
      if (response !== undefined) {
        setVisitors(response.data);
        setIsLoading(false);
      }
    })();
  }, []);

  const handleClose = () => {
    setForm(false);
    selectVisitor({});
  };

  const visitorOut = (visitor) => {
    setForm(true);
    selectVisitor(visitor);
  };

  const confirm = async () => {
    setIsConfirmLoading(true);
    const data = {
      first_name: selectedVisitor.visitor.first_name,
      last_name: selectedVisitor.visitor.last_name,
      visitor_id: selectedVisitor.visitor.id,
    };
    const response = await instance.post("assets/check-out-visitor/", data);
    if (response.status === 200) {
      setIsConfirmLoading(false);
      setForm(false);
      onSuccess(selectedVisitor);
    }
  };
  return (
    <div className="questions-cont visitor-cont">
      <h2 className="global-h2 light-black">
        {english("forms.type.visitor.header-out")}
      </h2>
      <h4 className="global-h4 gray bottom-space">
        {english("forms.type.visitor.sub-header")}
      </h4>
      <div className="recent-activity">
        {isLoading ? (
          <Dots />
        ) : visitors !== undefined ? (
          visitors.map((visitor) => (
            <div className="activity-item">
              <span className="global-h5 gray">
                {visitor.visitor.first_name} {visitor.visitor.last_name}
              </span>
              <div onClick={() => visitorOut(visitor)}>
                <CheckOutIcon color="#FF6E6E" />
              </div>
            </div>
          ))
        ) : null}
      </div>
      <br />
      <div className="full-cont">
        <button onClick={onClose} className="cancel-btn">
          {english("buttons.cancel")}
        </button>
      </div>
      {form ? (
        <div>
          <div className="page-mask page-mask-animation">
            <CheckOutVisitor
              onClose={handleClose}
              confirm={confirm}
              visitor={selectedVisitor}
              english={english}
              loading={isConfirmLoading}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
