import { useState, useRef } from "react";
import { PhoneInput } from "../../Inputs/PhoneInput";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Dots } from "../../LoadingAnimations/LoadingAnimations";
import { convertHeicToJpeg } from "../../Utility/HeicToJpg"; 


export const AddNewDriver1 = ({ next, onData, data, onUpload }) => {
  // Initialize formData with data coming from the parent component
  const {t} = useTranslation();
  const [formData, setFormData] = useState({
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    licenseNumber: data.licenseNumber || "",
    personalPhoneNumber: data.personalPhoneNumber || "",
    companyPhoneNumber: data.companyPhoneNumber || "",
    emailAddress: data.emailAddress || "",
    medicalNotes: data.medicalNotes || "",
    photo: data.photo || null,
    username: data.username || "",
    password: data.password || "",
    CarrierName: data.CarrierName || "",
  });

  const [confirmPassword, setConfirmPassword] = useState({
    confirmPassword: "",
  });

  const fileInputRef = useRef(null);

  // Function to handle input changes in the form fields
  const handleInputChange = (e) => {
    // Extract name and value from the input element
    const { name, value } = e.target;

    // Update the corresponding field in formData using the name as the key
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (name, value) => {
    // Update the corresponding field in formData using the name as the key
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // sets confirm password state
  const handleConfirmPassword = (e) => {
    const { name, value } = e.target;

    setConfirmPassword((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleContinue1 = () => {
    // Pass collected user data to the parent component
    onData(formData);
    // Move to the next step
    next();
  };

  // Photo upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
  
    if (file) {
      const convertedFile = await convertHeicToJpeg(file);
      
      // Read the uploaded file as a data URL
      const reader = new FileReader();
      reader.onload = function (e) {
        // Set the uploaded photo in the state and formData
        setFormData((prevFormData) => ({
          ...prevFormData,
          photo: [e.target.result, convertedFile],
        }));
      };
      reader.readAsDataURL(convertedFile);
    }
  };
  

  const deletePhoto = (e) => {
    e.preventDefault();
    setFormData((prevFormData) => ({
      ...prevFormData,
      photo: null,
    }));
  };

  // Function to handle upload button click
  const handleUploadClick = (e) => {
    e.preventDefault(); // Prevent form submission
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Ensure fileInputRef is not null before accessing click method
    }
  };

  return (
    <>
      <div className="add-new-driver-cont">
        <Helmet>
          <title>Forward | New driver</title>
        </Helmet>
        <div className="driver-profile-cont">
          <h4 className="global-h4 gray new-driver-header">
            {t("forms.type.check-in-header")}
          </h4>
          <form onSubmit={handleContinue1}>
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.newDriver.input-headers.firstName")}
              </label>
              <div className="input-cont">
                <input
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="text-input"
                  type="text"
                  placeholder="John"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.newDriver.input-headers.lastName")}
              </label>
              <div className="input-cont">
                <input
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="text-input"
                  type="text"
                  placeholder="Doe"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.newDriver.input-headers.carrier")}
              </label>

              <div className="input-cont">
                <label>
                  <input
                    type="radio"
                    name="CarrierName"
                    value="JFG"
                    checked={formData.CarrierName === "JFG"}
                    onChange={handleInputChange}
                  />
                  JFG
                </label>
                <label>
                  <input
                    type="radio"
                    name="CarrierName"
                    value="ATI"
                    checked={formData.CarrierName === "ATI"}
                    onChange={handleInputChange}
                  />
                  ATI
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="companyPhone" className="input-label required">
                {t("forms.type.newDriver.input-headers.license")}
              </label>
              <div className="input-cont">
                <input
                  id=""
                  name="licenseNumber"
                  value={formData.licenseNumber}
                  onChange={handleInputChange}
                  className="text-input"
                  type="text"
                  placeholder="A0002144"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="personalPhone" className="input-label required">
                {t("forms.type.newDriver.input-headers.personalPhone")}
              </label>
              <PhoneInput
                name={"personalPhoneNumber"}
                value={formData.personalPhoneNumber}
                change={handlePhoneChange}
                id={"personalPhone"}
              />
            </div>
            <div className="form-group">
              <label htmlFor="companyPhone" className="input-label">
                {t("forms.type.newDriver.input-headers.companyPhone")}
              </label>
              <PhoneInput
                name={"companyPhoneNumber"}
                value={formData.companyPhoneNumber}
                change={handlePhoneChange}
                id={"companyPhone"}
              />
            </div>
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.newDriver.input-headers.email")}
              </label>

              <div className="input-cont">
                <input
                  name="emailAddress"
                  value={formData.emailAddress}
                  onChange={handleInputChange}
                  className="text-input"
                  type="email"
                  placeholder="john@agramontworldwide.com"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label">
                {t("forms.type.newDriver.input-headers.medical")}
              </label>
              <div className="input-cont">
                <textarea
                  name="medicalNotes"
                  value={formData.medicalNotes}
                  onChange={handleInputChange}
                  className="text-input area"
                  placeholder={t("forms.type.newDriver.placeholders.medical")}
                />
              </div>
            </div>
            <div className="driver-photo-id">
              <h2 className="edit-header">
                {t("forms.type.newDriver.input-headers.id")}
              </h2>
              <div data-testid="profile-photo" className="driver-photo">
                {/* Display the uploaded photo */}
                {formData.photo && (
                  <img src={formData.photo[0]} alt="Uploaded" />
                )}
              </div>

              <div>
                {/* Hidden file input */}
                <input
                  data-testid="file-input"
                  className="file-input"
                  type="file"
                  name="photo"
                  onChange={handleFileChange}
                  accept="image/*, .heic"
                  ref={fileInputRef} // Connect ref to the input element
                />
                <button
                  onClick={handleUploadClick}
                  className="profile-pic-btn gap"
                >
                  {t("buttons.uploadPhoto")}
                </button>

                <button onClick={deletePhoto} className="profile-pic-btn">
                  {t("buttons.deletePhoto")}
                </button>
              </div>
            </div>

            <div className="password-block">
              <div className="form-group">
                <label className="input-label required">
                  {t("forms.type.newDriver.input-headers.username")}
                </label>
                <div className="input-cont">
                  <input
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    className="text-input"
                    type="text"
                    placeholder={t(
                      "forms.type.newDriver.input-headers.username"
                    )}
                    required
                  />
                </div>
              </div>
              <p className="gray">
                {t("forms.type.newDriver.input-headers.requirements")}
              </p>
              <div className="form-group">
                <label className="input-label required">
                  {t("forms.type.newDriver.input-headers.password")}
                </label>
                <div className="input-cont">
                  <input
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="text-input"
                    type="password"
                    placeholder={t(
                      "forms.type.newDriver.input-headers.password"
                    )}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="input-label required">
                  {t("forms.type.newDriver.input-headers.password2")}
                </label>
                <div className="input-cont">
                  <input
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleConfirmPassword}
                    className="text-input"
                    type="password"
                    placeholder={t(
                      "forms.type.newDriver.input-headers.password2"
                    )}
                    required
                  />
                </div>
              </div>
              {formData.password &&
                confirmPassword.confirmPassword &&
                formData.password !== confirmPassword.confirmPassword && (
                  <p className="error-text">
                    {t("forms.type.newDriver.placeholders.no-match")}
                  </p>
                )}
              {formData.password &&
                confirmPassword.confirmPassword &&
                confirmPassword.confirmPassword.length < 8 && (
                  <p className="error-text">
                    {t("forms.type.newDriver.placeholders.maxlength")}
                  </p>
                )}
            </div>
            <div className="full-cont">
              {formData.password &&
              confirmPassword.confirmPassword &&
              confirmPassword.confirmPassword.length >= 8 &&
              formData.password === confirmPassword.confirmPassword &&
              formData.photo ? (
                <button type="submit" className="profile-pic-btn">
                  {t("buttons.continue")}
                </button>
              ) : (
                <>
                  <p className="">
                    {t("forms.type.newDriver.placeholders.completeForm")}
                  </p>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export const AddNewDriver2 = ({ next, back, onData, data }) => {
  // Initialize formData with data coming from the parent component
  
  const {t} = useTranslation();

  const [formData, setFormData] = useState({
    // Set initial values for formData fields based on data received from the parent component
    emergencyContact1Name: data.emergencyContact1Name || "",
    emergencyContact1LastName: data.emergencyContact1LastName || "",
    emergencyContact1PhoneNumber: data.emergencyContact1PhoneNumber || "",
    emergencyContact2Name: data.emergencyContact2Name || "",
    emergencyContact2LastName: data.emergencyContact2LastName || "",
    emergencyContact2PhoneNumber: data.emergencyContact2PhoneNumber || "",
    emergencyContact3Name: data.emergencyContact3Name || "",
    emergencyContact3LastName: data.emergencyContact3LastName || "",
    emergencyContact3PhoneNumber: data.emergencyContact3PhoneNumber || "",
  });


  // Function to handle input changes in the form fields
  const handleInputChange = (e) => {
    // Extract name and value from the input element
    const { name, value } = e.target;
    // Update the corresponding field in formData using the name as the key
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Function to handle moving to the next step
  const handleContinue1 = () => {
    // Pass collected user data to the parent component using onData callback
    onData(formData);
    // Move to the next step by calling the next function
    next();
  };

  // Function to handle going back to the previous step
  const handleBack = () => {
    // Call the back function to navigate back
    back();
  };

  const handlePhoneChange = (name, value) => {
    // Update the corresponding field in formData using the name as the key
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="add-new-driver-cont">
        <div className="driver-profile-cont">
          <h4 className="global-h4 gray new-driver-header">
            {t("forms.type.vehicle.sub-header.firstForm")}
          </h4>
          <form onSubmit={handleContinue1}>
            <div className="emergancy-edit">
              <h2 className="edit-header">{t("forms.type.newDriver.subheaders.emergencyContact")} 1</h2>
              <div className="form-group">
                <label className="input-label required">{t("forms.type.newDriver.input-headers.contactName")}</label>
                <div className="input-cont">
                  <input
                    name="emergencyContact1Name"
                    placeholder="John"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact1Name}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    name="emergencyContact1LastName"
                    placeholder="Doe"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact1LastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="contact-phone" className="input-label required">
                  {t("forms.type.newDriver.input-headers.cellNum")}
                </label>
                <PhoneInput
                  id="contact-phone"
                  name={"emergencyContact1PhoneNumber"}
                  value={formData.emergencyContact1PhoneNumber}
                  change={handlePhoneChange}
                />
              </div>
            </div>
            <div className="emergancy-edit">
              <h2 className="edit-header">{t("forms.type.newDriver.subheaders.emergencyContact")} 2</h2>
              <div className="form-group">
                <label className="input-label required">{t("forms.type.newDriver.input-headers.contactName")}</label>
                <div className="input-cont">
                  <input
                    name="emergencyContact2Name"
                    placeholder="John"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact2Name}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    name="emergencyContact2LastName"
                    placeholder="Doe"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact2LastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="contact-phone" className="input-label required">
                {t("forms.type.newDriver.input-headers.cellNum")}
                </label>
                <PhoneInput
                  id={"contact-phone"}
                  name={"emergencyContact2PhoneNumber"}
                  value={formData.emergencyContact2PhoneNumber}
                  change={handlePhoneChange}
                />
              </div>
            </div>
            <div className="emergancy-edit">
              <h2 className="edit-header">{t("forms.type.newDriver.subheaders.emergencyContact")} 3</h2>
              <div className="form-group">
                <label className="input-label required">{t("forms.type.newDriver.input-headers.contactName")}</label>
                <div className="input-cont">
                  <input
                    name="emergencyContact3Name"
                    placeholder="John"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact3Name}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    name="emergencyContact3LastName"
                    placeholder="Doe"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact3LastName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="contact-phone" className="input-label required">
                {t("forms.type.newDriver.input-headers.cellNum")}
                </label>
                <PhoneInput
                  id={"contact-phone"}
                  name={"emergencyContact3PhoneNumber"}
                  value={formData.emergencyContact3PhoneNumber}
                  change={handlePhoneChange}
                />
              </div>
            </div>
            <div className="full-cont">
              <button onClick={handleBack} className="other-btn">
                {t("buttons.back")}
              </button>
              <button className="profile-pic-btn" type="submit">
                {t("buttons.continue")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export const AddNewDriverFinal = ({ back, onData, data, submit }) => {
  // Initialize state variables
  const [isEditing, setIsEditing] = useState(false); // State variable to track if the inputs are in editing mode
  const [formData, setFormData] = useState(data); // State variable to store form data
  const renderedImg = formData.photo[0]; // src used for rendering
  const fileInputRef = useRef(null);
  const [isConfirmed, setIsConfirmed] = useState(false); // Confirms user inputs and changes data into form data
  const [isloading, setIsLoading] = useState(false);

  const {t} = useTranslation();

  // Function to navigate back
  const handleBack = () => {
    back();
  };

  // Function to enable editing of inputs
  const handleEditInputs = () => {
    setIsEditing(true);
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target; // Extract 'name' and 'value' from the event target
    // Update 'formData' state with the new value, using the name of the input field as the key
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Function to save changes
  const handleSaveChanges = () => {
    // Save changes by passing updated data to parent component using 'onData' callback
    onData(formData); // Pass 'formData' to the 'onData' callback function
    setIsEditing(false); // Set 'isEditing' state to false to disable editing mode
  };

  const handlePhotoChange = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const file = e.target.files[0];
  
    if (file) {
      const convertedFile = await convertHeicToJpeg(file);
  
      const reader = new FileReader();
      reader.onload = function (event) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          photo: [event.target.result, convertedFile],
        }));
        onData(prevFormData => ({
          ...prevFormData,
          photo: [event.target.result, convertedFile],
        }));
      };
      reader.readAsDataURL(convertedFile);
    }
  };
  

  // Select the correct format from the phone objects
  const handlePhoneUpdate = () => {
    // Define an array of phone number fields to update
    const phoneFields = [
      "personalPhoneNumber",
      "companyPhoneNumber",
      "emergencyContact1PhoneNumber",
      "emergencyContact2PhoneNumber",
      "emergencyContact3PhoneNumber",
    ];

    // Iterate over the phoneFields array and update corresponding fields in formData
    phoneFields.forEach((field) => {
      // Extract the formattedValue from data and assign it to formData
      formData[field] = data[field]?.formattedValue || "";
    });
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    handlePhoneUpdate();
    const newFormData = new FormData();
    newFormData.append("photo", formData.photo[1]);

    newFormData.append("data", JSON.stringify(formData));
    onData(newFormData); // Pass 'formData' to the 'onData' callback function
    setIsConfirmed(true);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    await submit();
    setIsLoading(false);
  };

  // Function to handle upload button click
  const handleUploadClick = (e) => {
    e.preventDefault(); // Prevent form submission
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Ensure fileInputRef is not null before accessing click method
    }
  };

  const handlePhoneChange = (name, value) => {
    // Update the corresponding field in formData using the name as the key
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="add-new-driver-cont">
        <div className="driver-profile-cont">
          <h4 className="global-h4 gray new-driver-header">
            {t("forms.type.newDriver.input-headers.review-submission")}
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="input-label required">{t("forms.type.newDriver.input-headers.name")}</label>
              <div className="input-cont">
                <input
                  placeholder="John"
                  className="text-input"
                  type="text"
                  name="firstName" // Ensure this matches the key in formData
                  value={formData.firstName}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
                <input
                  placeholder="Doe"
                  className="text-input"
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.newDriver.input-headers.license")}
              </label>
              <div className="input-cont">
                <input
                  placeholder="A0002144"
                  className="text-input"
                  type="text"
                  name="licenseNumber"
                  value={formData.licenseNumber}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.newDriver.input-headers.personalPhone")}
              </label>
              <PhoneInput
                name={"personalPhoneNumber"}
                value={formData.personalPhoneNumber}
                change={handlePhoneChange}
                disabled={!isEditing}
                required
              />
            </div>
            <div className="form-group">
              <label className="input-label">{t("forms.type.newDriver.input-headers.companyPhone")}</label>
              <PhoneInput
                name={"companyPhoneNumber"}
                value={formData.companyPhoneNumber}
                change={handlePhoneChange}
                disabled={!isEditing}
                required
              />
            </div>
            <div className="form-group">
              <label className="input-label required">{t("forms.type.newDriver.input-headers.email")}</label>
              <div className="input-cont">
                <input
                  placeholder="john@example.com"
                  className="text-input"
                  type="email"
                  name="emailAddress"
                  value={formData.emailAddress}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label">{t("forms.type.newDriver.input-headers.medical")}</label>
              <div className="input-cont">
                <textarea
                  className="text-input area"
                  name="medicalNotes"
                  value={formData.medicalNotes}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
            </div>
            {!isEditing && (
              <div className="full-cont">
                <button onClick={handleEditInputs} className="other-btn">
                  {t("buttons.editInputs")}
                </button>
              </div>
            )}
            {isEditing && (
              <div className="full-cont">
                <button onClick={handleSaveChanges} className="profile-pic-btn">
                  {t("buttons.save-changes")}
                </button>
              </div>
            )}
            {/* -------------------------- Picture section -------------------------- */}
            <div className="driver-photo-id">
              <h2 className="edit-header">{t("forms.type.newDriver.input-headers.id")}</h2>
              <div className="driver-photo">
                <img
                  src={renderedImg}
                  alt="Driver"
                  //   style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>

              <input
                className="file-input"
                data-testid="file-input"
                type="file"
                name="photo"
                onChange={handlePhotoChange}
                accept="image/*, .heic"
                ref={fileInputRef} // Connect ref to the input element
              />

              <div>
                <button
                  onClick={handleUploadClick}
                  className="other-btn"
                  type="button"
                >
                  {t("buttons.editPhoto")}
                </button>
              </div>
            </div>
            {/* -------------------------- Emergency contact sections -------------------------- */}

            {[1, 2, 3].map((index) => (
              <div className="emergancy-edit" key={index}>
                <h2 className="edit-header">{t("forms.type.newDriver.subheaders.emergencyContact")} {index}</h2>
                <div className="form-group">
                  <label className="input-label required">{t("forms.type.newDriver.input-headers.contactName")}</label>
                  <div className="input-cont">
                    <input
                      placeholder="John"
                      className="text-input"
                      type="text"
                      name={`emergencyContact${index}Name`}
                      value={formData[`emergencyContact${index}Name`]}
                      onChange={handleInputChange}
                      required
                      disabled={!isEditing}
                    />
                    <input
                      placeholder="Doe"
                      className="text-input"
                      type="text"
                      name={`emergencyContact${index}LastName`}
                      value={formData[`emergencyContact${index}LastName`]}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label required">
                  {t("forms.type.newDriver.input-headers.cellNum")}
                  </label>
                  <PhoneInput
                    name={`emergencyContact${index}PhoneNumber`}
                    value={formData[`emergencyContact${index}PhoneNumber`]}
                    change={handlePhoneChange}
                    disabled={!isEditing}
                    required
                  />
                </div>
              </div>
            ))}
            {!isEditing && (
              <div className="full-cont">
                <button onClick={handleEditInputs} className="other-btn">
                  {t("buttons.editInputs")}
                </button>
              </div>
            )}
            {isEditing && (
              <div className="full-cont">
                <button onClick={handleSaveChanges} className="profile-pic-btn">
                  {t("buttons.save-changes")}
                </button>
              </div>
            )}
            {isloading ? (
              <Dots />
            ) : (
              <div className="full-cont mt-4">
                <button onClick={handleBack} className="other-btn">
                  {t("buttons.back")}
                </button>

                {isConfirmed ? (
                  <button type="submit" className="profile-pic-btn">
                    {t("buttons.submit")}
                  </button>
                ) : (
                  <button onClick={handleConfirm} className="other-btn">
                    {t("buttons.continue")}
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
