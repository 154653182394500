import instance from "../../Interceptor/api_instance";
import { useState, useEffect } from "react";

export const ChangePassword = ({ onClose, setMessage, english }) => {
  const [currentPasswd, setCurrentPasswd] = useState("");
  const [newPasswd, setNewPasswd] = useState("");
  const [confirmPasswd, setConfirmPasswd] = useState("");

  useEffect(() => {
    const uppercase = document.getElementById("passwd-uppercase");
    const lowercase = document.getElementById("passwd-lowercase");
    const number = document.getElementById("passwd-number");
    const special = document.getElementById("passwd-special");
    const minLength = document.getElementById("passwd-length");
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    /[A-Z]/.test(newPasswd)
      ? (uppercase.checked = true)
      : (uppercase.checked = false);
    /[a-z]/.test(newPasswd)
      ? (lowercase.checked = true)
      : (lowercase.checked = false);
    format.test(newPasswd)
      ? (special.checked = true)
      : (special.checked = false);
    newPasswd.length > 7
      ? (minLength.checked = true)
      : (minLength.checked = false);
    /\d/.test(newPasswd) ? (number.checked = true) : (number.checked = false);
  }, [newPasswd]);

  const submitPwd = async (e) => {
    e.preventDefault();
    //Check if any requirement is not yet met
    const uppercase = document.getElementById("passwd-uppercase");
    const lowercase = document.getElementById("passwd-lowercase");
    const number = document.getElementById("passwd-number");
    const special = document.getElementById("passwd-special");
    const minLength = document.getElementById("passwd-length");
    if (
      !(
        uppercase.checked &&
        lowercase.checked &&
        special.checked &&
        minLength &&
        number.checked
      )
    ) {
      window.alert("Your new password does not meet all the required fields.");
    }

    //Confirm the two new passwords are the same
    else if (newPasswd !== confirmPasswd) {
      window.alert("Please make sure both new passwords are the same.");
    } else if (newPasswd === currentPasswd) {
      window.alert("Your new password cannot be the same as your old one.");
    } else {
      try {
        const passwords = {
          currentPassword: currentPasswd,
          newPassword: newPasswd,
        };
        const response = await instance.post("api/change-password/", passwords);
        setMessage(true);
        onClose();
      } catch (e) {
        window.alert(e);
      }
    }
  };
  return (
    <form
      className="change-password-cont"
      id="change-password"
      onSubmit={submitPwd}
    >
      <h2 className="global-h2 black">
        {english("page-subheaders.change-password")}
      </h2>
      <h4 className="global-h4 gray">
        {english("forms.type.vehicle.sub-header.firstForm")}
      </h4>
      <div className="form-group">
        <label className="input-label required">
          {english("forms.type.change-password.current-pwd")}
        </label>
        <input
          type="password"
          className="text-input"
          onChange={(e) => setCurrentPasswd(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label className="input-label required">
          {english("forms.type.change-password.new-pwd")}
        </label>
        <div className="new-password">
          <input
            type="password"
            className="text-input"
            onChange={(e) => setNewPasswd(e.target.value)}
            required
          />
          <div style={{marginTop:'16px'}}>
            <label className="global-h5">
              {english("forms.type.change-password.requirements.header")}
            </label>
            <div className="checkbox-cont">
              <input type="checkbox" id="passwd-length" disabled />
              <p className="global-p">
                {english("forms.type.change-password.requirements.min")}
              </p>
            </div>
            <div className="checkbox-cont">
              <input type="checkbox" id="passwd-uppercase" disabled />
              <p className="global-p">
                1 {english("forms.type.change-password.requirements.upper")}
              </p>
            </div>
            <div className="checkbox-cont">
              <input type="checkbox" id="passwd-lowercase" disabled />
              <p className="global-p">
                1 {english("forms.type.change-password.requirements.lower")}
              </p>
            </div>
            <div className="checkbox-cont">
              <input type="checkbox" id="passwd-number" disabled />
              <p className="global-p">1 {english("forms.type.change-password.requirements.num")}</p>
            </div>
            <div className="checkbox-cont">
              <input type="checkbox" id="passwd-special" disabled />
              <p className="global-p">
                1 {english("forms.type.change-password.requirements.special")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="input-label required">
          {english("forms.type.change-password.confirm")}
        </label>
        <input
          type="password"
          className="text-input"
          onChange={(e) => setConfirmPasswd(e.target.value)}
          required
        />
      </div>
      <div className="full-cont">
        <button type="submit" className="change-pwd-btn">
          {english("buttons.save")}
        </button>
        <button onClick={onClose} className="cancel-btn">
          {english("buttons.cancel")}
        </button>
      </div>
    </form>
  );
};
