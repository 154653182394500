export const ProfilePhoto = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clipPath="url(#clip0_1669_59)">
        <path
          d="M31.1 30.3C27.8 33.8 23.1 36 17.9 36C12.7 36 8.00005 33.8 4.80005 30.3C5.80005 26.8 9.00005 24.2 12.8 24.2H23C26.9 24.2 30.2 26.8 31.1 30.3Z"
          fill="#5656B9"
        />
        <path
          d="M25.8 14.2C25.8 18.5 22.3 22 18 22C13.7 22 10.2 18.5 10.2 14.2C10.2 9.9 13.7 6.4 18 6.4C22.3 6.4 25.8 9.9 25.8 14.2Z"
          fill="#5656B9"
        />
        <path
          d="M18 0C8.1 0 0 8.1 0 18C0 22.8 1.8 27.1 4.9 30.3C5.9 26.8 9.1 24.2 12.9 24.2H23.1C26.9 24.2 30.1 26.8 31.1 30.3C34.1 27.1 36 22.8 36 18C36 8.1 27.9 0 18 0ZM18 22C13.7 22 10.2 18.5 10.2 14.2C10.2 9.9 13.7 6.4 18 6.4C22.3 6.4 25.8 9.9 25.8 14.2C25.8 18.5 22.3 22 18 22Z"
          fill="#E6E7FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1669_59">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
