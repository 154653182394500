import { useState, useEffect } from "react";
import instance from "../../../Interceptor/api_instance";
import { sortTruckList, sortTrailers } from "../../Tables/Sorting/functions";
import { AutoCheckOutTrailer } from "./AutoCheckOutTrailer";
import { CheckInTruckF15 } from "../CheckIn/CheckInTruckF15";
import { CheckInTrailerF15 } from "../CheckIn/CheckInTrailerF15";
import { CheckInPhotos } from "../CheckIn/CheckInPhotos";
import { CheckInSuccess } from "../CheckIn/CheckInSuccess";
import CheckoutConfirm from "../../Modals/CheckoutConfirm";
import { use } from "i18next";

export const AutoCheckOutVehicle = ({
  onClose,
  vehicle,
  vehicleType,
  english,
  carrying,
}) => {
  const [trucks, setTruckList] = useState([]);
  const [truck, setTruck] = useState(vehicleType === "Truck" ? vehicle : {});
  const [trailerData, setTrailer] = useState(
    vehicleType === "Trailer" ? vehicle : {}
  );
  const [trailerList, setTrailerList] = useState([]);
  const [visitorList, setVisitorList] = useState([]);
  const [visitor, setVisitor] = useState({});
  const [success, setSuccess] = useState(false);
  const [isCarrying, setIsCarrying] = useState(carrying);
  const [order, setOrder] = useState(1);

  const [dimensionOption, setDimensionOption] = useState(0);
  const [customLength, setCustomLength] = useState("");
  const [customWidth, setCustomWidth] = useState("");
  const [customHeight, setCustomHeight] = useState("");

  const [capturedPhotos, setCapturedPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [lockTruck, setLockTruck] = useState(false)
  const [trailerForm, setTrailerForm] = useState({
    trailer_dimensions: [parseFloat(53), parseFloat(8.5), parseFloat(13.5)],
    chassis: true,
    inOutDoors: true,
    trailerFloor: true,
    sideWalls: true,
    frontWall: true,
    ceiling: true,
    refrigerator: true,
    sealCondition: true,
    trailerGarbage: true,
    trailerCleanliness: true,
    filter: true,
    humidity: true,
    view_seal: true,
    verify_seal: true,
    tug_seal: true,
    twist_turn_seal: true,
  });
  const [truckForm, setTruckForm] = useState({
    bumper: true,
    engine: true,
    tires: true,
    cabFloor: true,
    fuelTank: true,
    compartments: true,
    airTank: true,
    driveShafts: true,
    fifthWheel: true,
    exhaust: true,
    truckGarbage: true,
    truckCleanliness: true,
  });
  const [currentForm, setCurrentForm] = useState(1);

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      truck.truck_condition === "Poor" ||
      truck.truck_condition === "Critical"
    ) {
      window.alert("Truck condition is not up to standard.");
      setIsLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      capturedPhotos.forEach((photo, index) => {
        formData.append("photo", photo);
      });

      // Get dimensions of trailer. Order is length, width, height and in feet
      let dimensions = [];
      if (dimensionOption === 1) {
        dimensions = [parseFloat(53), parseFloat(8.5), parseFloat(13.5)];
      } else if (dimensionOption === 2) {
        dimensions = [parseFloat(48), parseFloat(8.5), parseFloat(13.5)];
      } else {
        dimensions = [
          parseFloat(customLength),
          parseFloat(customWidth),
          parseFloat(customHeight),
        ];
      }
      if (isCarrying) {
        if (
          trailerData.trailer_condition === "Poor" ||
          trailerData.trailer_condition === "Critical"
        ) {
          window.alert("Trailer condition is not up to standard.");
          setIsLoading(false);
          return;
        }
        const data = {
          ...trailerForm,
          ...truckForm,
          first_name: visitor.first_name,
          last_name: visitor.last_name,
          destination:
            truck.city + ", " + truck.countryState + ", " + truck.country,
          carrier_name: truck.carrier_name,
          trailer_number: trailerData.trailer_number,
          trailer_id: trailerData.id,
          trailer_type: trailerData.trailer_type,
          trailer_condition: trailerData.trailer_condition,
          trailer_dimensions: dimensions,
          truck_number: truck.truck_number,
          truck_condition: truck.truck_condition,
          truck_id: truck.id,
          odometer: truck.odometer,
          customer: trailerData.customer ? trailerData.customer : "",
          is_loaded: trailerData.is_loaded,
          is_seal: trailerData.is_seal,
          seal_number: trailerData.seal_number ? trailerData.seal_number : null,
          generalNotes: truck.generalNotes ? truck.generalNotes : "",
          driver_id: visitor.id,
        };

        console.log(data);
        formData.append("data", JSON.stringify(data));
        await instance.post("assets/check-out-trailer/", formData, {
          headers: {
            "Content-Type": "multipart/data-form",
          },
        });
      } else {
        const data = {
          ...truckForm,
          ...truck,
          first_name: visitor.first_name,
          last_name: visitor.last_name,
          driver_id: visitor.id,
          destination:
            truck.city + ", " + truck.countryState + ", " + truck.country,
          truck_id: truck.id,
        };
        formData.append("data", JSON.stringify(data));
        await instance.post("assets/check-out-truck/", formData, {
          headers: {
            "Content-Type": "multipart/data-form",
          },
        });
      }
      setCurrentForm(0);
      setSuccess(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (Object.keys(truck).length === 0) {
        window.alert("Please select a truck to check out.");
      } else if (Object.keys(visitor).length === 0) {
        window.alert("Please select a visitor to check out.");
      } else {
        window.alert(e);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (carrying) {
          const [truckData, visitorData] = await Promise.all([
            instance.get("assets/view-trucks"),
            instance.get("assets/get-visitors/"),
          ]);
          if (truckData.data !== "") {
            sortTruckList(truckData.data, setTruckList);
          }
          if (visitorData.data !== "") {
            setVisitorList(visitorData.data);
          }
        } else {
          const [allTrailers, visitorData] = await Promise.all([
            instance.get("assets/view-trailers"),
            instance.get("assets/get-visitors/"),
          ]);
          if (allTrailers.data !== "") {
            sortTrailers(allTrailers.data, setTrailerList, order, setOrder);
          }
          if (visitorData.data !== "") {
            setVisitorList(visitorData.data);
          }
        }
      } catch (error) {
        window.alert("Error fetching data:", error);
      }
    };
    fetchData();
    
  }, []);

  useEffect (() => {
    checkReservation();
  },[trucks])

  const nextForm = () => {
    setCurrentForm(4);
  };

  const backForm = () => {
    setCurrentForm(1);
  };

  const handleTrailerPart = (part, value) => {
    setTrailerForm((prevFormData) => ({
      ...prevFormData,
      [part]: value,
    }));
  };

  const handleVehicleData = (setVehicle, key, value) => {
    setVehicle((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  const handleTruckPart = (part, value) => {
    setTruckForm((prevFormData) => ({
      ...prevFormData,
      [part]: value,
    }));
  };

  console.log("truck", truck);
  console.log("truck list", trucks);
  console.log("trailer", trailerData);

  const checkReservation = () => {
    if (trailerData.reservation?.truck_asset) {
      console.log("this function works", trailerData.reservation.truck_asset);
      handleTruckChange2(trailerData.reservation.truck_asset);
    } else {
      console.log("function not reading the state");
    }
  };

  const handleTruckChange2 = (selectedOption) => {
    console.log('selectedOption', selectedOption)
    if (selectedOption) {
      const selectedTruckPlate = selectedOption.license_plate;
      const selectedTruckObject = trucks.filter(
        (obj) => obj.truck.truck_plate === selectedTruckPlate
      );

      setTruck((prevState) => ({
        ...selectedTruckObject[0]?.truck,
        
      }));
      setLockTruck(true)
    }
  };

  const handleTrailerchange = (e) => {
    const selectedTrailerId = e.target.value;
    const selectedTrailerObject = trailerList.filter((obj) => {
      return obj.trailer.id === parseInt(selectedTrailerId);
    });
    setTrailer(selectedTrailerObject[0].trailer);
  };
  const handleTruckChange = (selectedOption) => {
    const selectedTruckId = selectedOption ? selectedOption.value : "";

    // Find the truck object corresponding to the selected ID
    const selectedTruckObject = trucks.filter(
      (obj) => obj.truck.id === parseInt(selectedTruckId)
    );

    // Set the selected truck in the state
    const truck = selectedTruckObject[0];
    setTruck(truck.truck);
  };

  const handleVisitorChange = (selectedOption) => {
    // selectedOption will be an object like { value: 'someValue', label: 'someLabel' }
    // or it will be null if the selection is cleared

    // Extract the value from selectedOption if not null, otherwise set to an empty string
    const visitorId = selectedOption ? selectedOption.value : "";

    // Find the visitor object corresponding to the selected ID
    const visitorObject = visitorList.find(
      (obj) => obj.visitor.id === parseInt(visitorId)
    );

    // Set the selected visitor in the state
    setVisitor(visitorObject.visitor);
  };

  const deletePhoto = (index) => {
    const updatedCapturedPhotos = [...capturedPhotos];
    updatedCapturedPhotos.splice(index, 1);
    setCapturedPhotos(updatedCapturedPhotos);
  };
  return (
    <div>
      {currentForm === 1 ? (
        <AutoCheckOutTrailer
          handleTruckChange={handleTruckChange}
          trucks={trucks}
          handleVehicleData={handleVehicleData}
          setTrailer={setTrailer}
          setTruck={setTruck}
          onClose={onClose}
          nextForm={nextForm}
          trailer={trailerData}
          handleVisitorChange={handleVisitorChange}
          truck={truck}
          english={english}
          visitorList={visitorList}
          visitor={visitor}
          customWidth={customWidth}
          customLength={customLength}
          customHeight={customHeight}
          setCustomWidth={setCustomWidth}
          setCustomHeight={setCustomHeight}
          setCustomLength={setCustomLength}
          setDimensionOption={setDimensionOption}
          dimensionOption={dimensionOption}
          carrying={isCarrying}
          setCarrying={setIsCarrying}
          vehicleType={vehicleType}
          trailerList={trailerList}
          handleTrailerChange={handleTrailerchange}
          lockTruck={lockTruck}
        />
      ) : null}
      {currentForm === 2 ? (
        <CheckInTruckF15
          backForm={backForm}
          nextForm={nextForm}
          english={english}
          handleTruckChange={handleTruckPart}
          truckForm={truckForm}
        />
      ) : null}
      {currentForm === 3 ? (
        <CheckInTrailerF15
          backForm={backForm}
          nextForm={nextForm}
          english={english}
          handleTrailerChange={handleTrailerPart}
          sealStatus={trailerData.is_seal}
          trailerForm={trailerForm}
        />
      ) : null}
      {currentForm === 4 ? (
        <CheckInPhotos
          english={english}
          backForm={backForm}
          capturedPhotos={capturedPhotos}
          setCapturedPhotos={setCapturedPhotos}
          deletePhoto={deletePhoto}
          submit={submit}
          isLoading={isLoading}
          confirm={setConfirm}
        />
      ) : null}
      {success ? (
        <CheckInSuccess
          title={english("forms.type.success")}
          message={english("forms.type.check-out-success")}
        />
      ) : null}
      {confirm && (
        <CheckoutConfirm
          truck={truck?.carrier_name + "-" + truck?.truck_number}
          trailer={trailerData?.trailer_number}
          confirm={setConfirm}
          submit={submit}
          type="check out"
          english={english}
        />
      )}
    </div>
  );
};

// ef630s ATI-40 Bruno Arias
