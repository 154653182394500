export const CheckInTruckF15 = ({
  nextForm,
  backForm,
  english,
  handleTruckChange,
  truckForm,
}) => {
  const isAnyFieldPoor = () => {
    return Object.values(truckForm).some((value) => value === false);
  };

  return (
    <div className="questions-cont">
      <h2 className="global-h2 light-black">
        {english ? "Vehicle Check in" : "Entrada de Vehículo"}
      </h2>
      <h4 className="global-h4 gray bottom-space">
        {english
          ? "Please note the condition of the truck's parts."
          : "Marque el estado de las piezas del camión."}
      </h4>
      <form onSubmit={nextForm} id="form-truckF15">
        <div className="form-group">
          <label className="input-label required">
            {english ? "Bumper " : "Parachoques "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="bumper"
                  checked={truckForm.bumper}
                  onClick={() => handleTruckChange("bumper", true)}
                  className="radio-input"
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="bumper"
                  checked={truckForm.bumper === false}
                  onClick={() => handleTruckChange("bumper", false)}
                  className="radio-input"
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Engine " : "Motor "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="engine"
                  checked={truckForm.engine}
                  onClick={() => handleTruckChange("engine", true)}
                  className="radio-input"
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="engine"
                  checked={truckForm.engine === false}
                  onClick={() => handleTruckChange("engine", false)}
                  className="radio-input"
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Tires " : "Llantas "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="tires"
                  checked={truckForm.tires}
                  onClick={() => handleTruckChange("tires", true)}
                  className="radio-input"
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="tires"
                  checked={truckForm.tires === false}
                  onClick={() => handleTruckChange("tires", false)}
                  className="radio-input"
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Cab floor " : "Piso de cabina "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="cab-floor"
                  className="radio-input"
                  checked={truckForm.cabFloor}
                  onChange={() => handleTruckChange("cabFloor", true)}
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="cab-floor"
                  checked={truckForm.cabFloor === false}
                  onClick={() => handleTruckChange("cabFloor", false)}
                  className="radio-input"
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Fuel tank " : "Tanque de combustible "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="fuel"
                  className="radio-input"
                  checked={truckForm.fuelTank}
                  onClick={() => handleTruckChange("fuelTank", true)}
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="fuel"
                  className="radio-input"
                  checked={truckForm.fuelTank === false}
                  onClick={() => handleTruckChange("fuelTank", false)}
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Cab & compartments " : "Cabina y compartimientos "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="cab"
                  className="radio-input"
                  checked={truckForm.compartments}
                  onClick={() => handleTruckChange("compartments", true)}
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="cab"
                  className="radio-input"
                  checked={truckForm.compartments === false}
                  onClick={() => handleTruckChange("compartments", false)}
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Air tank " : "Cilindros de aire "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="air-tank"
                  className="radio-input"
                  checked={truckForm.airTank}
                  onClick={() => handleTruckChange("airTank", true)}
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="air-tank"
                  className="radio-input"
                  checked={truckForm.airTank === false}
                  onClick={() => handleTruckChange("airTank", false)}
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Drive shafts " : "Flecha cardan "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="drive-shafts"
                  className="radio-input"
                  checked={truckForm.driveShafts}
                  onClick={() => handleTruckChange("driveShafts", true)}
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="drive-shafts"
                  className="radio-input"
                  checked={truckForm.driveShafts === false}
                  onClick={() => handleTruckChange("driveShafts", false)}
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Fifth wheel " : "Quinta rueda "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="fifth-wheel"
                  className="radio-input"
                  checked={truckForm.fifthWheel}
                  onClick={() => handleTruckChange("fifthWheel", true)}
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="fifth-wheel"
                  className="radio-input"
                  checked={truckForm.fifthWheel === false}
                  onClick={() => handleTruckChange("fifthWheel", false)}
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Exhaust " : "Mofle "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="exhaust"
                  className="radio-input"
                  checked={truckForm.exhaust}
                  onClick={() => handleTruckChange("exhaust", true)}
                  onChange={null}
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="exhaust"
                  className="radio-input"
                  checked={truckForm.exhaust === false}
                  onClick={() => handleTruckChange("exhaust", false)}
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Pest & garbage free " : "Libra de plagas y basura "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="pest"
                  className="radio-input"
                  checked={truckForm.truckGarbage}
                  onClick={() => handleTruckChange("truckGarbage", true)}
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="pest"
                  className="radio-input"
                  checked={truckForm.truckGarbage === false}
                  onClick={() => handleTruckChange("truckGarbage", false)}
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label required">
            {english ? "Cleanliness " : "Limpieza "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="cleanliness"
                  className="radio-input"
                  checked={truckForm.truckCleanliness}
                  onClick={() => handleTruckChange("truckCleanliness", true)}
                  required
                />{" "}
                <label>{english ? "Good" : "Bueno"}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="cleanliness"
                  className="radio-input"
                  checked={truckForm.truckCleanliness === false}
                  onClick={() => handleTruckChange("truckCleanliness", false)}
                />{" "}
                <label>{english ? "Poor" : "Mal"}</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="form-group">
          <label className="input-label">{english ? "Notes" : "Notas"}</label>
          <div className="input-cont">
            <input
              className="text-input"
              placeholder={english ? "Enter notes" : "Ingresa notas"}
              onChange={(e) => handleTruckChange("truckNotes", e.target.value)}
              value={truckForm.truckNotes}
              type="text"
              required={isAnyFieldPoor()}
            />
          </div>
        </div>
        <div className="full-cont">
          <button className="change-pwd-btn" type="submit">
            Continue
          </button>
          <button className="cancel-btn" onClick={backForm}>
            Back
          </button>
        </div>
      </form>
    </div>
  );
};
