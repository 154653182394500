import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import instance from '../Interceptor/api_instance';

export const ProtectedRoute = ({ allowedRoles }) => {
  const { user, updateUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const response = await instance.get("api/checkauthentication");
      if (response.status === 200) {
        console.log("Update user")
        localStorage.setItem("user", JSON.stringify(response.data));
        updateUser(response.data);
      }
      setLoading(false);
    }
    checkAuth();
  }, [navigate])

  return (
    <>
      {loading ? null : (user && user.groups.some(group => allowedRoles.includes(group.name))
        ? <Outlet /> // If user exists and has a correct role, render the child routes
        : user
          ? <Navigate to="/unauthorized" replace /> // Authenticated but wrong role
          : <Navigate to="/login" replace /> // Not authenticated
      )}
    </>
  );
};
