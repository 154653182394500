import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import UniversalTable from "../Tables/UniversalTable";
import {
  backlogTruckHeaders,
  backlogTrailerHeaders,
} from "../Tables/TableHeaders";

import instance from "../../Interceptor/api_instance";

export default function BackLog() {
  const [user, setUser] = useState([]);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [trailerData, setTrailerData] = useState(null);
  const [truckData, setTruckData] = useState(null);
  const [table, setTable] = useState("trailers-table");
  const [currentTrailerPage, setCurrentTrailerPage] = useState(1);
  const [currentTruckPage, setCurrentTruckPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    fetchTrailerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrailerPage]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    fetchTruckData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTruckPage]);

  const fetchTrailerData = async () => {
    try {
      setPageLoading(true);
      const data = await instance.get(
        `assets/backlog/trailers/${currentTrailerPage}`
      );
      setTrailerData(data.data);
      setPageLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const fetchTruckData = async () => {
    try {
      let driverData = null;
      driverData = await instance.get(
        `assets/backlog/trucks/${currentTruckPage}`
      );
      setTruckData(driverData.data);
    } catch (error) {
      setError(error);
    }
  };

  const handleNextPage = () => {
    if (table === "trailers-table") {
      setCurrentTrailerPage(currentTrailerPage + 1);
    }
    if (table === "trucks-table") {
      setCurrentTruckPage(currentTruckPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (table === "trailers-table") {
      setCurrentTrailerPage(currentTrailerPage - 1);
    }
    if (table === "trucks-table") {
      setCurrentTruckPage(currentTruckPage - 1);
    }
  };

  const handlePageChange = (p) => {
    if (table === "trailers-table") {
      setCurrentTrailerPage(p);
    }
    if (table === "trucks-table") {
      setCurrentTruckPage(p);
    }
  };
  const active = async (e) => {
    const previous = document.getElementsByClassName("selected");
    for (var i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    setTable(e.target.id);
  };
  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | Overview</title>
      </Helmet>
      <div className="page-overview">
        <div className="cont-wrapper">
          {/* <DispatchInventory english={t} /> */}
          <div className="inventory-header">
            <h2 className="global-h1 light-black">
              {t("page-headers.backlog")}
              {/* {pageLoading && <Spinner animation="border" variant="dark" />} */}
            </h2>
          </div>
          <ul className="yard-tabs">
            <li className="selected" onClick={active} id="trailers-table">
              {t("yard-tables.tabs.trailers")}
            </li>
            <li className="tab" onClick={active} id="trucks-table">
              {t("yard-tables.tabs.trucks")}
            </li>
          </ul>
          {table === "trailers-table" && (
            <UniversalTable
              english={t}
              data={trailerData?.object_list}
              headers={backlogTrailerHeaders}
              filters={["Location", "Customer", "Contents", "Reservation"]}
              type="backlog trailer"
              error={error}
              page={currentTrailerPage}
              totalPages={trailerData?.total_pages}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              pageLoading={pageLoading}
              handlePageChange={handlePageChange}
              user={user}
            />
          )}
          {table === "trucks-table" && (
            <UniversalTable
              english={t}
              data={truckData?.object_list}
              headers={backlogTruckHeaders}
              filters={["Location", "Driver", "Carrier", "Destination"]}
              type="backlog truck"
              error={error}
              page={currentTruckPage}
              totalPages={truckData?.total_pages}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              pageLoading={pageLoading}
              handlePageChange={handlePageChange}
              user={user}
            />
          )}
        </div>
      </div>
    </div>
  );
}
