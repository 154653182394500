import FormExport from "../Utility/FormExport"

export default function DriverReports() {

    return(
        <>
        <FormExport
            type="Driver"
            pdfFile="F15-INSPECCION DE IITEDITADO.pdf"
            endPoint="safety/ctpat-reports"
        />
        </>
    )
}