import "../../../CSS/checkInTrailer.css";
import { useTranslation } from "react-i18next";

export const CheckInSuccess = ({ title, message, setSuccess }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-mask-2 page-mask-animation zindex-modal">
        <div className="success-message ">
          <h2 className="global-h2">
            {title.includes("This truck is already checked in.")
              ? t("forms.type.truckCheckinError")
              : title}
          </h2>
          {title.includes("Error") ? (
            <h3 className="global-h4 gray">
              {title.includes("This truck is already checked in.")
                ? ""
                : t("forms.type.trailerCheckinError")}
            </h3>
          ) : (
            <h3 className="global-h4 gray">{message}</h3>
          )}

          {title.includes("Error") ? (
            <button
              className="change-pwd-btn"
              onClick={() => setSuccess(false)}
            >
              {t("buttons.close")}
            </button>
          ) : (
            <button
              className="change-pwd-btn"
              onClick={() => window.location.reload()}
            >
              {t("buttons.close")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
