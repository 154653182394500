/**
 * Props for FancyFilter Component:
 *
 * @prop {string} title - Text displayed on the dropdown when it is closed.
 * @prop {Array} items - Options displayed in the dropdown when it is open.
 * @prop {Array|string} selectedItems - Items currently selected by the user. If 'type' is 'checkbox', this is an array of selected item identifiers. If 'type' is 'list', it is a string identifier for the single selected item.
 * @prop {Function} handleSelect - Callback function that handles the selection of items. This function should update the state of `selectedItems` based on user interactions.
 *    Example usage:
 *    ```
 *    const handleSelect = (item, setSelectedItems) => {
 *        setSelectedItems(prevSelectedItems => {
 *            if (prevSelectedItems.includes(item)) {
 *                return prevSelectedItems.filter(selectedItem => selectedItem !== item);
 *            } else {
 *                return [...prevSelectedItems, item];
 *            }
 *        });
 *    };
 *    ```
 * @prop {string} type - Determines the type of dropdown. Acceptable values are "checkbox" for multi-select dropdowns or "list" for single-select dropdowns.
 * @prop {boolean} isOpen - Indicates whether the dropdown is currently open.
 * @prop {Function} toggleDropdown - Function to toggle the open/close state of the dropdown.
 */

export default function FancyFilter({
  title,
  items,
  selectedItems,
  handleSelect,
  isOpen,
  toggleDropdown,
  type,
  english
}) {
  return (
    <div>
      {/* Fancy dropdown filter */}
      <div onClick={toggleDropdown} className="fancy">
        <span>{title} </span>
        {/* Additional logic for displaying selected items */}
        {type === "checkbox"
          ? selectedItems.length > 0 && <span>({selectedItems.length})</span>
          : selectedItems.length > 0 && <span>({selectedItems})</span>}

        {/* Apply dynamic rotation to the SVG based on isOpen state */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          className={`fancy-carrot ${isOpen ? "rotate" : ""}`}
        >
          <path
            d="M0.959997 10.56L7.36 4.15997C7.52 3.99997 7.76 3.91997 8 3.91997C8.24 3.91997 8.48 3.99997 8.64 4.15997L15.04 10.56C15.44 10.96 15.44 11.52 15.04 11.92C14.64 12.32 14.08 12.32 13.68 11.92L7.92 6.15997L2.16 11.92C1.76 12.32 1.2 12.32 0.799996 11.92C0.559997 11.52 0.559998 10.88 0.959997 10.56Z"
            fill="#393838"
          />
        </svg>
      </div>
      {isOpen &&
        (type === "checkbox" ? (
          <div className="dropdown-list">
            {items.length > 0 ? (
              items.map((item, index) => (
                <div
                  onClick={() => handleSelect(item)}
                  className="dropdown-checkbox-cont"
                  key={index}
                >
                  <input
                    type="checkbox"
                    value={item}
                    checked={selectedItems.includes(item)}
                  />
                  <span>{item}</span>
                </div>
              ))
            ) : (
              <>
                <span>N/A</span>
              </>
            )}
          </div>
        ) : (
          <div className="dropdown-list">
            {items.map((item, index) => (
              <div
                className="dropdown-checkbox-cont"
                key={index}
                onClick={() => handleSelect(item)}
              >
                <span className="fancy-list-item">{item}</span>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
}
