import { reservationHeaders } from "../Tables/TableHeaders";
import { useEffect, useState, useCallback } from "react";
import "../../CSS/global.css";
import "../../CSS/reservations.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import UniversalTable from "../Tables/UniversalTable";
import instance from "../../Interceptor/api_instance";
import ReservationConfirm from "../Modals/ReservationConfirm";

export const Reservations = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [trailers, setTrailers] = useState([]);
  const [orderedTrailers, setOrder] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [reservationModal, setReservationModal] = useState(false);
  const [allTrucks, setAllTrucks] = useState({ ati: [], jfg: [] });
  const [selectedATITruck, setSelectedATITruck] = useState(null);
  const [selectedJFGTruck, setSelectedJFGTruck] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem("user") === null) {
      window.location.href = "/login";
    }
    const userData = localStorage.getItem("user");
    setUser(JSON.parse(userData));
    (async () => {
      try {
        setLoading(true);
        if (trailers.length === 0) {
          const data = await instance.get("assets/get-all-reservations/");
          setTrailers(data.data);
          if (data.data.length > 0) {
            let filter = data.data.filter(
              (a) => a.location.loc_name === "San Diego Yard"
            );
            setOrder(filter);
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error);
      }
    })();
  }, [trailers.length]);

  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        const response = await instance.get("assets/all-truck-assets");
        setAllTrucks(response.data);
      } catch (error) {
        console.error("Failed to fetch trucks:", error);
      }
    };

    fetchTrucks();
  }, []);

  const handleTruckChange = useCallback((selectedOption, action) => {
    if (action.name === "ATITruck") {
      setSelectedATITruck(selectedOption);
    } else if (action.name === "JFGTruck") {
      setSelectedJFGTruck(selectedOption);
    }
  }, []);

  const ATITruckOptions =
    allTrucks.ati?.length > 0
      ? allTrucks.ati.map((truck) => ({
          value: truck.id,
          label: `${truck.company}-${truck.name}`,
        }))
      : [{ value: "truckListLoading", label: "Loading" }];

  const JFGTruckOptions =
    allTrucks.jfg?.length > 0
      ? allTrucks.jfg.map((truck) => ({
          value: truck.id,
          label: `${truck.company}-${truck.name}`,
        }))
      : [{ value: "truckListLoading", label: "Loading" }];

  const reserve = async (e) => {
    let key = {
      trailer_id: e,
    };

    if (selectedATITruck || selectedJFGTruck) {
      key.truck_id = selectedATITruck
        ? selectedATITruck.value
        : selectedJFGTruck.value;
    }

    try {
      setLoading(true);
      await instance.post("assets/reserve-trailer/", key);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      setErrorMessage(error);
    }
  };

  const unreserve = async (e) => {
    const key = {
      trailer_id: e,
    };
    try {
      setLoading(true);
      await instance.post("assets/unreserve-trailer/", key);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const active = async (e) => {
    const previous = document.getElementsByClassName("selected");
    for (var i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    if (trailers.length > 0) {
      let filter = trailers.filter((a) => a.location.loc_name === e.target.id);
      setOrder(filter);
    }
  };

  const handleResevationConfirm = (confirmed, row) => {
    if (confirmed) {
      setConfirmLoading(true);
      if (
        row.row[1] === t("graphics-cont.reservations.unreserve").toLowerCase()
      ) {
        unreserve(row.row[0]);
      } else {
        reserve(row.row[0]);
      }
    }
  };

  return (
    <>
      <div className="global-cont">
        <Helmet>
          <title>Forward | Reservations</title>
        </Helmet>
        <div className="page-overview">
          <div className="cont-wrapper">
            <div className="overview-header">
              <h1 className="global-h1 black">
                {t("page-headers.reservations")}
              </h1>

              <ul className="yard-tabs">
                <li className="selected" onClick={active} id="San Diego Yard">
                  {t("yards.sd")}
                </li>
                <li className="tab" onClick={active} id="Tijuana Yard">
                  {t("yards.tj")}
                </li>
                <li className="tab" onClick={active} id="Tecate Yard">
                  {t("yards.tecate")}
                </li>
              </ul>

              <UniversalTable
                english={t}
                data={orderedTrailers}
                headers={reservationHeaders}
                filters={null}
                user={user}
                reserve={reserve}
                unreserve={unreserve}
                type="reservation"
                error={errorMessage}
                loading={loading}
                pageLoading={loading}
                ReservationConfirm={(row) => (
                  <ReservationConfirm
                    row={row}
                    english={t}
                    confirmLoading={confirmLoading}
                    errorMessage={errorMessage}
                    handleResevationConfirm={handleResevationConfirm}
                    handleTruckChange={handleTruckChange}
                    ATITruckOptions={ATITruckOptions}
                    JFGTruckOptions={JFGTruckOptions}
                    selectedATITruck={selectedATITruck}
                    selectedJFGTruck={selectedJFGTruck}
                    setReservationModal={setReservationModal}
                  />
                )}
                reservationModal={reservationModal}
                setReservationModal={setReservationModal}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reservations;
