import React from "react";

export default function CheckoutConfirm({ truck, trailer, confirm, submit, type, english }) {
  const renderSubmitButton = () => {
    if (type === "check out" && truck) {
      return (
        <button id="submit-btn" onClick={submit} className="change-pwd-btn">
          {english("buttons.submit")}
        </button>
      );
    } else if (type === "check in") {
      return (
        <button onClick={submit} id="submit-btn" className="change-pwd-btn">
          {english("buttons.submit")}
        </button>
      );
    } else if (type !== "check in") {
      return <p className="error m-0">{english("forms.type.vehicle.check-in.truck-not")}</p>;
    }
    return null;
  };

  return (
    <div className="page-mask">
      <div className="blank-card active-confirm">
        <h4>{english("buttons.confirm")}</h4>
        <p className="gray mb-4">{english("forms.type.vehicle.check-in.review")} {type === "check in" ? english("page-headers.check-in").toLowerCase() : english("page-headers.check-out").toLowerCase()}</p>
        <div>
          {truck && <p>{english("yard-tables.table-columns.truck")}: {truck}</p>}
          {trailer && <p>{english("yard-tables.tabs.trailer")}: {trailer}</p>}
        </div>
        <div className="d-flex gap-2 justify-content-center align-items-center full-cont">
          {renderSubmitButton()}
          <button id="back-btn" onClick={() => confirm(false)} className="cancel-btn">
            {english("buttons.back")}
          </button>
        </div>
      </div>
    </div>
  );
}
