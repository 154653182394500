import React, {createContext, useContext, useState, useEffect} from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({children}) => {
    
    // Check if language is stored in localStorage
    const savedLanguage = localStorage.getItem('language');
    let language = true;
    if (savedLanguage) {
        language = savedLanguage === 'true' ? true : false;
    }
    const [english, setLanguage] = useState(language);
    const [pfp, setPfp] = useState(localStorage.getItem("pfp_url"));
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
    const [change, setChange] = useState('');

    const toggleLanguage = (set) => {
        setLanguage(set);
    };

    const storeUser = (data) => {
        setUser(data);
        localStorage.setItem("user", JSON.stringify(data));
    }

    useEffect(() => {
        localStorage.setItem('language', english);
    }, [user, english, change]);

    return (
        <LanguageContext.Provider value={{ english, toggleLanguage, user, storeUser, change, setChange, pfp, setPfp }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    return useContext(LanguageContext)
}