import { useEffect, useState } from "react";
import instance from "../../../Interceptor/api_instance";
import { CheckIn1stForm } from "./CheckinCheckout";
import { CheckInTruckF15 } from "./CheckInTruckF15";
import { CheckInTrailerF15 } from "./CheckInTrailerF15";
import { CheckInPhotos } from "./CheckInPhotos";
import { CheckInSuccess } from "./CheckInSuccess";
import CheckoutConfirm from "../../Modals/CheckoutConfirm";

export const CheckInVehicle = ({ onClose, english }) => {
  // Check in conditions
  const [carrying, setCarrying] = useState(null);
  const [trailerType, setTrailerType] = useState(null);
  const [atiBool, setBool] = useState(false);
  const [country, setCountry] = useState("");
  const [countryState, setState] = useState("");
  const [isLoading, setLoading] = useState(false);

  // Trailer states
  const [trailerList, setTrailerList] = useState([]);
  const [customer, setCustomer] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [trailerBody, setTrailerBody] = useState({});
  const [dimensionOption, setDimensionOption] = useState([]);
  const [customLength, setCustomLength] = useState("");
  const [customWidth, setCustomWidth] = useState("");
  const [customHeight, setCustomHeight] = useState("");

  const [generalNotes, setGeneralNotes] = useState("");

  // Truck states
  const [carrier, setCarrier] = useState("");
  const [otherCarrier, setOtherCarrier] = useState("");
  const [atiTruckList, setATITruckList] = useState([]);
  const [jfgTruckList, setJFGTruckList] = useState([]);
  const [truckBody, setTruckBody] = useState({});

  // Images states
  const [capturedPhotos, setCapturedPhotos] = useState([]);

  // Current form state
  const [currentForm, setCurrentForm] = useState(1);

  // Check in success
  const [success, setSuccess] = useState(false);

  const [responseData, setResponseData] = useState(null);
  const [successTitle, setSuccessTitle] = useState("");

  const [trailerForm, setTrailerForm] = useState({
    trailer_dimensions: [parseFloat(53), parseFloat(8.5), parseFloat(13.5)],
    chassis: true,
    inOutDoors: true,
    trailerFloor: true,
    sideWalls: true,
    frontWall: true,
    ceiling: true,
    refrigerator: true,
    sealCondition: true,
    trailerGarbage: true,
    trailerCleanliness: true,
    filter: true,
    humidity: true,
    view_seal: true,
    verify_seal: true,
    tug_seal: true,
    twist_turn_seal: true,
  });
  const [truckForm, setTruckForm] = useState({
    bumper: true,
    engine: true,
    tires: true,
    cabFloor: true,
    fuelTank: true,
    compartments: true,
    airTank: true,
    driveShafts: true,
    fifthWheel: true,
    exhaust: true,
    truckGarbage: true,
    truckCleanliness: true,
  });

  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    const getTrucks = async () => {
      try {
        const [atiTrucks, jfgTrucks] = await Promise.all([
          instance.get("assets/out-trucks/ATI/"),
          instance.get("assets/out-trucks/JFG/"),
        ]);
        if (atiTrucks.status === 200) {
          setATITruckList(atiTrucks.data);
        }
        if (jfgTrucks.status === 200) {
          setJFGTruckList(jfgTrucks.data);
        }
      } catch (e) {
        window.alert(e);
      }
    };
    getTrucks();
  }, []);

  const handleCheckInDetails = (setBody, part, value) => {
    setBody((prevFormData) => ({
      ...prevFormData,
      [part]: value,
    }));
  };

  const handleTruckChange = (part, value) => {
    setTruckForm((prevFormData) => ({
      ...prevFormData,
      [part]: value,
    }));
  };

  const handleTrailerChange = (part, value) => {
    setTrailerForm((prevFormData) => ({
      ...prevFormData,
      [part]: value,
    }));
  };

  const nextForm = () => {
    setCurrentForm(4);
  };

  const backForm = () => {
    setCurrentForm(1);
  };

  const deletePhoto = (index) => {
    const updatedCapturedPhotos = [...capturedPhotos];
    updatedCapturedPhotos.splice(index, 1);
    setCapturedPhotos(updatedCapturedPhotos);
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (carrying) {
        const formData = new FormData();
        capturedPhotos.forEach((photo, index) => {
          formData.append("photo", photo);
        });

        //Get dimensions of trailer. Order is length, width, height and in feet
        let dimensions = [];
        if (dimensionOption === 1) {
          dimensions = [parseFloat(53), parseFloat(8.5), parseFloat(13.5)];
        } else if (dimensionOption === 2) {
          dimensions = [parseFloat(48), parseFloat(8.5), parseFloat(13.5)];
        } else {
          dimensions = [
            parseFloat(customLength),
            parseFloat(customWidth),
            parseFloat(customHeight),
          ];
        }

        const data = {
          ...trailerBody,
          ...truckBody,
          ...trailerForm,
          ...truckForm,
          carrier: carrier !== "Other" ? carrier : otherCarrier,
          reason: "Checking in a trailer",
          is_loaded: trailerBody.is_loaded === "true" ? true : false,
          seal_number:
            trailerBody.is_seal === "true" ? trailerBody.seal_number : null,
          is_seal: trailerBody.is_seal === "true" ? true : false,
          trailer_dimensions: dimensions,
          trailer_type: trailerType,
          customer: trailerBody.is_loaded === "true" ? customer : null,
        };

        if (!data.odometer) {
          data.odometer = 0;
        }

        formData.append("data", JSON.stringify(data));
        const checkInTrailer = await instance.post(
          "assets/check-in-trailer/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/data-form",
            },
          }
        );
        if (checkInTrailer.status === 200) {
          setSuccessTitle(english("forms.type.success"));
          setSuccess(true);
        }
        console.log("checkInTrailer", checkInTrailer);
      } else {
        const formData = new FormData();
        capturedPhotos.forEach((photo, index) => {
          formData.append("photo", photo);
        });
        const data = {
          ...truckBody,
          ...truckForm,
          carrier: carrier !== "Other" ? carrier : otherCarrier,
          reason: "Checking in with a truck",
        };
        setResponseData(data);

        formData.append("data", JSON.stringify(data));

        const checkInTruck = await instance.post(
          "assets/check-in-truck/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/data-form",
            },
          }
        );

        if (checkInTruck.status === 200) {
          setSuccessTitle(english("forms.type.success"));
          setSuccess(true);
        }
        console.log("checkInTruck", checkInTruck);
      }

      // window.location.href = "/guard-overview";
    } catch (error) {
      setSuccessTitle(error);
      setSuccess(true);
      setLoading(false);
      console.log("error", error);
    }
  };

  const findTrailer = () => {
    let trailer = null;
    if (trailerBody.trailer_number) {
      if (trailerType) {
        trailer = trailerList.find(
          (trailer) => trailer.id === trailerBody.trailer_number
        );
        console.log(trailer);
        return trailer.unit + "-" + trailer.unit_number;
      } else {
        return trailerBody.trailer_number;
      }
    }
  };
  const trailerData = findTrailer();
  return (
    <div>
      {currentForm === 1 ? (
        <CheckIn1stForm
          carrying={carrying}
          setCarrying={setCarrying}
          trailerType={trailerType}
          setTrailerType={setTrailerType}
          atiBool={atiBool}
          setBool={setBool}
          trailerList={trailerList}
          setTrailerList={setTrailerList}
          carrier={carrier}
          setCarrier={setCarrier}
          otherCarrier={otherCarrier}
          setOtherCarrier={setOtherCarrier}
          customer={customer}
          setCustomer={setCustomer}
          customerList={customerList}
          setCustomerList={setCustomerList}
          atiTruckList={atiTruckList}
          setATITruckList={setATITruckList}
          jfgTruckList={jfgTruckList}
          setJFGTruckList={setJFGTruckList}
          onClose={onClose}
          english={english}
          nextForm={nextForm}
          generalNotes={generalNotes}
          setGeneralNotes={setGeneralNotes}
          handleCheckInDetails={handleCheckInDetails}
          trailerBody={trailerBody}
          truckBody={truckBody}
          setTrailerBody={setTrailerBody}
          setTruckBody={setTruckBody}
          inOrOut={true}
          dimensionOption={dimensionOption}
          setDimensionOption={setDimensionOption}
          country={country}
          setCountry={setCountry}
          customWidth={customWidth}
          customHeight={customHeight}
          customLength={customLength}
          setCustomWidth={setCustomWidth}
          setCustomLength={setCustomLength}
          setCustomHeight={setCustomHeight}
          countryState={countryState}
          setState={setState}
        />
      ) : null}
      {currentForm === 2 ? (
        <CheckInTruckF15
          nextForm={nextForm}
          backForm={backForm}
          english={english}
          handleTruckChange={handleTruckChange}
          truckForm={truckForm}
        />
      ) : null}
      {currentForm === 3 ? (
        <CheckInTrailerF15
          backForm={backForm}
          carrying={carrying}
          nextForm={nextForm}
          english={english}
          handleTrailerChange={handleTrailerChange}
          trailerForm={trailerForm}
        />
      ) : null}
      {currentForm === 4 ? (
        <CheckInPhotos
          english={english}
          backForm={backForm}
          capturedPhotos={capturedPhotos}
          setCapturedPhotos={setCapturedPhotos}
          deletePhoto={deletePhoto}
          submit={submit}
          isLoading={isLoading}
          inOrOut={true}
          confirm={setConfirm}
        />
      ) : null}
      {confirm && (
        <CheckoutConfirm
          english={english}
          truck={carrier + "-" + truckBody.truck_number}
          trailer={trailerData}
          confirm={setConfirm}
          submit={submit}
          type="check in"
        />
      )}
      {success && (
        <CheckInSuccess
          title={successTitle}
          message={english("forms.type.vehicle-checked-in")}
          setSuccess={setSuccess}
        />
      )}
    </div>
  );
};
