import { useState, useEffect } from "react";
import instance from "../../Interceptor/api_instance";
import { Spinner } from "../LoadingAnimations/LoadingAnimations";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import UniversalTable from "../Tables/UniversalTable";
import { DriverInfo, EditDriverInfo } from "../Modals/DriverInfo";

export default function DrivingTeam() {
  const [driverData, setDriverData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isActivateLoading, setIsActivateLoading] = useState(false);
  const [activeError, setActiveError] = useState(null);

  const { t } = useTranslation();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let driverData = null;
      driverData = await instance.get("safety/get-all-drivers");
      setDriverData(driverData.data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowSelect = (row) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeEditModal = () => {
    setIsEditOpen(false);
  };

  const handleEditBtn = (item) => {
    setSelectedRow(item);
    setIsEditOpen(true);
  };

  // Active status request -----------------------------------------------------
  const getActiveStatus = async (id, status) => {
    try {
      setIsActivateLoading(true);
      const response = await instance.get(
        `safety/driver-set-status/${id}/${status}`
      );
      if (response.status === 200) {
        await fetchData();
        setIsActivateLoading(false);
        setIsConfirmModal(false);
      }
    } catch (error) {
      setIsActivateLoading(false);
      setActiveError(error);
    }
  };

  // Confirm status change modal -----------------------------------------------------
  const ActiveConfirmModal = (row) => {
    const status = row.row.is_active ? "deactivated" : "active";

    const handleActiveConfirm = (confirmed) => {
      if (confirmed) {
        getActiveStatus(row.row.id, status);
      } else {
        setIsConfirmModal(false);
      }
    };

    return (
      <>
        <div className="page-mask page-mask-animation">
          <div className="blank-card active-confirm">
            {isActivateLoading ? (
              <>
                <Spinner />
              </>
            ) : activeError ? (
              <>
                <p className="error">{activeError}</p>
                <button
                  className="profile-pic-btn"
                  onClick={() => handleActiveConfirm(false)}
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <h4 className="">
                  {t("driversTable.prompt")}{" "}
                  {row.row.is_active
                    ? t("driversTable.deactivate")
                    : t("driversTable.activate")}{" "}
                  {row.row.first_name} {row.row.last_name}?
                </h4>

                <div className="d-flex gap-2 justify-content-center">
                  <button
                    className="profile-pic-btn"
                    onClick={() => handleActiveConfirm(true)}
                  >
                    {t("general.yes")}
                  </button>
                  <button
                    className="profile-pic-btn"
                    onClick={() => handleActiveConfirm(false)}
                  >
                    No
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isModalOpen && (
        <>
          <DriverInfo
            close={closeModal}
            row={selectedRow}
            fetch={fetchData}
            openEdit={handleEditBtn}
            error={error}
            isLoading={isLoading}
            english={t}
          />
          <div
            onClick={() => setIsModalOpen(false)}
            className="page-mask page-mask-animation"
          ></div>
        </>
      )}
      {isEditOpen && (
        <>
          <div className="page-mask page-mask-animation">
            <EditDriverInfo
              fetch={fetchData}
              row={selectedRow}
              english={t}
              close={closeEditModal}
            />
          </div>
        </>
      )}
      <Helmet>
        <title>Forward | Driving Team</title>
      </Helmet>
      <div className="global-cont">
        <div className="page-overview">
          <div className="cont-wrapper">
            <div className="overview-header">
              <h1 className="global-h1 black">
                {t("page-headers.driving-team")}
              </h1>
            </div>
            <UniversalTable
              type={"driver"}
              english={t}
              data={driverData}
              headers={[
                "id",
                "is_active",
                "first_name",
                "carrier_name",
                "license_number",
                "personal_phone",
                "work_phone",
                "email",
              ]}
              filters={["Carrier"]}
              onRowSelect={handleRowSelect}
              isDriverInfoOpen={isModalOpen}
              ActiveConfirmModal={ActiveConfirmModal}
              setIsConfirmModal={setIsConfirmModal}
              isConfirmModal={isConfirmModal}
              error={error}
              pageLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
